.cupertino-pane {
	.cupertino-pane-wrapper {
		.backdrop {
			z-index: 101;
		}

		.pane {
			z-index: 102;
			padding-top: 100px;
		}

		.draggable {
			top: 30px;
			z-index: 10;
		}
	}
}
