.checkbox {
	@include color($color__dark);
	@include text-normal;
	position: relative;
	display: flex;
	font-weight: 300;
	line-height: 22px;
	cursor: pointer;
	padding: .625rem 0 .625rem 1rem;
	margin-left: -1rem;
	border-radius: $border-radius-xs;
	transition: $transition-base;

	&:hover {
		@include color($black);
		background-color: #f7f7f7;

		.checkbox__box {
			border-color: $color__gold_hover;
			/*box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .05);*/
		}
	}

	&:active {
		background-color: hsla(0, 0%, 90%, .6);

		.checkbox__box {
			border-color: $color__gold_active;
			box-shadow: none;
		}
	}
}

.checkbox__input {
	position: absolute;
	width: 1px;
	height: 1px;
	opacity: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&:checked {
		& + .checkbox__box {
			background-color: $color__gold;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSA1TDUgOUwxMyAxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=');
			background-position: center;
		}
	}
}

.checkbox__box {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: 22px;
	height: 22px;
	margin-right: 16px;
	background-color: $white;
	border: 1px solid $color__gold;
	background-repeat: no-repeat;
	background-position: center -100%;
	border-radius: $border-radius-xs;
	transition: $transition-base, background-position $transition-default-time-smallest $transition-default-effect;
}

.checkbox__text {
	width: 100%;

	a {
		color: inherit;
		text-decoration: underline;
	}
}