.accent-card-logo {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	margin: 0 auto;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.accent-card-logo__icon {
	width: 40px;
	height: 35px;

	margin-bottom: 5px;
}

.accent-card-logo__text {
	width: 100px;
	height: 1.125rem;
}