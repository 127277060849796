.shop-banner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 620px;

	@include media-breakpoint-down(xxl) {
		height: 560px;
	}

	@include media-breakpoint-down(lg) {
		height: 480px;
	}

	@include media-breakpoint-down(sm) {
		position: static;
		height: unset;
	}
}

.shop-banner__container {
	height: 620px;

	@include media-breakpoint-down(xxl) {
		height: 560px;
	}

	@include media-breakpoint-down(lg) {
		height: 480px;
	}

	@include media-breakpoint-down(sm) {
		height: 440px;
		padding: 0;
	}
}

.shop-banner__contact-card {
	max-width: 500px;

	@include media-breakpoint-down(sm) {
		max-width: unset;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
