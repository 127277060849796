/*@import "./catalog-sidebar/catalog-sidebar.css";
@import "./catalog-sort/catalog-sort.css";
@import "./catalog-view/catalog-view.css";
@import "./catalog-filter-price/catalog-filter-price.css";
@import "./catalog-product-card/catalog-product-card.css";
@import "./detailed-catalog-product-card/detailed-catalog-product-card.css";*/

.search-page {
	display: none;
}

.catalog {

	@include media-breakpoint-down(lg) {
		overflow-x: hidden;
	}

	@include media-breakpoint-down(md) {
		overflow-x: unset;

		.catalog__sidebar {
			display: none;
		}
	}
}

.catalog__breadcrumb {
	margin-bottom: 48px;
}

.catalog__grid {
	display: grid;
	grid-gap: 2.75rem;
	grid-template-columns: 1.2fr 4.5fr;
	position: relative;
	z-index: 1;

	@include media-breakpoint-down(lg) {
		grid-gap: 4vw;
		grid-template-columns: 1.2fr 3fr;
	}

	@include media-breakpoint-down(md) {
		grid-template-columns: 1fr;
	}
}

.catalog__products {
	position: relative;
}

.catalog__products_grid {
	display: grid;
	grid-gap: 1.25rem .625rem;
	grid-template-columns: repeat(4, 1fr);

	@include media-breakpoint-down(xxl) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include media-breakpoint-down(lg) {
		grid-gap: 1.25rem 1.125rem;
		grid-template-columns: 1fr 1fr;
		margin-top: 2rem;
	}

	@include media-breakpoint-down(md) {
		margin-top: 24px;
	}

	&.catalog__products_list {
		grid-template-columns: 1fr 1fr;

		@include media-breakpoint-down(xl) {
			grid-template-columns: 1fr;
		}
	}
}

.catalog__icon {
	@include color($color__graylight);
	position: absolute;
	top: -40px;
	right: 0;
	z-index: -5;
	width: 1002px;
	height: 90px;
	opacity: 0.36;

	@include media-breakpoint-down(lg) {
		right: unset;
		left: 205px;
		width: 674px;
		height: 58px;
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.catalog__favorites {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

	@include media-breakpoint-down(sm) {
		grid-template-columns: 1fr 1fr;
	}
}

.catalog__empty {
	max-width: 500px;
	margin: 0 auto;
	text-align: center;
	display: flex;
	flex-direction: column;
}

.catalog__empty-title {
	@include color($color__darklight);
	margin-bottom: 20px;
	font-weight: 300;
	font-size: 38px;
	line-height: 46px;
	text-align: center;
}

.catalog__pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2.875rem;
}

.catalog__promotional-block {
	grid-column: 1/-1;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

.catalog__control-container {
	display: flex;
	justify-content: space-between;

	& + .catalog__products {
		margin-top: 2rem;
	}
}

.catalog__title {
	@include color($color__darklight);
	margin-bottom: 44px;
	font-weight: 300;
	font-size: 38px;
	line-height: 120%;
	text-transform: uppercase;
}

.catalog__show-more-categories {
	@include color($color__gray);
	@include display-text-normal;
	margin: 0;
	padding: .75rem .5rem;
	font-weight: 300;
	background-color: transparent;
	border: none;

	@include media-breakpoint-down(lg) {
		padding: .5rem;
	}
}

.catalog__filter-title {
	@include text-small;
	margin-bottom: .625rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.catalog__filter-button, .account__menu-button {
	display: none;
	min-height: 3.375rem;
	border-color: transparent;

	&:hover {
		border-color: transparent;
	}

	&:hover {
		@include color($color__gold_hover);

		.catalog__filter-icon {
			fill: $color__gold;
		}
	}

	&:active {
		@include color($color__gold_active);
		border-color: #e7e7e7 !important;

		.catalog__filter-icon {
			fill: $color__gold;
		}
	}

	@include media-breakpoint-down(md) {
		display: flex;
	}
}

.catalog__filter-icon {
	fill: $color__gold;
	height: 24px;
	width: 24px;
	margin-right: .375rem;
	position: relative;
	top: -1px;
	transition: $transition-base;
}