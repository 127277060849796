.product-tab__section {
	transition: $transition-base;

	&.collapsing {

	}

	&.show {
		.product__attributes {
			padding-top: 1rem;

			& * {
				@include color($color__darklight);
			}
		}
	}
}

.product-tab__header {
	border-bottom: 1px solid #efefef;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	position: relative;
	transition: $transition-base;

	@include media-breakpoint-down(md) {
		overflow-x: auto;
		overflow-y: hidden;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&:not(.collapsed) {
		.plus_minus {
			&:before {
				transform: translatey(-50%) rotate(-90deg);
				opacity: 0;
			}
			&:after {
				transform: translatey(-50%) rotate(0);
				background-color: $color__gray;
			}
		}
	}

	&.collapsed {
		&:hover {
			border-color: $color__gray;
		}
	}

	&:active {
		border-color: #999;
	}

	.plus_minus {
		position: relative;
		width: 1.25rem;
		height: 1.25rem;
		cursor: pointer;

		&:before , &:after {
			content: '';
			display: block;
			background-color: $color__darklight;
			position: absolute;
			top: 50%; left: 0;
			transition: $transition-base;
			width: 100%;
			height: 2px;
		}

		&:before {
			transform: translatey(-50%);
		}

		&:after {
			transform: translatey(-50%) rotate(90deg);
		}
	}
}

.product-tab__button {
	@include color($black);
	margin: 0;
	padding: 0 0 14px;
	font-weight: 300;
	font-size: 20px;
	line-height: 130%;
	text-decoration: none;
	background-color: transparent;
	border: none;
	cursor: pointer;

	@include media-breakpoint-down(md) {
		@include text-small;
	}

	&:not(:last-child) {
		margin-right: 3rem;
	}

	&.active {
		position: relative;
		font-weight: 400;

		&::after {
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 3px;
			background-color: $black;
			content: "";
		}
	}
}
