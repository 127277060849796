.catalog-filter-price {
	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: 1fr 1fr;
	align-items: center;
}

.catalog-filter-price__label {
	@include display-text-normal;
	@include color($color__graydark);
	display: flex;
	align-items: center;
	font-weight: 300;
}

.catalog-filter-price__input {
	@include color($color__dark);
	width: 100%;
	margin: 0 0 0 .5rem;
	padding: .75rem .5rem;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $color__gray;
	outline: none;
	border-radius: 0;
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
		appearance: none;
	}
}
