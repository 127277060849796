.breadcrumb {
	/*line-height: 1rem;*/
	display: flex;
	line-height: 24px;
	white-space: nowrap;
	overflow: hidden;
	max-width: 100vw;

	@include media-breakpoint-down(md) {
		margin-left: -1.625rem;
		margin-right: -1.625rem;

		.scrollyeah_active {
			padding: 0 1rem;
			margin: 0 -1rem;
		}

		.scrollyeah__wrap {
			padding-left: 1.625rem !important;
			padding-right: 1.625rem !important
		}
	}
}

.breadcrumb__home {
	@include color($color__dark, true);
	position: relative;
	top: -2px;
}

.breadcrumb__item, .breadcrumb__link {
	@include color($color__dark, true);
	@include text-smallest;
	display: inline;
	font-weight: 300;
	letter-spacing: 0.01em;
	text-decoration: none;
}

.breadcrumb__item_color_dark, .breadcrumb__link_color_dark {
	color: $color__darklight;
}

.breadcrumb__item_color_gray, .breadcrumb__link_color_gray {
	color: $color__gray;
}

.breadcrumb__item_back {
	padding-right: 1.0625rem;
	margin-right: .75rem;
	position: relative;

	&:after {
		height: 24px;
		width: 1px;
		background: #b2b2b2;
		display: block;
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}

	& + .breadcrumb__item {
		.breadcrumb__link {

		}
	}

	.breadcrumb__link {
		@include color($color__gold, true);

		svg {
			@include color($color__gold);
			margin-right: .25rem;
		}

		&:hover {
			@include color($color__gold_hover);

			svg {
				@include color($color__gold_hover);
			}
		}
	}

	&:hover {
		svg {
			@include color($color__gold);
		}
	}
}

.breadcrumb__link {
	line-height: 1.25rem;
	transition: $transition-base;
	display: flex;
	align-items: center;

	&:hover {
		.breadcrumb__home {
			@include color($color__gold);
		}
	}
}

.breadcrumb__item {
	display: flex;
	align-items: center;

	svg {
		position: relative;
		/*bottom: -.0625rem;
		opacity: .8;*/
		color: $color__dark;

		&.arrow {
			margin: 0 .5rem;
			fill: $color__dark;
		}
	}
}

.breadcrumb__arrow {
	color: $color__gold;
	margin-right: .5rem;
	margin-left: .5rem;
}