.video-player {
	position: relative;
	width: 100%;
	height: 640px;

	@include media-breakpoint-down(xxl) {
		height: 560px;
	}

	@include media-breakpoint-down(md) {
		height: 360px;
		margin-bottom: 64px;
	}
}

.video-player__container {
	height: 637px;
}

.video-player__thumbnail {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 637px;
	object-fit: cover;

	@include media-breakpoint-down(xxl) {
		height: 560px;
	}

	@include media-breakpoint-down(md) {
		height: 360px;
	}
}

.video-player__button {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: 0;
	padding: 0;
	background: none;
	border: 0;
	transform: translate(-50%, -50%);
	cursor: pointer;

	.video-player__button-icon {
		width: 89px;
		height: 89px;
	}
}

iframe {
	width: 100%;
	height: 637px;

	@include media-breakpoint-down(xxl) {
		height: 560px;
	}

	@include media-breakpoint-down(md) {
		height: 360px;
	}
}
