.product-gallery {
	display: flex;
	position: sticky;
	top: 210px;
	height: 100%;
	background: $white;
	transition: all $transition-default-time-bigger $transition-default-effect;
	z-index: 2;

	@include media-breakpoint-down(lg) {
		width: 100%;
		display: flex;
		position: static;
		justify-content: center;
	}

	@include media-breakpoint-down(md) {
		margin: .625rem auto 2rem;
	}
}

.product-gallery__thumbnails {
	position: relative;
	width: 120px;
	margin-right: 1rem;
	margin-bottom: .5rem;
	margin-top: .5rem;

	@include media-breakpoint-down(md) {
		display: none;
	}

	&::before {
		display: block;
		/*padding-bottom: 420%;*/
		content: '';
	}

	& + .product-gallery__slider {
		@include media-breakpoint-up(md) {
			width: calc(100% - 120px - 1rem);
		}
	}
}

.product-gallery__slider {
	position: relative;
	width: 100%;
	border-radius: $border-radius-sm;
	transition: $transition-base;

	&::before {
		display: block;
		/* padding-bottom: 100%; */
		content: '';
	}

	.product-gallery__controls {
		position: absolute;
		top: 0;
		right: 0;
	}

	&.product-gallery__slider_shadow {
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08);
		padding: 1rem;
		overflow: hidden;
		border-radius: $border-radius;

		.product-gallery__viewport {
			padding: 0;
		}

		.product-gallery__image {
			transition: $transition-base;
		}

		.product-gallery__controls {
			right: 2rem;
			top: 0;
		}

		&:hover {
			box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .12);

			.product-gallery__image {
				filter: saturate(1.05);
			}
		}

		&:active {
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .12);
		}
	}
}

.product-gallery__viewport {
	position: sticky;
	top: 87px;
	left: 0;
	width: calc(100% + 2rem);
	padding: 1rem;
	margin: -1rem;
	overflow: hidden;
}

.product-gallery__container {
	display: flex;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;

	@include media-breakpoint-down(md) {
		transition: $transition-base;
	}
}

.product-gallery__container_align_vertical {
	flex-direction: column;
}

.product-gallery__image_detail {
	height: 500px;
}

.product-gallery__thumb {
	position: relative;
	flex-shrink: 0;
	width: 120px;
	margin: 0;
	padding: 0;
	background-color: transparent;
	cursor: pointer;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08);
	border: 2px solid transparent;
	border-radius: $border-radius-sm;
	transition: $transition-base;

	&:not(:last-child) {
		margin-bottom: .75rem;
	}

	&::before {
		display: block;
		padding-bottom: 100%;
		content: '';
		border-radius: $border-radius-sm;
		background-color: $white;
	}

	&:not(.active) {
		opacity: .8;
	}

	&:hover {
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .12);
	}

	&.active {
		background-color: $color__gold;

		.button_play {
			fill: $color__gold;
		}
	}

	&:active {
		background-color: #F0F0F0 !important;
		box-shadow: none;

		.button_play {
			fill: #F0F0F0 !important;
		}
	}

	&:hover {
		opacity: 1;
		background-color: $color__gold;

		img {
			filter: saturate(1.05);
		}

		.button_play {
			fill: $color__gold;
		}
	}

	img {
		transition: $transition-base;
	}

	> * {
		position: absolute;
		top: 0;
		left: 0;
	}

	.button_play__wrapper {
		background-color: $white;
		position: relative;
		border-radius: $border-radius-sm;
	}

	.button_play {
		fill: $color__graylight;
		width: 60%;
		height: 60%;
		position: absolute;
		top: 0;
		bottom: 0;
		right: -.625rem;
		left: 0;
		margin: auto;
		transition: $transition-base;
	}
}

.product-gallery__slide {
	position: relative;
	flex-shrink: 0;
	width: 100%;
}

/*
.product-gallery__open-slide-button {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: none;
	border: 0;
	cursor: pointer;
} */

.product-gallery__image {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: $border-radius-sm;

	/*&.lazy:not(.loaded) {
		@include color(transparent);
		background-color: #e8e6f0;
		animation: blink 1.1s linear infinite;
	}*/
}

.product-gallery__button {
	@include color($color__darklight);
	position: absolute;
	left: 0;
	width: 100%;
	height: 19px;
	margin: 0;
	padding: 0;
	background: none;
	border: 0;
	cursor: pointer;
	transition: $transition-base;

	&[disabled] {
		@include color($color__light);
	}

	svg {
		pointer-events: none;
	}
}


.product-gallery__button_prev {
	top: -27px;

	svg {
		transform: rotate(-90deg);
	}
}

.product-gallery__button_next {
	bottom: -27px;

	svg {
		transform: rotate(90deg);
	}
}

.product-gallery__pagination {
	display: none;

	@include media-breakpoint-down(md) {
		position: absolute;
		bottom: .75rem;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 10px;

		.product-gallery__pagination-button {
			width: 10px;
			height: 10px;
			margin: 0;
			padding: 0;
			background-color: hsla(0, 0%, 90%, .6);
			border: 0;
			border-radius: 50%;
			cursor: pointer;
			transition: $transition-base;

			&:not(:last-child) {
				margin-right: 8px;
			}

			&.active {
				background-color: $color__gold;
			}

			&:hover {
				background-color: $color__gold_hover;
			}

			&:active {
				background-color: $color__gold_active;
			}
		}
	}
}

.product-gallery__controls-button {
	@include color($color__gray);
	stroke: $color__gray;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	margin: 0;
	padding: 0;
	line-height: 0;
	background: $white;
	border: 0;
	border-radius: 50%;
	cursor: pointer;

	&:hover {
		@include color($color__gold_hover);
		stroke: $color__gold_hover;
	}

	&:active {
		@include color($color__gold_active);
		stroke: $color__gold_active;
	}

	svg {
		transition: $transition-base;
	}

	&:first-of-type > svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:last-of-type > svg {
		margin-top: 1px;
	}

	&.active svg {
		@include color($color__gold);
		fill: $color__gold;
		stroke: $color__gold;
	}

	&.loading {
		&:after {
			border-color: $color__gray transparent;
			height: 10px;
			left: calc(50% - 5px);
			top: calc(50% - 5px);
			width: 10px;
		}
	}
}

.product-gallery__badge {
	padding: .625rem .75rem;
	font-weight: 600;
	text-transform: uppercase;
	border-radius: $border-radius-xs;

	&:not(:last-child) {
		margin-right: .25rem;
	}
}

.product-gallery__share {
	width: 100%;
	height: 100%;

	.ya-share2 {
		.ya-share2__container, .ya-share2__list.ya-share2__list_direction_horizontal, .ya-share2__item, .ya-share2__link {
			width: 100%;
			height: 100%;
		}

		.ya-share2__container {
			.ya-share2__item_more .ya-share2__popup.ya-share2__popup_direction_bottom {
				top: 42px;
			}
		}

		.ya-share2__list.ya-share2__list_direction_horizontal {
			margin: 0;

			> .ya-share2__item {
				margin: 0;
			}
		}

		.ya-share2__link.ya-share2__link_more, .ya-share2__link.ya-share2__link_more:hover, .ya-share2__link.ya-share2__link_more:focus {
			opacity: 0 !important;
		}
	}
}