.news__wrapper {
	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: calc(100% + 2rem);
		height: 100%;
		padding: 1rem;
		margin: -1rem;
		z-index: 1;
		transition: $transition-base;
	}

	&.loading {
		.news__image {
			/*transition: none !important;
			opacity: 1 !important;*/
		}

		.news__list__wrapper {
			position: absolute;
			top: 0;

			&[data-role=news-list-old] {
				opacity: 0;
			}

			&[data-role=news-list] {
				opacity: 1;
			}
		}

		&:before {
			content: '';
			display: block;
			position: absolute;

			background: rgba(255, 255, 255, .2);

			backdrop-filter: blur(2px);
			z-index: 2;


		}
	}

	&.loaded {
		&[data-role=news-list-old] {
			z-index: -1;
		}

		&[data-role=news-list] {
			z-index: 1;
			background-color: transparent;
		}
	}
}

.news__list__wrapper {
	position: relative;
	opacity: 1;
	z-index: 1;
	background-color: $white;
	transition: $transition-base;

	&[data-role=news-list-old] {
		background-color: $white;
		z-index: 1;

		& + [data-role=news-list] {
			position: absolute;
			background-color: $white;
			top: 0;
			z-index: -1;
			opacity: 0;
		}
	}

	/*&:before {
		content: '';
		display: block;
		background: rgba(255, 255, 255, .4);
		backdrop-filter: blur(0px);
		position: absolute;
		top: -1rem;
		bottom: -1rem;
		left: -1rem;
		right: -1rem;
		opacity: 0;
		z-index: -1;
		pointer-events: none;
		transition: $transition-base;
	}

	&:after {
		position: absolute;
		display: block;
		content: '';
		left: 50%;
		top: 100px;
		width: 0;
		height: 0;
		z-index: -1;
		opacity: 0;
		transition: $transition-base;
	}

	&.loading {
		color: inherit !important;
		pointer-events: none;

		&:before {
			opacity: 1;
			backdrop-filter: blur(2px);
		}

		&:after {
			border-width: 5px 0;
			height: 3.625rem;
			left: calc(50% - 1.875rem);
			top: calc(50% - 1.875rem);
			width: 3.625rem;
			border-color: hsla(0, 0%, 90%, .3);
			opacity: 1;
		}

		.news__image {
			transition: none;
		}

		.news__list {
			opacity: .6;
		}
	}

	&.shown {
		&:before {
			z-index: 1;
		}

		&:after {
			z-index: 1;
		}
	}*/
}

.news__spinner {
	position: absolute;
}

.news__list {
	display: grid;
	grid-gap: 2.25rem;
	grid-template-columns: repeat(3, 1fr);
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		grid-gap: 2rem 2.25rem;
		grid-template-columns: repeat(2, 1fr);
	}

	@include media-breakpoint-down(sm) {
		grid-gap: 1.75rem;
		grid-template-columns: repeat(1, 1fr);
	}
}

.news__item {
	&:hover {
		.news__image {
			filter: saturate(1.08);
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08);
		}

		.news__image_wrapp {
		}

		.news__preview {

		}

		.news__section {
			color: #b2b2b2;
		}
	}

	&:active {
		.news__image {
			box-shadow: none;
		}

		.news__title {
			@include color($color__gold_active !important);
		}
	}
}

.news__pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2.875rem;
	margin-bottom: 7.5rem;

	@include media-breakpoint-down(lg) {
		margin-bottom: 5rem;
	}
}

.news__image_wrapp {
	background: #f7f7f7;
	border-radius: $border-radius-xl;
	transition: $transition-base;
}

.news__image {
	width: 100%;
	height: 15.5vw;
	max-height: 255px;
	object-fit: cover;
	border-radius: $border-radius-xs;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		height: calc(26.7vw - 1.75rem);
	}

	@include media-breakpoint-down(sm) {
		height: inherit;
	}

	&.lazyload {
		opacity: 0;
	}

	&.lazyloaded {
		opacity: 1;
	}

	/*&.lazyloaded, &:not(.lazyload) {
		opacity: 1;
		max-height: 270px;
		height: inherit;
	}*/
}

.news__image_placeholder {
	object-fit: contain;
	padding: 1.75rem;
	background-color: hsla(0, 0%, 90%, .6);
}

.news__section {
	@include fs-lh(.875rem, 1.5rem);
	@include color($color__graydark, true);
	display: block;
	margin-top: .75rem;
	text-transform: uppercase;
	text-decoration: none;
	position: relative;
	z-index: 2;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		@include fs-lh(.8125rem, 1.25rem);
	}

	&:hover {
		.tags__item_hash {
			@include color($color__gold !important);
		}
	}
}

.news__title {
	@include fs-lh(1.3125rem, 1.75rem);
	@include color($color__darklight, true);
	margin-top: .25rem;
	text-decoration: none;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		@include fs-lh(1.125rem, 1.5rem);
	}
}

.news__preview {
	@include display-text-small;
	@include color($color__gray);
	margin-top: .25rem;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		@include fs-lh(1.0625rem, 1.5rem);
	}
}

.news_detail__preview {
	@include fs-lh(1.125rem, 1.5rem);
	@include color(#7a7a7a);
	font-style: italic;
	margin-top: 2.5rem;

	@include media-breakpoint-down(sm) {
		margin-top: 2rem;
	}
}

.news_detail__picture {
	& + .container_tight {
		margin-top: 0;
	}

	margin-top: 3.25rem;
	display: flex;
	justify-content: center;

	img {
		max-width: 100%;
	}
}

.news_detail__text {
	@include fs-lh(1.125rem, 1.75rem);
	@include color($color__darklight);
	margin-top: 3.25rem;

	p {
		@include fs-lh(1.125rem, 1.5rem);
		margin-top: 2rem;

		&:first-child {
			margin-top: 0;
		}
	}

	@include media-breakpoint-down(sm) {
		@include fs-lh(1.0625rem, 1.75rem);
	}
}

.news_list_slider__wrapp {
	background-color: #f0f0f0;
	padding: 5rem 0 7.5rem;
	margin-top: 12.5rem;
	border-bottom: 2px solid #ccc;
	margin-bottom: calc(-3rem - 2px);
	z-index: 3;
	position: relative;
	overflow: hidden;

	.news__wrapper {
		margin-top: 2.5rem;

		@include media-breakpoint-down(md) {
			margin-top: 2rem;
		}
	}

	@include media-breakpoint-down(xl) {
		padding: 3.75rem 0 4.25rem;
	}

	@include media-breakpoint-down(lg) {
		padding: 3.75rem 0 3.75rem;
	}

	@include media-breakpoint-down(md) {
		margin-top: 10rem;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 7.5rem;
		padding: 3.25rem 0 3.75rem;
	}

	h2 {
		font-size: 25px;
		line-height: 32px;
		text-align: center;

		&:after {
			content: '';
			display: block;
			width: 3.875rem;
			height: .25rem;
			background: $color__gold;
			margin: 1.625rem auto 0;
		}
	}

	.news__list__wrapper {
		background: transparent;
		margin: 2.5rem -3rem 0;
		padding: 0 1.875rem;

		@include media-breakpoint-down(lg) {
			margin: 2.5rem -3rem 0;
			padding: 0 2.125rem;
		}

		@include media-breakpoint-down(md) {
			margin: 2rem -5.375rem 0;
			padding: 0 4.625rem;
		}

		@include media-breakpoint-down(sm) {
			margin: 2rem -3.875rem 0;
			padding: 0 3.125rem;
		}

		&:before, &:after {
			background: #f0f0f0;
			bottom: 0;
			content: "";
			display: block;
			position: absolute;
			top: 0;
			width: 4.25rem;
			z-index: 2;
		}

		&:before {
			box-shadow: 3px 0 4px -1px #f0f0f0, 2px 0 5px #f0f0f0;
			left: -1.5rem;
		}

		&:after {
			box-shadow: -3px 0 4px -1px #f0f0f0, -2px 0 5px #f0f0f0;
			right: -1.5rem;
		}
	}

	.news__list {
		display: flex;
		flex-wrap: nowrap;
		gap: 0 !important;
	}

	.news__item {
		width: 33.3333333%;
		flex: none !important;

		.pos-rel {
			padding: 0 1.125rem;
		}

		@include media-breakpoint-down(lg) {
			width: 50%;

			.pos-rel {
				padding: 0 .875rem;
			}
		}

		@include media-breakpoint-down(md) {
			width: 100%;

			.news__image {
				height: auto;
			}
		}
	}
}