.top-sales {
	position: relative;
}

.top-sales__header {
	margin: 35px auto 22px;
}

.top-sales__icon {
	@include color($color__graylight);
	position: absolute;
	top: -40px;
	left: 0;
	z-index: -5;
	width: 1468px;
	height: 128px;
	opacity: 0.36;

	@include media-breakpoint-down(lg) {
		width: 674px;
		height: 58px;
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.top-sales__ring {
	position: absolute;
	right: 17px;
	bottom: calc(100% - 220px);
	z-index: -5;

	@include media-breakpoint-down(lg) {
		display: none;
	}

	.top-sales__ring_position_left {
		top: -150px;
		right: unset;
		bottom: unset;
		left: -100px;
	}
}