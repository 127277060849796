html {
	scroll-behavior: smooth;
	scrollbar-gutter: stable;
}

body {
	@include color($color__dark);
	overflow-x: hidden;
	min-width: 340px;
	max-width: 100vw;
	letter-spacing: -0.005em;
}

a {
	@include color($color__gold, true);

	&.color-dark {
		&:hover {
			@include color($color__gold !important);
		}
	}

	&.color-gold {
		&:hover {
			@include color($color__dark !important);
		}
	}
}

input {
	outline: 0;
	outline: none;
}

a[href] {
	transition: $transition-base;
}

svg {
	shape-rendering: geometricPrecision;

	path {
		transition: $transition-base;
	}
}

h1, h2, h3, h4, button, .button {
	text-rendering: geometricPrecision;
	font-smooth: subpixel-antialiased;
}

h1, .h1 {
	@include fs-lh(2.375rem, 3rem);
	@include color($color__dark);
	letter-spacing: -0.005em;
	display: inline-block;
	margin-right: 1rem !important;
	margin-top: 1.25rem !important;
	font-weight: 400;
	text-transform: uppercase;
	transition: $transition-base;

	@include media-breakpoint-down(md) {
		@include fs-lh(1.875rem, 2.1875rem);
		margin-right: .375rem !important;
	}

	@include media-breakpoint-down(sm) {
		@include fs-lh(1.625rem, 1.875rem);
		margin-top: 1.25rem;
	}
}

h2 {
	@include fs-lh(1.5625rem !important, 2.5rem !important);
	@include color($color__dark);
	margin: 3.5rem 0 2.5rem;
	font-weight: 400;
	text-transform: uppercase;

	@include media-breakpoint-down(lg) {
		@include fs-lh(1.625rem, 1.875rem);
		margin: 8rem 0 2.5rem;
	}

	@include media-breakpoint-down(md) {
		margin: 4rem 0 1.5rem;
		font-size: 20px;
		line-height: 127%;
	}

	@include media-breakpoint-down(xs) {
		@include text-smallest;
	}
}


.text-oneline {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.text-big {
	@include text-big;
}

.text-normal {
	@include text-normal;
}

.text-small {
	@include text-small;
}

.text-smallest {
	@include text-smallest;
}

.display-text-big {
	@include display-text-big;
}

.display-text-normal {
	@include display-text-normal;
}

.display-text-small {
	@include display-text-small;
}

.display-text-smallest {
	@include display-text-smallest;
}

.font-weight-bold {
	font-weight: 600 !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

/* width */
.w-auto {
	width: auto !important;
	min-width: auto;
}

.w-100 {
	width: 100% !important;
}

/* height */
.h-auto {
	height: auto !important;
}

.h-100 {
	height: 100% !important;
}

.h-100vh {
	height: 100vh !important;
}

/* other */
.pos-rel {
	position: relative !important;
}

.pos-abs {
	position: absolute !important;
}

.pos-static {
	position: static !important;
}

.overflow-hidden {
	overflow: hidden;
}

.overflow-visible {
	overflow: visible !important;
}

.invisible {
	visibility: hidden;
}

.border-radius {
	border-radius: $border-radius;
}

.box-shadow {
	box-shadow: $box-shadow;
}

.box-shadow-none {
	box-shadow: none !important;
}

.pe-none {
	pointer-events: none !important;
}

.pe-auto {
	pointer-events: auto !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.cursor-default {
	cursor: default !important;
}

.lazyload, .lazyloading, .lazyloaded {
	transition: opacity $transition-default-time-smallest $transition-default-effect;
}

.lazyload, .lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
}

.arrow-right {
	display: block;
	width: .625rem;
	height: .625rem;
	margin-right: .5rem;
	border-top: 1px solid;
	border-left: 1px solid;
	transform: rotate(135deg);
	transition: $transition-base;
	border-radius: .0625rem;
}