@font-face {
	font-family: 'Museo Sans Cyrl';
	src: local('Museo Sans Cyrl'), local('MuseoSansCyrl'),
	url('#{$fonts-path_default}/MuseoSansCyrl-300.woff2') format('woff2'),
	url('#{$fonts-path_default}/MuseoSansCyrl-300.woff') format('woff');
	font-style: normal;
	font-weight: 300;
	unicode-range: U+0020-007E, U+00A0-00FF, U+0410-0452, U+0489, U+2000-2064, U+2066-206F, U+20A0-20BF;
	font-display: fallback;
}

@font-face {
	font-family: 'Museo Sans Cyrl';
	src: local('Museo Sans Cyrl'), local('MuseoSansCyrl'),
	url('#{$fonts-path_default}/MuseoSansCyrl-500.woff2') format('woff2'),
	url('#{$fonts-path_default}/MuseoSansCyrl-500.woff') format('woff');
	font-style: normal;
	font-weight: 500;
	unicode-range: U+0020-007E, U+00A0-00FF, U+0410-0452, U+0489, U+2000-2064, U+2066-206F, U+20A0-20BF;
	font-display: swap;
}


@font-face {
	font-family: 'Museo Sans Cyrl';
	src: local('Museo Sans Cyrl'), local('MuseoSansCyrl'),
	url('#{$fonts-path_default}/MuseoSansCyrl-700.woff2') format('woff2'),
	url('#{$fonts-path_default}/MuseoSansCyrl-700.woff') format('woff');
	font-style: normal;
	font-weight: 700;
	unicode-range: U+0020-007E, U+00A0-00FF, U+0410-0452, U+0489, U+2000-2064, U+2066-206F, U+20A0-20BF;
	font-display: swap;
}