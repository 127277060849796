.certificates__address {
	margin: 56px -152px;
	padding: 104px 202px;
	color: $white;
	background-color: $color__darklight;
	opacity: 0.9;

	@include media-breakpoint-down(xxl) {
		margin: 48px -80px;
		padding: 72px 104px;
	}

	@include media-breakpoint-down(md) {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		margin: 32px 0;
		padding: 24px;
	}
}

.certificates__address-title {
	font-weight: 400;
	font-size: 25px;
	line-height: 30px;

	@include media-breakpoint-down(md) {
		@include display-text-small;
	}
}

.certificates__address-paragraph {
	margin: 2rem 0 2.5rem;

	@include media-breakpoint-down(md) {
		margin: 1rem 0;
	}
}

.certificates__address-image {
	position: relative;
	display: none;

	@include media-breakpoint-down(md) {
		display: flex;
		width: 247px;
		height: 212px;
		margin-top: 42px;
		margin-bottom: 30px;
	}

	.certificates__address-image-icon {
		position: absolute;
		bottom: -68px;
		width: 250px;
		height: 300px;
		color: $color__gold;
	}
}

.certificates__form {
	margin: 104px auto 76px;
	padding: 0 103px;

	@include media-breakpoint-down(xxl) {
		margin: 64px 0;
		padding: 0;
	}

	@include media-breakpoint-down(md) {
		margin: 70px 0;
	}

	.certificates__form-status {
		display: none;
		padding: 72px 48px;
		font-weight: 300;
		text-align: center;
		box-shadow: 0 3px 25px rgba(0, 30, 69, 0.05);

		.certificates__form-status-title {
			@include color($color__gold);
			font-size: 25px;
			line-height: 30px;
		}

		.certificates__form-status-kicker {
			@include display-text-small;
			@include color($color__graydark);
			margin-top: 2rem;
		}
	}
}