.not-found {
	margin: auto;
}

.not-found__container {
	display: flex;
	flex-flow: column nowrap;
	max-width: 500px;
	margin: 0 auto;
}

.not-found__main {
	position: relative;
}

.not-found__title {
	position: absolute;
	top: 70px;
	left: -114px;
	color: $black;
	font-weight: 300;
	font-size: 38px;
	line-height: 46px;

	@include media-breakpoint-down(xxl) {
		position: unset;
		text-align: center;
	}
}

.not-found__text {
	font-weight: 300;
	font-size: 288px;
	line-height: 346px;
	background: linear-gradient(0deg, $black 2%, #646464 78%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	@include media-breakpoint-down(sm) {
		font-size: 206px;
		line-height: 248px;
	}
}

.not-found__image {
	position: absolute;
	top: 39px;
	left: 141px;
	height: 260px;

	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 32px;
		left: 103px;
		height: 182px;
	}
}

.not-found__description {
	@include display-text-small;
	@include color($black);
	font-weight: 300;
	text-align: center;

	@include media-breakpoint-down(sm) {
		@include text-smallest;
		@include color($color__graydark);
		padding: 0 1.5rem;
	}
}

.not-found__button {
	margin: 1.5rem auto 0;
	max-width: 260px;
}

.not-found__burger {
	position: relative;
	margin-right: 1.5rem;

	.not-found__burger-span {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 18px;
		height: 2px;
		background-color: $white;
		transform: translate(-50%, -50%);

		&:nth-child(2) {
			top: calc(50% + 5px);
		}

		&:nth-child(3) {
			top: calc(50% - 5px);
		}
	}
}

.not-found__code-number {
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;

	@include media-breakpoint-down(sm) {
		max-width: 360px;
		max-height: 220px;
		margin: 0 auto;
	}
}
