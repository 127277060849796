.dark-tree-card {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	margin: 56px -152px;
	padding: 104px 154px;
	background-color: #474747;

	@include media-breakpoint-down(xxl) {
		margin: 48px -80px;
		padding: 72px 104px;
	}

	@include media-breakpoint-down(md) {
		margin: 32px -8px;
		padding: 24px 24px 40px;
	}

	@include media-breakpoint-down(sm) {
		padding: 24px 24px 0;
	}
}

.dark-tree-card__background-tree {
	position: absolute;
	right: -497px;
	bottom: 0;
	width: 100%;
	height: 754px;
	color: $white;

	@include media-breakpoint-down(xxl) {
		display: none;
	}
}

.dark-tree-card__title {
	margin-bottom: 32px;
	color: $white;
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;
	text-transform: uppercase;

	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 127%;
	}
}

.dark-tree-card__item {
	@include display-text-normal;
	margin-left: 22px;
	color: $white;
	font-weight: 300;
	list-style: disc;

	&:not(:last-child) {
		margin-bottom: 16px;
	}
}

.dark-tree-card__image {
	display: none;
	width: 260px;
	height: 220px;
	margin: 40px auto 0;
	color: $color__gold;

	@include media-breakpoint-down(sm) {
		display: block;
	}
}

.dark-tree-card__list-item {
	@include media-breakpoint-down(sm) {
		@include text-smallest;
		line-height: 130%;
	}
}