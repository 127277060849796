.container {
	width: 100%;
	max-width: $container__max_width;
	padding-left: 2.625rem;
	padding-right: 2.625rem;
	transition: $transition-base;

	.container_relative {
		position: relative;
	}

	.container_page {
		max-width: 100%;
		margin-right: 0;
		margin-left: 260px;
		padding: 0;

		@media (width >= 1400px) {
			max-width: 1569px;
			margin: 0 auto;
			padding: 0 80px;
		}

		@media (width <= 1391px) {
			max-width: 100%;
			margin: 0 auto;
			padding: 0 80px;
		}
	}

	.container_padding_big {
		padding: 0 80px;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.container-fluid {
	max-width: 100%;
}

.container_tight {
	width: 100%;
	max-width: $container__tight_max_width;
	padding-left: 2.625rem;
	padding-right: 2.625rem;
	margin-top: -3.25rem;
	transition: $transition-base;

	@include media-breakpoint-down(sm) {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	h1 {
		margin-top: 3.25rem !important;

		@include media-breakpoint-down(sm) {
			margin-top:  2.75rem !important;
		}
	}
}