.products-slider {
	position: relative;
	margin-top: 2.5rem;

	@include media-breakpoint-down(lg) {
		margin-top: 1.5rem;
	}
}

.products-slider__viewport {
	width: 100%;
	margin: 0 auto;
}

.catalog__products_gallery_swap {
	.swiper {
		.swiper-wrapper {
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: .375rem;
				right: .375rem;
				background: transparent;
				border-radius: $border-radius-xxl;
				opacity: 0;
				pointer-events: none;
				z-index: -1;
				transition: $transition-base;
			}
		}

		&:not(.swiper-initialized) {
			.swiper-wrapper {
				&:before {
					opacity: 1;
					background: #f7f7f7;
					z-index: 2;
				}

				.catalog-product-card__wrapper {
					opacity: 0;
				}
			}
		}
	}

	.catalog-product-card {
		padding: 1.25rem 1.75rem;

		@include media-breakpoint-down(lg) {
			padding: 1rem 1.25rem;
		}

		@include media-breakpoint-down(sm) {
			padding: .875rem .75rem;
		}

		&.catalog-product-card_labels {
			.catalog-product-card__offers {
				top: 3.25rem;
			}
		}

		&:hover {
			.catalog-product-card__title {
				@include color($white !important);
			}

			.catalog-product-card__info {
				.catalog-product-card__info_normal {
					opacity: 0;
					pointer-events: none;
				}

				.catalog-product-card__info_hover {
					background-color: rgba(0, 0, 0, .3);
					backdrop-filter: blur(10px);
					opacity: 1;
					pointer-events: all;
				}
			}

			.catalog-product-card__price {
				text-shadow: 0 0 1px rgba(255, 255, 255, .2), 0 0 8px rgba(255, 255, 255, .4), 0 0 18px rgba(255, 255, 255, .4);
			}
		}

		.card__like-button {
			right: .625rem;
			top: .5rem;
		}
	}

	.catalog-product-card__description {
		flex-grow: 1;
		margin-top: 0;

		.catalog-product-card__info_normal {
			display: flex;
			align-items: center;
			opacity: 1;
			height: 100%;
			transition: $transition-base;
		}
	}

	.catalog-product-card__info {
		/*margin: 0 -1.75rem -1.5rem;
		padding: 1rem 1.5rem 1.125rem 1.75rem;
		*/
		width: calc(100% + 3.5rem);
		line-height: 0;
		border-radius: 0 0 $border-radius $border-radius;
		transition: $transition-base;
		z-index: 2;

		@include media-breakpoint-down(lg) {
			/*margin: 0 -1.25rem -1rem !important;
			padding: 1rem 1.25rem !important;*/
		}

		@include media-breakpoint-down(sm) {
			/*margin: 0 -.75rem -1rem !important;
			padding: .75rem !important;*/
		}
	}

	.catalog-product-card__info_hover {
		display: flex;
		align-items: center;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 -1.75rem -1.25rem -1.75rem;
		padding: 1.125rem 1.125rem 1.25rem 1.75rem;
		opacity: 0;
		transition: $transition-base;
		pointer-events: none;
		border-radius: 0 0 $border-radius $border-radius;
		justify-content: space-between;

		@include media-breakpoint-down(lg) {
			/*padding: 1rem 1.25rem 1rem 1.25rem;
			margin: -1rem -1.25rem 0 -1.25rem;*/
			margin: -1rem -1.25rem;
			padding: 1rem 1.25rem;
		}

		@include media-breakpoint-down(sm) {
			margin: -.625rem -.75rem -.875rem;
			padding: .625rem .875rem;
		}
	}

	.catalog-product-card__button_buy {
		@include color($white);
		margin: -.5rem 0;

		svg {
			background-color: transparent;
			box-shadow: none;
		}

		&.loading {
			&:after {
				border-top-color: $white;
				border-bottom-color: $white;
			}
		}
	}

	.catalog-product-card__offers {
		margin-top: 0;
		position: absolute;
		left: 1.375rem;
		top: 1.25rem;

		@include media-breakpoint-down(lg) {
			top: 1.25rem;
		}

		@include media-breakpoint-down(sm) {
			top: .75rem;
		}

		&.offers__scrollyeah {
			margin-left: 0;
			left: 0;
			width: 100%;

			.catalog-product-card__price {
				margin-left: 0;
			}
		}
	}

	.catalog-product-card__title {
		font-size: 18px;
		line-height: 115%;
		transition: $transition-base, color $transition-default-effect $transition-default-time-smaller;
		margin-top: 0;

		@include media-breakpoint-down(lg) {
			font-size: 16px;
			font-weight: 400;
			line-height: 18px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 14px;
			line-height: 110%;
		}
	}

	.catalog-product-card__price {
		@include media-breakpoint-up(lg) {
			line-height: 125%;
			padding: .375rem 1.25rem .375rem 1.75rem;
		}

		@include media-breakpoint-down(lg) {
			@include fs(1.125rem);
			padding: .125rem 1.125rem .125rem 1.25rem;
		}

		@include media-breakpoint-down(md) {
			@include fs(1rem);
		}

		@include media-breakpoint-down(sm) {
			@include fs-lh(.9375rem, 1.75rem);
		}
	}
}

.catalog__products_gallery_slides {
	.catalog-product-card {
		&:hover {
			height: calc(100% + 1.75rem);
			z-index: 10;

			.catalog-product-card_additional {
				animation-name: show-cart-additional;
				animation-timing-function: ease-out;
				animation-duration: .3s;
				animation-fill-mode: forwards;
			}

			.card__like-button {
				svg {
					fill: $white;
				}
			}

			.catalog-product-card-slider__pagination-wrapper {
				opacity: 1;

				.catalog-product-card-slider__pagination-button {
					&:before {
						background-color: hsla(0, 0%, 90%, .6);
					}

					&.active {
						&:before {
							background-color: $color__gold !important;
						}
					}
				}
			}

			.catalog-product-card_prop__sticker {
				&:not(.catalog-product-card_prop__sticker_bottom_hover) {
					color: #bbb !important;
					border-color: #ccc !important;
					opacity: .6;
					bottom: -2px;
				}

				&.catalog-product-card_prop__sticker_bottom_hover {
					color: transparent !important;
					bottom: -50px !important;
					opacity: 0 !important;
					pointer-events: none;
				}
			}
		}

		.catalog-product-card_prop__sticker {
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 1;

			svg {
			}

			&[href] {
				&:hover {
					opacity: 1;
					border-color: #e0e0e0 !important;
					color: $color__gold !important;

					svg {
						color: $color__gold !important;
					}
				}

				&:active {
					border-color: #ccc !important;
					color: $color__gold_active !important;

					svg {
						color: $color__gold_active !important;
					}
				}
			}
		}

		@include media-breakpoint-down(sm) {
			padding: .75rem .875rem .5rem;
		}

		&.catalog-product-card_new {
			.catalog-product-card__price {
				line-height: 1;
				padding: 0 1.125rem 0 1.375rem;
				color: $color__dark !important;
				background-color: transparent;
			}

			.catalog-product-card__title {
				font-size: 1rem !important;
				line-height: 115% !important;
				font-weight: 300;
				margin-top: 2px;
				color: $color__darklight;
			}
		}
	}

	.catalog-product-card_badges {
		top: 0;
	}

	.catalog-product-card__badge {
		padding: 0.625rem .75rem;
		text-transform: uppercase;
		clip-path: polygon(100% 0, 100% 100%, 50% 86%, 0 100%, 0 0);
		font-weight: 400;
		border-radius: 0;
	}

	.catalog-product-card__gallery_slides {
		flex-grow: 1;
		align-items: center;
		display: flex;
	}

	.catalog-product-card__description {
		flex-direction: column;
	}

	.catalog-product-card__offers_wrapp {

	}

	.catalog-product-card__offers {
		&.offers__scrollyeah {
			margin-right: -1.125rem;
			margin-left: -1.375rem;

			@include media-breakpoint-down(sm) {
				margin-left: -.875rem;
				margin-right: -.875rem;
			}

			.catalog-product-card__price {
				margin-left: 0 !important;
			}
		}
	}

	.catalog-product-card__price {
		padding: .125rem 1.125rem .125rem 1.375rem;
		align-items: center;
		display: flex;

		@include media-breakpoint-down(lg) {
		}

		@include media-breakpoint-down(sm) {
			padding: 0 .75rem 0 .875rem;
			margin-left: -.875rem;
		}
	}

	.catalog-product-card__price_old {
		@include media-breakpoint-down(lg) {
			@include fs(80%);
		}

		/*@include media-breakpoint-down(sm) {
			@include fs(80%);
		}*/
	}

	.catalog-product-card__title {
		font-size: 18px;
		line-height: 115%;
		/*max-height: 38px;
		overflow: hidden;*/

		@include media-breakpoint-down(md) {
			font-size: 16px;
			line-height: 115%;
		}

		@include media-breakpoint-down(sm) {
			font-size: 14px !important;
			line-height: 110% !important;
			margin-top: .5rem;
		}
	}

	.catalog-product-card_additional_wrapp {
		position: relative;
	}

	.catalog-product-card_additional {
		display: flex;
		justify-content: space-between;
		border-radius: 0 0 .55555rem .55555rem;
		align-items: start;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		left: -1.375rem;
		right: -1.375rem;
		width: calc(100% + 2.75rem);
		top: 0;
		padding: .75rem 1.375rem .75rem;
		overflow: hidden;

		@include media-breakpoint-down(sm) {
			padding: .5rem .875rem .5rem;
			left: -.875rem;
			right: -.875rem;
			width: calc(100% + 1.75rem);
		}
	}

	.catalog-product-card__button_buy {
		@include color($color__darklight);

		@include media-breakpoint-up(md) {
			right: -2px;
		}

		&.loading {
			&:after {
				border-top-color: $color__gold_hover;
				border-bottom-color: $color__gold_hover;
			}
		}

		&:hover {
			@include color($color__gold_hover);
		}

		&:active {
			@include color($color__gold_active);
		}
	}

	@include media-breakpoint-down(lg) {
		.catalog-product-card-slider__overlay, .catalog-product-card-slider__pagination {
			display: none;
		}
	}
}

.catalog-product-card_props {
	@include color($color__dark);
	font-size: 12px;
	font-weight: 600;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	z-index: 1;
	line-height: 0;
	row-gap: .125rem;
	max-width: calc(100% - 1.875rem);

	.catalog-product-card_prop__wrapp {
		display: flex;
		align-items: center;
		height: 18px;
		text-decoration: none;
		white-space: nowrap;
	}

	a.catalog-product-card_prop__wrapp {
		@include color($color__dark);

		&:hover {
			@include color($color__gold !important);

			.catalog-product-card_prop__name {
				@include color($color__gold !important);
			}
		}
	}

	.catalog-product-card_prop__name {
		font-weight: 300;
		font-size: 12px;
		margin-right: .125rem;
		color: #999;
		transition: $transition-base;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.catalog-product-card_prop__icon_wrapp {
		display: flex;
		width: 26px;
		justify-content: center;
		margin-right: .25rem;
	}

	.catalog-product-card_prop__icon {
		color: $color__gray !important;
		fill: $color__gray !important;
	}

	.catalog-product-card_prop__icon_size {
		width: 25px;
		height: 25px;
	}

	.catalog-product-card_prop__icon_diameter {
		width: 16px;
		height: 16px;
	}

	.catalog-product-card_prop__icon_weight {
		width: 16px;
		height: 16px;
	}

	.catalog-product-card_prop__icon_lug {
		color: $color__gray !important;
		fill: $color__gray !important;
		position: relative;
		top: -3px;
		right: -1px;
	}
}

.catalog-product-card_prop__sticker {
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 12px;
	margin-top: 4px;
	color: $color__gold;
	padding: 3px 4px;
	border-radius: $border-radius-sm;
	border: 1px solid;
	text-decoration: none;
	opacity: 1;
	transition: $transition-base;
	backdrop-filter: blur(2px);
}

.catalog-product-card_prop__sticker_icon {
	height: 12px;
	line-height: 12px;
	display: flex;
	align-items: center;
	margin-right: 2px;
}

.catalog-product-card_prop__sticker_value {
	height: 12px;
	line-height: 12px;
	font-weight: 400;
}

.catalog-product-card__button_buy {
	@include color($white);
	display: block;
	cursor: pointer;
	position: relative;
	z-index: 1;
	border: 0;
	background: transparent;
	outline: none;
	padding-left: 6px;
	padding-right: 6px;
	transition: $transition-base;

	&.loading {
		&:after {
			border-top-color: $white;
			border-bottom-color: $white;
			height: 10px;
			left: 14px;
			top: 14px;
			width: 10px;
		}
	}

	svg {
		height: 32px;
		width: 26px;
		background-color: $white;
		box-shadow: 0 0 2px 3px $white;
		position: relative;
		z-index: 2;
	}

	&.active {
		.header__control_count {
			background-color: $color__green;

			&:after {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M9.86 18a1 1 0 0 1-.73-.32l-4.86-5.17a1 1 0 1 1 1.46-1.37l4.12 4.39 8.41-9.2a1 1 0 1 1 1.48 1.34l-9.14 10a1 1 0 0 1-.73.33z'/%3E%3C/svg%3E%0A");
				background-size: 12px;
			}
		}
	}

	.header__control_count {
		width: .875rem;
		height: .875rem;
		font-size: 11px;
		position: absolute;
		z-index: 3;
		left: inherit;
		right: 4px;
		top: 2px;
		transition: $transition-base, height 0s linear, width 0s linear, top 0s linear, left 0s linear, right 0s linear;

		&:after {
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'%3E%3Cpath fill='white' d='M.48 7.08V5.52h4.48V.6H6.6v4.92h4.46v1.56H6.6V12H4.96V7.08z'/%3E%3C/svg%3E");
			background-position: 50%;
			background-repeat: no-repeat;
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			background-size: 10px;
			box-shadow: 0 0 100px rgba(255, 255, 255, 0);
		}
	}

	&:hover {
		@include color(#e2e2e2);

		.header__control_count {
			background-color: $color__gold_hover;
			box-shadow: 0 0 12px 3px rgba(208, 163, 69, .6), -16px 12px 24px 16px hsla(0, 0%, 100%, .35);
			height: 1rem;
			width: 1rem;
			top: 1px;
			left: inherit;
			right: 3px;
		}
	}

	&:active {
		@include color(#e0e0e0);

		.header__control_count {
			background-color: $color__gold_active;
			box-shadow: none;
		}
	}
}

.catalog__products_slider {
	.catalog-product-card__wrapper {
		width: 25%;
		padding: 0 .75rem;

		@include media-breakpoint-down(xl) {
			width: 33.33%;
		}

		@include media-breakpoint-down(lg) {
			padding: 0 .5rem;
		}

		@include media-breakpoint-down(md) {
			/*width: 50%;*/
			width: 230px !important;
		}
	}

	.product-card__promo-image {
		height: inherit;
	}
}

.catalog__products_slider_fixed {
	.catalog-product-card__wrapper {
		width: 298px;
	}
}

.catalog__products_slider__list.catalog__products_slider__list_centered .catalog-product-card__wrapper.swiper-slide-active {
	@include media-breakpoint-down(md) {
		.product-card__image {
			opacity: 1 !important;
		}

		.catalog-product-card__price {
			background-color: $color__gold_hover;
			text-shadow: 0 0 1px rgba(255, 255, 255, .2), 0 0 8px rgba(255, 255, 255, .4), 0 0 18px rgba(255, 255, 255, .4);
		}

		.catalog-product-card__title {
			@include color($white !important);
		}

		.catalog-product-card__info {

			.catalog-product-card__info_normal {
				opacity: 0;
				pointer-events: none;
			}

			.catalog-product-card__info_hover {
				background-color: rgba(0, 0, 0, .3);
				backdrop-filter: blur(10px);
				opacity: 1;
				pointer-events: all;
			}
		}
	}
}

.catalog__products_slider_big_centered {

	.catalog__products_slider__list:not(.catalog__products_slider__list_centered) .catalog-product-card__wrapper.swiper-slide-next,
	.catalog__products_slider__list.catalog__products_slider__list_centered .catalog-product-card__wrapper.swiper-slide-active,
	{
		width: 40% !important;

		@include media-breakpoint-down(md) {
			width: 230px !important;
		}

		.catalog-product-card {
			&:hover {
				.product-card__image {
					opacity: 1 !important;
				}
			}
		}

		.catalog-product-card__price {
			background-color: $color__gold_hover;
		}

		.product-card__promo-image {
			&:last-child:not(.lazyload):not(:first-child), &.lazyloaded:last-child:not(:first-child) {
				opacity: 1;
			}
		}

		.product-card__image {
			&:last-child:not(.lazyload):not(:first-child), &.lazyloaded:last-child:not(:first-child) {
				opacity: 1;
			}
		}

		.catalog-product-card__price {
			text-shadow: 0 0 1px rgba(255, 255, 255, .2), 0 0 8px rgba(255, 255, 255, .4), 0 0 18px rgba(255, 255, 255, .4);
		}

		.catalog-product-card__title {
			@include color($white !important);
		}

		.catalog-product-card__info {

			.catalog-product-card__info_normal {
				opacity: 0;
				pointer-events: none;
			}

			.catalog-product-card__info_hover {
				background-color: rgba(0, 0, 0, .3);
				backdrop-filter: blur(10px);
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	.catalog__products_slider__list:not(.catalog__products_slider__list_centered) .catalog-product-card__wrapper:not(.swiper-slide-next),
	.catalog__products_slider__list.catalog__products_slider__list_centered .catalog-product-card__wrapper:not(.swiper-slide-active) {
		@include media-breakpoint-down(md) {
			width: 230px !important;
		}
	}

	.catalog-product-card__wrapper {
		width: 30%;
		height: inherit;
		display: flex;
		align-self: center;
		transition: all $transition-default-time linear;

		.catalog-product-card {
			align-self: center;
			height: auto;
			margin: auto;

			&:hover {
				.product-card__image {
					opacity: 0 !important;
				}
			}
		}
	}
}

.catalog__products_slider__list {
	margin: -7rem -3.5rem;
	padding: 7rem 3rem;
	overflow: hidden;

	.catalog-product-card {
		width: 100%;
	}

	@include media-breakpoint-down(md) {
		margin: -7rem -5.75rem;
		padding: 7rem 5.25rem;
	}

	@include media-breakpoint-down(sm) {
		padding: 7rem 1rem;
		margin: -7rem -1rem;
	}

	&.catalog__products_slider__list_centered {
		@include media-breakpoint-down(md) {
			.catalog-product-card__gallery_swap {
				img {
					min-height: inherit;
				}
			}

			.swiper-slide {
				display: flex;
				height: inherit;
			}

			.catalog-product-card {
				height: auto !important;
			}

			.catalog-product-card__wrapper {
				width: 30%;
				height: inherit;
				align-self: center;

				&.swiper-slide-active {
					width: 40%;
				}
			}
		}
	}

	&:before, &:after {
		background: $white;
		bottom: 4.125rem;
		content: '';
		display: block;
		position: absolute;
		top: 0;
		width: 4.25rem;
		z-index: 2;
	}

	&:before {
		box-shadow: 3px 0 4px -1px hsla(0, 0%, 100%, .9), 2px 0 5px hsla(0, 0%, 100%, .8);
		left: -1.25rem;

		@include media-breakpoint-down(md) {
			left: -1.125rem;
		}

		@include media-breakpoint-down(sm) {
			left: -4.25rem;
		}
	}

	&:after {
		box-shadow: -3px 0 4px -1px hsla(0, 0%, 100%, .9), -2px 0 5px hsla(0, 0%, 100%, .8);
		right: -1.25rem;

		@include media-breakpoint-down(md) {
			right: -1.125rem;
		}

		@include media-breakpoint-down(sm) {
			right: -4.25rem;
		}
	}
}

.catalog__products_slider__nav {

}

.catalog__products_slider__nav__button {
	background-color: $color__gold;
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 0;
	border: none;
	outline: none;
	cursor: pointer;
	mask-position: center;
	mask-repeat: no-repeat;
	z-index: 3;
	transition: $transition-base;
	width: 40px;
	margin-top: -1rem;

	@media (max-width: $container__max_width + 32 - 1) {
		width: 1.75rem;
		mask-size: 1.75rem;

		&.catalog__products_slider__btn_prev {
			left: 1.5rem !important;
		}

		&.catalog__products_slider__btn_next {
			right: 1.5rem !important;
		}
	}

	@include media-breakpoint-down(lg) {
		display: none;
	}

	&.swiper-button-disabled {
		background-color: $color__gray;
	}

	&.catalog__products_slider__btn_prev {
		mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39' height='82' viewBox='0 0 44 86' fill='none'%3E%3Cpath d='M42 84L3 43L42 2' stroke='currentColor' stroke-width='3' stroke-miterlimit='10'/%3E%3C/svg%3E");
		left: 0;
	}

	&.catalog__products_slider__btn_next {
		right: 0;
		mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39' height='82' viewBox='0 0 44 86' fill='none'%3E%3Cpath d='M2 2L41 43L2 84' stroke='currentColor' stroke-width='3' stroke-miterlimit='10'/%3E%3C/svg%3E");
	}

	&:hover {
		background-color: $color__gold_hover;
	}

	&:active {
		background-color: $color__gold_active;
	}
}