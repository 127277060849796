.promotional-block {
	max-width: 967px;
	margin: 0 auto;
	background-position: center !important;
	background-size: cover;

	.button__black {
		@include color($black);
		padding: 16px 40px;
		text-align: center;
		text-decoration: none;
		background-color: transparent;
		border: 1px solid $black;
		transition: $transition-base;

		&:hover {
			color: $white;
			background-color: $black;
		}
	}

	@include media-breakpoint-down(lg) {
		max-width: 674px;
	}
}

.promotional-block__container {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 32px;
	align-items: flex-start;
	padding: 80px 0 108px 120px;

	max-width: 967px;
	margin: 0 auto;
	background: url("../img/index/rectangle-min.jpg");
	background-color: rgba(255, 255, 255, 0.6);
	background-position: center !important;
	background-size: cover;
	background-blend-mode: overlay;

	@include media-breakpoint-down(lg) {
		min-height: 345px;
		padding: 50px;
	}

	@include media-breakpoint-down(md) {
		align-items: center;
		min-height: 362px;
		padding: 72px 45px;
	}

	@include media-breakpoint-down(sm) {
		padding: 40px;
	}
}

.promotional-block__image {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	object-fit: cover;

	&.lazy:not(.loaded) {
		color: transparent;
		background-color: #e8e6f0;
		animation: blink 1.1s linear infinite;
	}
}

.promotional-block__header {

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	max-width: 315px;

	@include media-breakpoint-down(lg) {
		max-width: 400px;
	}

	@include media-breakpoint-down(md) {
		flex-direction: column;
		align-items: center;
		max-width: 200px;
	}
}

.promotional-block__logo {
	width: 107px;
	height: 93px;
	fill: $color__dark;

	@include media-breakpoint-down(lg) {
		width: 69px;
		height: 60px;
	}
}

.promotional-block__title {
	align-self: center;
	margin-left: 16px;
	color: $color__dark;
	font-weight: 500;
	font-size: 38px;
	line-height: 46px;
	text-transform: uppercase;

	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
	}

	@include media-breakpoint-down(md) {
		margin: 0;
		text-align: center;
	}

	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 24px;
	}
}

.promotional-block__description {
	max-width: 500px;
	color: $color__dark;
	font-weight: 500;
	font-size: 32px;
	line-height: 38px;

	@include media-breakpoint-down(lg) {
		@include display-text-normal;
		max-width: 400px;
	}

	@include media-breakpoint-down(md) {
		text-align: center;
	}

	@include media-breakpoint-down(sm) {
		@include display-text-normal;
		margin: 1.375rem 0;
	}
}
