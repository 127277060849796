.contact-form {
	padding: 4.5rem 2.5rem;
	background-color: rgba(229, 229, 229, 0.3);

	@include media-breakpoint-down(sm) {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	&.contact-form__loyality {
		background-color: transparent;
	}

	&.contact-form__certificates {
		background-color: transparent;

		.contact-form__text {
			text-align: left;
			color: $color__dark;
			font-size: 1rem;
			font-weight: 300;
		}

		.nice-select {
			z-index: inherit;

			.current {
				z-index: inherit;
			}
		}
	}
}

.contact-form_color_dark {
	background-color: rgba(51, 51, 51, 0.9);

	@include media-breakpoint-down(lg) {
		background-color: rgba(229, 229, 229, 0.3);
	}

	.contact-form__title {
		@include color($white);

		@include media-breakpoint-down(lg) {
			@include color($black);
		}
	}

	.form__status_kicker {
		@include color($white);
	}

	.form__status {
		background-color: hsla(0, 0%, 30%, .6);
		box-shadow: 0 3px 25px rgba(0, 30, 69, .2);
	}
}

.contact-form__title {
	margin-bottom: 58px;
	font-weight: 300;
	font-size: 38px !important;
	text-align: center;

	@include media-breakpoint-down(sm) {
		font-size: 25px !important;
		margin-bottom: 2rem;
	}
}

.contact-form__text {

}

.contact-form__text-wrapper {
	text-align: center;
	margin-top: 1.5rem;
}

.contact-form__politic-link {
	@include display-text-small;
	@include color($color__dark);
	font-weight: 300;
}

.contact-form__button {
	margin-top: 2.5rem;
	margin-left: auto;
	margin-right: auto;
	min-width: 16rem;

	@include media-breakpoint-down(sm) {
		margin-top: 2rem;
	}
}
