.contact-card {
	@include color($white);
	@include display-text-small;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 160px 80px 0;
	overflow: hidden;
	font-weight: 300;
	background-color: rgba(51, 51, 51, 0.8);

	@include media-breakpoint-down(xxl) {
		padding: 120px 80px 0;
	}

	@include media-breakpoint-down(lg) {
		padding: 4.5rem 3rem 2rem;
	}

	@include media-breakpoint-down(sm) {
		background-color: rgba(51, 51, 51, 0.9);

		.contact-card__container {
			height: auto;
		}

		.contact-card__button {
			width: 100%;
			text-align: center;
		}
	}

	&.contact-card_with-image {
		@include color($color__darklight);
		display: grid;
		grid-template-columns: 40% 60%;
		max-width: 100%;
		padding: 0;
		background-color: transparent;

		@include media-breakpoint-down(xxl) {
			padding: 0;
		}

		@include media-breakpoint-down(lg) {
			padding: 0;
		}

		@include media-breakpoint-down(sm) {
			grid-template-rows: 320px 1fr;
			grid-template-columns: 1fr;
			padding: 0;
			background-color: transparent;

			.contact-card__link-wrapper {
				justify-content: center;
			}

			.contact-card__button {
				margin-top: 1.5rem;
			}

		}

		.contact-card__bg-image {
			@include color($color__darklight);
			top: 10%;
			left: 45%;
			z-index: -1;
			width: 480px;
			height: 414px;
		}

		.contact-card__container {
			width: 100%;
			padding: 2rem 3.125rem;
			background: rgba(229, 229, 229, 0.3);

			@include media-breakpoint-down(sm) {
				padding: 1rem .5rem 2.5rem;
				text-align: center;
			}
		}

		.contact-card__tel {
			@include color($color__darklight);
		}
	}
}

.contact-card__button {
	display: inline-block;
	min-width: 12.5rem;
}

.contact-card__image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.contact-card__slider {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.contact-card__slider-viewport {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;

	.contact-card__image {
		flex-shrink: 0;
	}
}

.contact-card__slider-navigation__wrapp {
	position: relative;
	top: calc(100% - 44px);
	max-width: 120px;
	height: 34px;
	margin: 0 auto;
}

.contact-card__slider-navigation {
	@include color($white);
	@include display-text-small;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 0 40px;
	font-weight: 300;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 67px;
	height: 34px;
	transform: translateX(-50%);
	position: relative;
	bottom: 0;
	top: 0;
	left: 50%;
	right: 0;
	z-index: 1;

	> span {
		margin: 0 .25rem;
	}
}

.contact-card__arrow {
	display: block;
	width: 10px;
	height: 20px;
}

.contact-card__arrow-button {
	@include color($white);
	height: 34px;
	margin: 0;
	padding: 7px 12px;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	transition: $transition-base;
	position: absolute;
	top: 0;
	z-index: 2;

	&:disabled {
		@include color($color__light);
		cursor: default;
	}

	&.contact-card__arrow-button_prev {
		left: 0;
	}

	&.contact-card__arrow-button_next {
		right: 0;
	}
}

.contact-card__container {
	position: relative;
	z-index: 2;
	height: 100%;
	overflow: hidden;
}

.contact-card__kicker {
	font-weight: 500;
}

.contact-card__title {
	margin: 1rem 0;
	font-weight: 300;
	font-size: 25px;
	line-height: 32px;
}

.contact-card__text {
	margin-top: .5rem;

	& > span {
		font-weight: 500;
	}
}

.contact-card__text_flex {
	display: flex;

	& > span {
		margin-left: 1ch;
	}
}

.contact-card__tel {
	@include color($white);
	display: block;
	margin-top: 24px;
	font-size: 20px;
	text-decoration: none;

	&:hover {
		@include color($color__gold !important);
	}
}

.contact-card__link {
	@include color($color__gold);
	display: inline-block;
	margin-right: 1rem;

	&:hover {
		@include color($color__muted !important);
	}
}

.contact-card__link-wrapper {
	display: flex;
	align-content: center;
	margin-top: .375rem;
}

.contact-card__icon {
	@include color($white);
	width: 25px;
	height: 25px;
	margin-right: .5rem;
	border: 2px;

	&.contact-card__icon_wa {
		height: 20px;
		width: 20px;
		position: relative;
		bottom: -2px;
	}
}

.contact-card__button {
	margin-top: 32px;
}

.contact-card__bg-image {
	@include color($white);
	position: absolute;
	top: 0;
	left: 0;
	width: 940px;
	height: 930px;
}
