.menu {
	background-color: transparent;
	-webkit-box-shadow: 0 4px 20px rgba(0, 30, 69, .08);
	box-shadow: 0 4px 20px rgba(0, 30, 69, .08);
	pointer-events: none;
	position: absolute;
	width: 100%;
	z-index: -1;
	opacity: 0;
	top: -100%;
	transition: $transition-base;

	&:not(.opened) {
		pointer-events: none;
		z-index: -1;
		visibility: hidden;
	}

	&:after {

	}

	&.opened {
		opacity: 1;
		pointer-events: all;
		top: 205px;
		z-index: 1000;
		background-color: $white;
		box-shadow: 1px 1px 8px -1px rgba(0,0,0,.16);

		/*@include media-breakpoint-down(xl) {
			top: 189px !important;
		}

		@include media-breakpoint-down(lg) {
			top: 147px !important;
		}

		@include media-breakpoint-down(md) {
			top: 64px !important;
		}*/

		& + .menu__backdrop {
			background-color: $color__darklight;
			opacity: .7;
			z-index: 9;
			pointer-events: all;
		}
	}

	& + .menu__backdrop {
		display: block;
		position: fixed;
		width: 100%;
		min-height: calc(100vh + 60px);
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		opacity: 0;
		pointer-events: none;
		z-index: -1;
		background-color: transparent;
		transition: $transition-base;

		&.show {
			background-color: $color__darklight;
			opacity: .7;
			z-index: 9;
			pointer-events: all;
		}
	}
}


.menu__container {
	display: grid;
	grid-gap: 1.5rem;
  	grid-template-columns: 148px 1fr;

	@include media-breakpoint-down(lg) {
		grid-gap: .825rem;
	}

	@include media-breakpoint-down(md) {

	}

	&::-webkit-scrollbar {
		width: 11px;
	}

	&::-webkit-scrollbar-track {
		height: 100%;
		background: none;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #e3e2de;
		border: none;
		border-radius: 10px;
		margin: 2.125rem 0;
	}
}

.menu__list_right_wrapp {
	transition: $transition-base;
	overflow: hidden;

	&:before {
		box-shadow: 0 0 10px 5px #fff;
		content: '';
		display: block;
		height: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;
	}

	&:after {
		box-shadow: 0 0 10px 5px #fff;
		content: '';
		display: block;
		height: 0;
		position: absolute;
		bottom: 0;
		width: 100%;
		z-index: 2;
	}

	@include media-breakpoint-down(lg) {
		margin-right: -20px;
	}
}

.menu__list {
	overflow-y: auto;
	padding: 2.25rem 0;
	position: relative;

	@include media-breakpoint-down(lg) {
		padding: 30px 0;
	}

	&.menu__list_left {
		flex-shrink: 0;
	}

	&.menu__list_right {
		position: absolute;
		top: 0;
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		width: 100%;
		margin: 0 -1.25rem 0 0;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		pointer-events: none;
		opacity: 0;
		z-index: -1;
		background: $white;
		transition: $transition-base, opacity $transition-default-time-bigger ease-in;

		@include media-breakpoint-down(xl) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include media-breakpoint-down(lg) {
			padding-right: 20px;
			padding-left: 20px;
		}

		&.active {
			pointer-events: all;
			opacity: 1;
			z-index: 1;
			transition: $transition-base, opacity $transition-default-time-bigger ease-out;
		}
	}

	&::-webkit-scrollbar {
		width: 11px;
	}

	&::-webkit-scrollbar-track {
		height: 100%;
		background: none;
		margin: 2.5rem 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #e3e2de;
		border: none;
		border-radius: 10px;
	}
}

.menu__list, .menu__list_right_wrapp {
	max-height: calc(100vh - 206px);

	@include media-breakpoint-down(xl) {
		max-height: calc(100vh - 148px);
	}

	@include media-breakpoint-down(md) {
		max-height: calc(100vh - 62px) !important;
	}
}

.menu__button {
	@include color($black);
	@include text-small;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0;
	font-weight: 300;
	line-height: 2.875rem;
	text-align: left;
	text-decoration: none;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	transition: $transition-base;

	@include media-breakpoint-down(md) {
		.menu__button_submenu {
			position: relative;

			span {
				display: flex;
				align-items: center;

				.menu__button-icon {
					flex-shrink: 0;
				}
			}

			.mobile-menu__tab-arrow {
				top: 50%;
				transform: translateY(-50%);
			}

			+ .menu__list {
				padding: 16px 15px 0;

				&:not(.active) {
					display: none;
				}
			}
		}
	}

	&.active, &:hover {
		@include color($color__gold !important);

		.menu__button-icon {
			background-color: $color__gold !important;
		}
	}
}

.menu__button-icon {
	display: block;
	flex-shrink: 0;
	width: 1.875rem;
	height: 1.875rem;
	margin-right: .625rem;
	background-color: $color__dark;
	transition: $transition-base;
	mask-image: var(--mask);
	mask-size: contain;
	mask-repeat: no-repeat;
	mask-position: center;

	&.menu__button-icon__all {
		mask-size: 1.375rem;
	}
}

.menu__button-icon_wrapp {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.875rem;
	height: 1.875rem;
	margin-right: .625rem;

	.menu__button-icon {
		background-color: $color__dark;
		margin: 0;
		width: 1.375rem;
		height: 1.375rem;
	}
}