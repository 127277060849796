.ya-share2 {
	.ya-share2__container {
		height: 100%;

		.ya-share2__container_size_m {
			.ya-share2__item_more.ya-share2__item_has-pretty-view {
				.ya-share2__icon_more {
					display: none;
				}
			}
		}
	}

	.ya-share2__list {
		width: 100%;
		height: 100%;
	}

	.ya-share2__item {
		width: 100%;
		height: 100%;
	}

	.ya-share2__link_more {
		height: 100%;

		background: none !important;
	}

	.ya-share2__popup_direction_bottom {
		top: 56px !important;
	}
}
