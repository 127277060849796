.catalog-product-card-slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	@include media-breakpoint-down(lg) {
		& > a {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}

.catalog-product-card-slider__list {
	display: flex;
	height: 100%;
	transition: $transition-base;
}

.catalog-product-card-slider__item {
	min-width: 100%;
	height: 100%;
	display: flex;

	img {
		width: 100%;
		/*height: 100%;*/
		object-fit: contain;
		object-position: center;
		border-radius: $border-radius;
		margin: auto;
	}
}

.catalog-product-card-slider__overlay {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;

	.catalog-product-card-slider__overlay-item {
		width: 100%;
		background-color: transparent;
	}
}

.catalog-product-card-slider__pagination {
	display: flex;
	justify-content: center;
}

.catalog-product-card-slider__pagination-wrapper {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2px;
	transition: $transition-base;
	opacity: 0;
	width: 100%;
	padding: 0 .25rem;
	margin-top: .5rem;

	.catalog-product-card-slider__pagination-wrapper_outer {
		bottom: -2.5rem;
	}

	.catalog-product-card-slider__pagination-button {
		&:before {
			background-color: transparent;
		}
	}
}

.catalog-product-card-slider__pagination {
	width: 100%;
	padding: 0 .25rem;
}

.catalog-product-card-slider__pagination-button {
	height: 3px;
	padding: .375rem 0;
	margin: 0;
	border: 0;
	cursor: pointer;
	width: 25%;
	position: relative;
	background-color: transparent;

	&:before {
		content: '';
		display: block;
		position: absolute;
		bottom: 4px;
		width: 100%;
		height: 3px;
		border-radius: $border-radius;
		transition: $transition-base;
		background: rgba(229, 229, 229, 0.6);
	}

	&.active {
		&:before {
			background-color: $color__gold !important;
		}
	}

	&:not(:last-child) {
		margin-right: .25rem;
	}

	&:hover {
		&:before {
			background-color: $color__gold !important;
		}
	}

	&:active {
		&:before {
			background-color: $color__gold_active !important;
		}
	}
}