.testimonial-card {
	margin-bottom: 40px;

	@include media-breakpoint-down(md) {
		margin-bottom: 32px;
	}

	&.hidden {
		display: none;
	}
}

.testimonial-card__review.hidden {
	display: none;
}

.testimonial-card__header {
	display: flex;
	flex-flow: row nowrap;
}

.testimonial-card__empty {
	.testimonial-card__empty-title {
		margin-top: 56px;
		color: $color__darklight;
		font-weight: 400;
		font-size: 20px;
		line-height: 127%;
	}

	.testimonial-card__empty-description {
		max-width: 678px;
		margin: 42px 0;
		color: $color__darklight;
		font-weight: 300;
		font-size: 20px;
		line-height: 130%;
	}
}

.testimonial-card__avatar {
	flex-shrink: 0;
	width: 78px;
	height: 78px;
	margin-right: 24px;
	object-fit: cover;
	border-radius: 50%;

	&.lazy:not(.loaded) {
		color: transparent;
		background-color: #e8e6f0;
		animation: blink 1.1s linear infinite;
	}
}

.testimonial-card__info {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;

	@include media-breakpoint-down(xxl) {
		flex-direction: column;
	}
}

.testimonial-card__author-info {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(xxl) {
		order: 1;
	}
}

.testimonial-card__rate {
	display: flex;
	align-items: center;
}

.testimonial-card__date {
	align-items: center;
	margin-right: 26px;
	color: $color__darklight;
	font-size: 12px;
	line-height: 130%;
}

.testimonial-card__name {
	margin-bottom: 8px;
	color: $color__darklight;
	font-weight: 400;
	font-size: 20px;
	line-height: 127%;
}

.testimonial-card__description {
	max-width: 651px;
	margin-left: 102px;
	color: $black;

	@include media-breakpoint-down(xxl) {
		margin: 24px 0;
	}
}

.testimonial-card__product-size {
	color: $color__darklight;
	font-size: 12px;
	line-height: 130%;
}

.testimonial-card__product-photos {
	display: flex;

	@include media-breakpoint-down(md) {
		margin-left: -8px;
	}
}

.testimonial-card__product-photo {
	width: 90px;
	height: 120px;
	object-fit: cover;

	&.lazy:not(.loaded) {
		color: transparent;
		background-color: #e8e6f0;
		animation: blink 1.1s linear infinite;
	}

	.testimonial-card__product-photo-button {
		padding: 0;
		background: none;
		border: 0;
		cursor: pointer;

		@include media-breakpoint-down(md) {
			flex-shrink: 0;
			padding-left: 8px;

			&:not(:last-child) {
				margin-right: 0;
			}
		}

		&:not(:last-child) {
			margin-right: 4px;
		}
	}
}

.testimonial-card__read-more {
	margin-top: 24px;
	cursor: pointer;
}
