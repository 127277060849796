/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
	padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

html {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	line-height: 1.5;
	scroll-behavior: smooth;
	font-family: 'Museo Sans Cyrl', Helvetica, Arial, sans-serif;
	text-rendering: optimizeSpeed;
	-webkit-font-smoothing: antialiased;
	max-width: 100vw;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
	list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration: none;
}

/* Make images easier to work with */
img {
	display: block;
	max-width: 100%;
}

video {
	display: block;
	max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

svg {
	fill: transparent;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	* {
		transition-duration: 0.01ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		scroll-behavior: auto !important;
	}
}

button {
	svg {
		pointer-events: none;
	}
}
