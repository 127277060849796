.category-card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding: 2.5rem 2.5rem 2.875rem;
	outline: 1px solid $white;
	box-shadow: 0 0 25px rgba(0, 30, 69, 0.1);
	border-radius: $border-radius-xs;
	transition: $transition-base;

	img {
		transition: $transition-base;
	}

	&:hover {
		outline: 1px solid rgb(212 166 124 / 80%);
		z-index: 10;
		box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .2) !important;

		.category-card__text {
			@include color($color__gold);
		}

		img {
			filter: saturate(1.03);
		}

		.category-card__back-icon {
			fill: $color__gold;
		}

		.category-card__image-wrapper_mask {
			background-color: $color__gold;
		}
	}

	&:active {
		outline: 1px solid rgba(223, 166, 45, 0.8) !important;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .06) !important;

		.category-card__text {
			@include color($color__gold_active !important);
		}

		.category-card__back-icon {
			fill: $color__gold_active;
		}
	}

	@include media-breakpoint-down(lg) {
		padding: 1.5rem;
	}

	&.category-card-back {
		padding-bottom: 2.5rem;
		box-shadow: 0 0 25px rgba(0, 30, 69, .03);

		.category-card__text {
			@include color($color__light);
		}

		&:hover {
			.category-card__text {
				@include color($color__gold);
			}
		}
	}
}

.category-card__image-wrapper {
	display: flex;
	align-items: center;
	flex-grow: 1;

	/* @include media-breakpoint-down(lg) {
		width: 94px;
		height: 78px;
	} */

	.category-card__back-icon {
		height: 160px;
		max-height: 100%;
		max-width: 100%;
		fill: $color__graylight;
		transition: $transition-base;
	}
}

.category-card__image-wrapper_mask {
	mask-image: var(--mask);
	mask-position: center;
	mask-repeat: no-repeat;
	transition: $transition-base;
	background-color: #ccc;
	width: 100%;
	height: 100%;
}

.category-card__image {
	height: 160px;
	object-fit: contain;
	border-radius: $border-radius;
	transition: $transition-base;

	@include media-breakpoint-down(md) {
		height: auto;
	}

	&.card__image_placeholder {
		max-height: 160px;
		opacity: .7;
	}
}

.category-card__text {
	@include display-text-big;
	line-height: 32px;
	margin-top: 2rem;
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	flex-grow: 1;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		@include display-text-small;
		margin-top: .75rem;
	}
}

.category-card__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
