.hero-slider {
	position: relative;

	@include media-breakpoint-down(md) {
		display: block;
	}
}

.hero-slider__viewport {
	position: relative;
	overflow: hidden;

	&::before {
		display: block;
		padding-bottom: 33%;
		content: '';

		@include media-breakpoint-down(lg) {
			padding-bottom: 34%;
		}

		@include media-breakpoint-down(sm) {
			padding-bottom: 46%;
		}
	}
}

.hero-slider__list {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;
	background-color: #e0e0e0;
}

.hero-slider__item {
	position: relative;
	min-width: 100%;
}

.hero-slider__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.hero-slider__image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;

	&.lazyload:not(.lazyloaded) {
		color: transparent;
		background-color: #e8e6f0;
		animation: blink 1.1s linear infinite;
	}
}

.hero-slider__arrow {
	display: block;
	width: 38px;
	height: 32px;
	margin: 0 auto;

	@include media-breakpoint-down(lg) {
		width: 25px;
	}
}

.hero-slider__arrow-button {
	@include color($white);
	position: absolute;
	top: 40%;
	left: 50px;
	width: 100px;
	height: 100px;
	background-color: rgba(136, 124, 112, 0.6);
	border: none;
	border-radius: 50%;
	cursor: pointer;
	transition: $transition-base;
	z-index: 1;

	@include media-breakpoint-down(lg) {
		top: 35%;
		width: 70px;
		height: 70px;
	}

	@include media-breakpoint-down(lg) {
		display: none;
	}

	&:hover {
		background-color: rgba(0, 0, 0, .2);
	}

	&:active {
		background-color: rgba(0, 0, 0, .4);
	}
}

.hero-slider__arrow-button_next {
	right: 50px;
	left: unset;
}

.hero-slider__pagination {
	position: absolute;
	display: flex;
	gap: 4px;
	margin-top: 30px;
	transform: translateX(-50%);
	left: 50% !important;
	bottom: -50px !important;

	@include media-breakpoint-down(lg) {
		bottom: -30px !important;
	}

	@include media-breakpoint-down(sm) {
		bottom: -20px !important;
	}

	&.swiper-pagination-horizontal {
		width: auto !important;
		max-width: auto !important;
	}

	.swiper-pagination-bullet {
		width: 20px;
		height: 20px;
		margin: 0 !important;
		padding: 0;
		background-color: $color__graylight;
		border: 0;
		cursor: pointer;
		transition: $transition-base;
		border-radius: 0;
		opacity: 1;

		@include media-breakpoint-down(lg) {
			width: 15px;
			height: 15px;
		}

		@include media-breakpoint-down(lg) {
			width: 10px;
			height: 10px;
		}

		@include media-breakpoint-down(sm) {
			width: 8px;
			height: 8px;
		}

		&.swiper-pagination-bullet-active {
			background-color: $color__light;
		}
	}
}