.hero-banner {
	position: relative;
	width: 100%;
	max-height: 564px;
	overflow: hidden;
	background-color: #f7f7f7;

	@include media-breakpoint-down(md) {
		&.mobile-hide {
			display: none;
		}
	}

	@include media-breakpoint-down(sm) {
		max-height: 360px;
	}

	@include media-breakpoint-down(xxl) {
		max-height: 560px;
	}
}

.hero-banner_with_container {
	max-height: 620px;

	@include media-breakpoint-down(lg) {
		max-height: 560px;
	}

	@include media-breakpoint-down(sm) {
		max-height: unset;

		.hero-banner__image {
			display: none;
		}
	}

	@include media-breakpoint-down(sm) {
		max-height: unset;
	}
}

.hero-banner__image {
	width: 100%;
	height: 620px;
	object-fit: cover;
	object-position: bottom;

	@include media-breakpoint-down(xxl) {
		height: 560px;
		object-position: center;
	}

	@include media-breakpoint-down(lg) {
		height: 480px;
	}
}

.hero-banner__image_position_center {
	object-position: center;
}