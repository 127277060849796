.city-notifier {
	position: absolute;
	top: 52px;
	left: 0;
	z-index: 100;
	display: none;
	min-width: 470px;
	padding: 48px 40px 28px;
	background-color: $color__darklight;
	border-radius: $border-radius-lg;
}

.city-notifier_initialized {
	display: block;
	animation: fadeInNotifier 1s linear forwards;
}

.city-notifier__text {
	margin-bottom: 32px;
	color: $white;
	font-weight: 400;
	font-size: 25px;
	line-height: 30px;

	span {
		color: $color__gold;
	}
}

.city-notifier__button {
	margin-right: 16px;
	text-transform: uppercase;

	.city-notifier__button_transparent {
		border-color: $color__gold;
	}
}

.city-notifier__close-button {
	position: absolute;
	top: 16px;
	right: 16px;
	width: 23px;
	height: 23px;
	margin: 0;
	padding: 0;
	background: none;
	border: 0;
	cursor: pointer;

	&:before, &:after {
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 100%;
		height: 3px;
		background-color: $white;
		cursor: pointer;
		content: "";
	}

	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

@keyframes fadeInNotifier {
	0%, 70% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
