.location {

}

.location__button {
	@include color($color__dark, true);
	@include text-small;
	font-weight: 400;
	display: flex;
	align-items: center;
	padding: 0;
	border: 0;
	cursor: pointer;
	background: transparent;
	white-space: nowrap;
	transition: $transition-base;

	@include media-breakpoint-down(md) {
		max-width: 100px;

		span {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.location__icon {
	@include color($color__dark, true);
	width: .75rem;
	height: 1rem;
	margin-right: 1rem;
	transition: $transition-base;
}