.checkout {
	&.loading {
		cursor: wait;
		pointer-events: none;
	}
}

.checkout__container {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: .8fr .3fr .9fr;
	padding-top: 1rem;

	@include media-breakpoint-down(xl) {
		grid-template-columns: .8fr .2fr .9fr;
		grid-gap: 0;
	}

	@include media-breakpoint-down(lg) {
		grid-template-columns: 1fr;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 32px;
	}
}

.checkout__form {
	@include media-breakpoint-down(lg) {
		max-width: 560px;
	}
}

.checkout__form-title {
	@include color($color__darklight);
	margin-top: 4rem;
	font-weight: 300;
	font-size: 25px;
	line-height: 130%;
	text-transform: uppercase;
	transition: $transition-base;

	@include media-breakpoint-down(md) {
		font-size: 20px;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 2.75rem;
	}

	.pseudo {
		border-bottom: 1px dashed;
	}
}

.checkout__form-title_without-margin {
	margin-top: 0;

	@include media-breakpoint-down(sm) {
		margin-top: 0;
	}
}

.checkout__checkbox {
	margin-bottom: 20px;
}

.checkout__checkbox-wrapper {
	margin-top: 2rem;
}

.checkout__gift-fields {
	height: 0;
	opacity: 0;
	margin-top: 0;
	margin-bottom: 0;
	position: relative;
	z-index: -1;
	pointer-events: none;
	transition: $transition-base;

	&.active {
		margin-top: -1.25rem;
		margin-bottom: 2.25rem;
		height: auto;
		opacity: 1;
		z-index: 1;
		pointer-events: auto;
	}
}

.checkout__radios {
	margin-top: 1.5rem;

	.form__field-message {
		margin-top: 1rem;
		margin-left: 0;
	}

	&.error {
		.form__field-message {
			display: block;
		}
	}
}

.checkout__total {
	margin-top: 2rem;
	text-align: right;

	@include media-breakpoint-down(md) {
		text-align: center;
	}

	.contact-form__text, .contact-form__text a {
		@include text-smallest;
		line-height: 1.0625rem;
	}
}

.checkout__total-price {
	@include color($color__darklight);
	display: flex;
	justify-content: flex-end;
	margin-top: .875rem;
	font-size: 25px;
	align-items: center;

	@include media-breakpoint-down(md) {
		display: block;
	}

	> p {
		line-height: 1.2;
	}

	[data-total] {
		white-space: nowrap;
	}
}

.checkout__shipping {
	@include color($black);
	font-weight: 300;
	font-size: 20px;
	line-height: 130%;

	&.hidden {
		display: none;
	}

	& + .checkout__total-price {
		@include media-breakpoint-down(md) {
			margin-top: .25rem;
		}
	}
}

.checkout__button {
	margin-left: 2rem;
	min-width: 16rem;

	@include media-breakpoint-down(md) {
		width: 100%;
		margin-top: 16px;
		margin-left: 0;
	}
}

.checkout__location {

}

.checkout__location-field {
	position: relative;
	z-index: 10;

	.bx-ui-sls-pane {
		top: calc(100% + 6px);
	}
}

.checkout__location-suggest {
	position: absolute;
	top: var(--top, 100%);
	left: 0;
	z-index: 1;
	width: 100%;
	background-color: $white;
	border: 1px solid $color__gold;
	border-top: 0;

	&.hidden, &:empty {
		display: none;
	}
}

.checkout__suggest-button {
	@include display-text-small;
	@include color($color__darklight);
	display: block;
	width: 100%;
	padding: 1rem 2.5rem;
	font-weight: 500;
	text-align: left;
	background-color: $white;
	border: 0;
	cursor: pointer;
	transition: $transition-base;

	@include media-breakpoint-down(sm) {
		padding: 1rem;
	}

	&:hover {
		background-color: $color__light;
	}
}

.checkout__list {
	position: relative;
	transition: $transition-base;

	&.loading {
		&::before, &::after {
			position: absolute;
			z-index: 2;
			content: '';
		}

		&::before {
			top: -15px;
			left: -15px;
			width: calc(100% + 30px);
			height: calc(100% + 30px);
			backdrop-filter: blur(1px);
		}

		&::after {
			left: 10rem;
			width: 2rem;
			height: 2rem;
			border-width: 3px 0;
		}
	}
}

.checkout__products {
	position: sticky;
	top: calc(150px + 1rem);
	transition: all $transition-default-time-bigger $transition-default-effect;

	@include media-breakpoint-down(lg) {
		margin-top: 4rem;
		border-top: 1px solid hsla(0, 0%, 90%, .6);
	}

	.cart-product__price {
		.catalog-product-card__price_old {
			@include  media-breakpoint-down(md) {
				margin-top: 0;
			}
		}
	}
}
