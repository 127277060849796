.product-attribute {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 3fr;

	@include media-breakpoint-between(lg, xl) {
		grid-column-gap: 1rem;
		grid-template-columns: 2fr 3fr;
	}

	@include media-breakpoint-down(sm) {
		grid-column-gap: .75rem;
		grid-template-columns: 2fr 3fr;
	}
}
