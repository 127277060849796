.company-section {
	position: relative;
	margin: 140px auto 0;
	background-color: rgba(229, 229, 229, 0.3);

	@include media-breakpoint-down(xxl) {
		margin: 80px auto 0;
		background-color: transparent;
	}

	@include media-breakpoint-down(md) {
		margin: 64px auto 0;
	}
}
