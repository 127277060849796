.select {
	position: relative;
	width: 100%;

	.select_color_white {
		.select {
			.select__button {
				@include color($white);
				border-bottom: 1px solid $white;

				@include media-breakpoint-down(lg) {
					color: $color__darklight;
					border-bottom: 1px solid $color__darklight;
				}
			}

			.select__option {
				color: $white;

				@include media-breakpoint-down(lg) {
					color: $color__darklight;
				}

				.select__option-list {
					background-color: $color__darklight;
					box-shadow: none;

					@include media-breakpoint-down(lg) {
						background-color: #f7f7f7;
						box-shadow: 0 20px 20px rgb(151 153 164 / 50%);
					}
				}
			}
		}
	}
}

.select__button {
	@include display-text-small;
	@include color($color__graydark);
	position: relative;
	width: 100%;
	padding: 1.75rem 2.5rem 1.375rem;
	text-align: left;
	border: 0;
	background-color: #f7f7f7;
	border-left: 1px solid #f7f7f7;
	border-bottom: 1px solid $color__darklight;
	cursor: pointer;

	z-index: 3;

	margin-left: -1px;

	@include media-breakpoint-down(sm) {
		padding: 16px 40px 16px 16px;
	}

	&::after {
		position: absolute;
		top: 40%;
		right: 20px;
		width: 20px;
		height: 12px;
		background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 14'%3E%3Cpath d='M21 1 11 12 1 1' stroke='%23D4A67C' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		transition: $transition-base;
		content: "";

		@include media-breakpoint-down(sm) {
			right: 10px;
		}
	}

	&.active {
		background-color: $white;
		border-left-color: $white;

		&::after {
			transform: rotate(180deg);
		}
	}

	&.highlighted {
		color: $color__gold;
		border-color: $color__gold;
		transition: $transition-base;
	}
}

.select__option-list {
	background-color: #f7f7f7;
	box-shadow: 0 20px 20px rgb(151 153 164 / 50%);
	display: block;
	opacity: 0;
	pointer-events: none;
	overflow: auto;
	padding: 0;
	position: absolute;
	width: 100%;
	z-index: 1;
	transition: $transition-base;
	top: 0;

	@include media-breakpoint-down(sm) {
		padding: 16px;
	}

	&.active {
		opacity: 1;
		pointer-events: all;
		padding: 1rem 0;
		top: 69px;
		z-index: 2;
	}
}

.select__option {
	@include display-text-small;
	@include color($black);
	position: relative;
	cursor: pointer;
	text-align: left;
	padding: .875rem 0 1rem 2.625rem;
	margin: 0;
	transition: $transition-base;
}
