@import '../catalog-product-card-slider/catalog-product-card-slider.scss';

.catalog-product-card {
	position: relative;
	transition: $transition-base;
	border-radius: $border-radius;
	background: $white;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08), 0 1px 2px 0 rgba(0, 0, 0, .02);
	padding: 1.375rem 1.375rem 1.125rem;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	&:hover {
		box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .2);

		.catalog-product-card__title {
			@include color($black !important);
		}

		img {
			filter: saturate(1.07);
		}

		.catalog-product-card__price {
			background-color: $color__gold_hover !important;

			&.discount {
				background-color: $color__darklight !important;
			}
		}

		.catalog-product-card__price_old {
			@include color($color__darklight !important);

			/*&:after {
				border-color: $color__gold_hover !important;
			}*/
		}

		.catalog-product-card__buttons {
			opacity: 1;
			height: auto;
			pointer-events: auto;
			margin-top: 1rem;
		}

		.catalog-product-card__gallery_swap {
			.product-card__image {
				&.lazyloaded, &:not(.lazyload) {
					opacity: 1;
				}
			}
		}

		.catalog-product-card__title {
			@include color($color__dark);
		}
	}

	&:active {
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08);

		.catalog-product-card__price {
			background-color: $color__gold_active !important;

			&.discount {
				background-color: $black !important;
			}
		}
	}

	img {
		transition: $transition-base;
	}

	.card__like-button {
		position: absolute;
		top: .375rem;
		right: .5rem;
	}

	.catalog-product-card__offers_wrapp {
		display: flex;
		/* height: 2rem; */
		column-gap: 8%;
		position: relative;
		width: calc(100% + 1rem);
		max-width: calc(100% + 1rem);

		.scrollyeah__shaft {
			display: flex !important;
			column-gap: 8%;
		}

		@include media-breakpoint-down(lg) {
			column-gap: .75rem;

			.scrollyeah__shaft {
				column-gap: .75rem;
			}
		}

		@include media-breakpoint-down(md) {
			column-gap: .625rem;

			.scrollyeah__shaft {
				column-gap: .625rem;
			}
		}

		@include media-breakpoint-down(sm) {
			column-gap: .5rem;

			.scrollyeah__shaft {
				column-gap: .5rem;
			}
		}
	}
}

.catalog_product_card_not_available {
	.catalog-product-card__description {
		opacity: .6;
	}

	.detailed-catalog-product-card__description {
		.catalog-product-card__title {
			opacity: .6;
		}

		.detailed-catalog-product-card__offers {
			opacity: .6;
		}

		.detailed-catalog-product-card__list {
			opacity: .6;
		}
	}
}

.catalog-product-card__buttons {
	opacity: 0;
	height: 0;
	pointer-events: none;
	z-index: 1;
	position: relative;
	transition: $transition-base;

	.button {
		width: 100%;
		padding: 10px 16px;
		border-radius: .125rem;
	}
}

.catalog-product-card_badges {
	display: flex;
	position: absolute;
	top: 1rem;
	left: 1.375rem;
	z-index: 9;
	border-radius: $border-radius-xs;

	.badge {
		text-transform: uppercase;
		padding: .625rem .75rem;
	}

	.badge__text {
		position: relative;
		top: -1px;
	}
}

.catalog-product-card__badge {
	font-weight: 400;
	letter-spacing: .03555em;
	text-transform: uppercase;
}

.catalog-product-card__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.catalog-product-card__gallery_slides {
	position: relative;
	width: 100%;
	max-width: 230px;
	margin: 0 auto;
	z-index: 8;

	@include media-breakpoint-down(lg) {
		max-width: 180px;
	}

	&::before {
		display: block;
		padding-bottom: 100%;
		content: '';
	}
}

.catalog-product-card__gallery-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.catalog-product-card__description {
	padding: 0;
	text-align: left;
	display: flex;
	justify-content: space-between;
	margin-top: 1.25rem;
}

.catalog-product-card__info {
	position: relative;
}

.catalog-product-card__title {
	@include fs-lh(16px, 20px);
	@include color($color__darklight);
	font-weight: 400;
	margin-top: .75rem;
	display: inline-block;
	text-decoration: none;

	@include media-breakpoint-down(lg) {
		@include display-text-normal;
		/*overflow: hidden;
		max-height: 66px;*/
	}
}

.catalog-product-card__offers {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-top: 0;
}

.catalog-product-card__price {
	@include color($white);
	background-color: $color__gold;
	font-size: 120%;
	font-weight: 400;
	white-space: nowrap;
	width: max-content;
	/*height: 2rem;
	line-height: 2rem;*/
	padding: 0 1.25rem 0 1.375rem;
	margin-left: -1.375rem;
	/* border-radius: 0 $border-radius-xs $border-radius-xs 0; */
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: subpixel-antialiased;
	clip-path: polygon(100% 0, 98% 52%, 100% 100%, 0 100%, 0 0);
	position: relative;
	z-index: 2;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		@include fs-lh(1rem, 1.625rem);
	}

	@include media-breakpoint-down(md) {
		@include fs(1rem);
	}

	@include media-breakpoint-down(sm) {
		@include fs-lh(.9375rem, 1.75rem);
	}

	&.discount {
		background-color: #444;
	}
}

.catalog-product-card__price_old {
	@include color($color__graydark);
	font-size: 90%;
	font-weight: 500;
	align-items: center;
	display: flex;
	position: relative;
	margin-bottom: .25rem;
	margin-top: .25rem;
	z-index: 8;
	transition: $transition-base;

	.catalog-product-card__price_old__value {
		position: absolute;
		white-space: nowrap;
		text-shadow: 0 0 10px rgba(255, 255, 255, 1);
		border-radius: .25rem;

		&:after {
			content: '';
			display: block;
			width: calc(100% + .5rem);
			height: 60%;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: -.25rem;
			border-bottom: 2px solid $color__gold;
			transform: rotate(-12deg);
			transition: $transition-base;
		}
	}

	@include media-breakpoint-up(md) {
		white-space: nowrap;
	}

	@include media-breakpoint-down(md) {
		@include fs(.875rem);
	}

	@include media-breakpoint-down(sm) {
		@include fs(80%);
	}
}

.catalog-product-card__price_discount {
	font-weight: 300;
	margin-left: 1.5rem;
	line-height: 19px;
	text-decoration-line: line-through;

	@include media-breakpoint-down(lg) {
		line-height: 130%;
		margin-left: .875rem;
	}
}