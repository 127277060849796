.selectpicker {
	display: none;
}

.dropdown {
	position: relative;
	z-index: 2;

	&.show {
		z-index: 3;

		.dropdown-toggle {
			padding-left: 1rem;
			box-shadow: none;

			&:before {
				opacity: 0;
				transition: none;
			}

			&:after {
				opacity: 0;
				transition: none;
				top: 100%;
			}
		}
	}
}

[data-type=nice-select] {
	display: none;
}

.nice-select {
	font-size: 1rem;
	font-weight: 500;
	border: none;
	cursor: pointer;
	position: relative;
	transition: $transition-base;
	z-index: 10;
	outline: none !important;

	&.nice-select__button {
		.current {
			@include color($white);
			background: $color__gold;
			border-radius: $border-radius-xs;
			padding: .75rem 2.375rem .75rem 1.125rem;
			border: 0;

			&:hover {
				background: $color__gold_hover;
			}

			&:after {
				content: '';
				background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 22 14'%3E%3Cpath stroke='%23FFFFFF' stroke-miterlimit='10' stroke-width='2' d='M21 1 11 12 1 1'/%3E%3C/svg%3E") center no-repeat;
				display: block;
				height: .625rem;
				width: .625rem;
				position: absolute;
				right: 1.125rem;
				top: calc(50% - .25rem);
				transition: $transition-base;
				opacity: 1;
			}

			&:before {
				content: '';
				display: block;
				height: .75rem;
				width: .75rem;
				position: absolute;
				right: -1.125rem;
				top: calc(50% - .25rem);
				transition: $transition-base;
				opacity: 0;
			}

			&:active {
				background: $color__gold_active;
			}
		}

		&.open {
			.nice-select-dropdown {
				box-shadow: 0 2px 2px 0 rgba(25, 25, 25, .078), 0 0 4px 0 rgba(25, 25, 25, .161);
			}

			.current {
				background: $color__gold;

				&:after {
					opacity: 0 !important;
				}

				&:before {
					opacity: 1 !important;
				}
			}
		}

		.nice-select-dropdown {
			.list {
				li {
					padding: .75rem 1.125rem .75rem 1.125rem;
				}
			}
		}
	}

	.current {
		@include color(#333);
		width: 100%;
		background: transparent;
		line-height: 1.375rem;
		display: inline-block;
		padding: 1rem 1.375rem 1rem 1.25rem !important;
		position: relative;
		z-index: 11;
		transition: $transition-base;

		&::after {
			position: absolute;
			top: 40%;
			right: 20px;
			width: 20px;
			height: 12px;
			background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 14'%3E%3Cpath d='M21 1 11 12 1 1' stroke='%23D4A67C' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			transition: $transition-base;
			content: '';

			@include media-breakpoint-down(sm) {
				right: 10px;
			}
		}

		&:hover, &:focus {
			@include color($color__gold);
		}

		&:active {
			background: hsla(0, 0%, 90%, .3);
		}
	}

	.nice-select-dropdown {
		.list {
			li {
				padding: .875rem 1.375rem .875rem 1.25rem !important;
			}
		}
	}

	.option {
		transition: $transition-base;

		&.selected, &.active {
			background: #EFEAE6;

			.payment__retry__payment_option_price {
				opacity: 1 !important;
			}
		}

		&:hover {
			background: #F7F7F7;
		}
	}

	&.open {
		.nice-select-dropdown {
			pointer-events: all;
			opacity: 1;
			top: 100%;
			background-color: $white;
			border-radius: $border-radius-xs;
			box-shadow: 0 2px 2px 0 rgba(25, 25, 25, .078), 0 0 4px 0 rgba(25, 25, 25, .161);

			.list {
				padding: .625rem 0;
			}
		}

		.current {
			border-radius: $border-radius-xs $border-radius-xs 0 0;
			background: hsla(0, 0%, 90%, .3);

			&:after {
				transform: rotate(-180deg);
			}
		}
	}

	&.disabled {
		pointer-events: none;

		.current {
			&:after {
				display: none;
			}
		}
	}
}

.nice-select-dropdown {
	background-color: transparent;
	pointer-events: none;
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	opacity: 0;
	overflow: hidden;
	transition: $transition-base;

	.list {
		min-width: 190px;

		li {
			@include color($color__graydark);
			font-size: 1rem;
			font-weight: 300;
			line-height: 1.375rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			transition: $transition-base;

			&:hover {
				@include color($color__dark);
				background-color: hsla(0, 0%, 90%, .33);
			}

			&:active {
				background-color: hsla(0, 0%, 90%, .9);

			}
		}
	}
}

.autoComplete_wrapper {
	width: 100%;
}

.irs {
	display: block;
	position: relative;
	margin-top: 2rem;
	margin-bottom: .75rem;
}

.irs-bar, .irs-line {
	border-radius: $border-radius;
	display: block;
	height: .1875rem;
	outline: none;
}

.irs-line {
	background: #f7f7f7;
}

.irs-from, .irs-max, .irs-min, .irs-single, .irs-to {
	display: none;
}

.irs-bar {
	background: $color__gold;
	position: absolute;
	top: 0;
}

.irs-handle {
	background: $color__gold;
	border-radius: 50%;
	cursor: pointer;
	height: 1rem;
	margin-top: .1875rem;
	position: absolute;
	top: -.6rem;
	transition: $transition-base;
	width: 1rem;

	&:hover {
		box-shadow: $box-shadow-lg, $box-shadow, 1px 1px 1px -1px rgba(0, 0, 0, .3);
	}

	&:active {
		background: $color__gold-hover;
		box-shadow: $box-shadow-sm, $box-shadow, 1px 1px 1px -1px rgba(0, 0, 0, .3);
		transition: none;
	}
}

.lending-signup {
	position: relative;
	z-index: 1;

	a {
		width: 100%;
		max-width: 359px;
	}
}

.catalog__products_count {
	@include text-small;
	@include color($color__gray);
	font-weight: 300;

	@include media-breakpoint-down(md) {
		@include text-smallest;
	}

	&.catalog__products_count__block {
		display: block;
		margin-top: 1.25rem;
		margin-bottom: -2.125rem;
	}
}

.body_spinner {
	background-color: rgba(82, 82, 82, .2);
	/*background-color: rgba(51, 51, 51, .3);*/
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
	opacity: 0;
	transition: $transition-base;

	.logo__icon {
		@include color(#f6f6f6);
		height: 6rem;
		left: 50%;
		margin: -3rem 0 0 -3rem;
		position: absolute;
		top: 50%;
		width: 6rem;
		z-index: 2;
		opacity: .8;
	}

	.spinner {
		animation: spin 1.33333s linear infinite, spin_bg__xl 1s ease infinite;
	}

	&.active {
		opacity: 1;
	}

	.modal-backdrop {
		display: none;
	}

	&.news__spinner {
		position: absolute;
		background-color: transparent;

		.logo__icon, .spinner {
			top: 120px;
		}
	}
}

.spinner {
	animation: spin 1.33333s linear infinite, spin_bg__sm 1s ease infinite;
	border-bottom: 1rem solid #e7e7e7;
	border-radius: 50%;
	border-top: 1rem solid #e7e7e7;
	height: 9rem;
	left: 50%;
	margin: -4.5rem 0 0 -4.5rem;
	position: absolute;
	top: 50%;
	width: 9rem;
	z-index: 1;
	backdrop-filter: blur(4px);
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin_bg__block_md {
	0% {
		background: rgba(0, 139, 209, .03);
		box-shadow: inset 0 0 0.75rem rgba(255, 255, 255, .5), 0 0 0.5rem rgba(0, 0, 0, .01);
		opacity: 1;
	}
	100% {
		background: transparent;
		box-shadow: inset 0 0 .125rem rgba(0, 0, 0, 0), 0 0 .125rem rgba(0, 0, 0, 0);
		opacity: 0;
	}
}

@keyframes spin_bg__sm {
	0% {
		background: rgba(0, 0, 0, .1);
		box-shadow: inset 0 0 .75rem rgba(255, 255, 255, .5);
	}
	100% {
		background: rgba(0, 0, 0, 0);
		box-shadow: inset 0 0 .125rem rgba(255, 255, 255, 0);
	}
}

@keyframes spin_bg__xl {
	0% {
		/*box-shadow: 0 0 3rem rgba(255, 255, 255, .1), inset 0 0 3rem hsla(0, 0%, 100%, .75), 0 0 3rem rgba(51, 51, 51, .05);*/
		box-shadow: 0 0 3rem rgba(255, 255, 255, .1), inset 0 0 3rem hsla(0, 0%, 100%, .75);
		background: rgba(51, 51, 51, .1);
	}
	100% {
		background: transparent;
		box-shadow: 0 0 1rem transparent, inset 0 0 1rem hsla(0, 0%, 100%, 0);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.bx-session-message {
	display: none !important;
}

img {
	#alttext-container {
		display: none;
	}
}

.with-fancybox {
	.modal-backdrop {
		opacity: 0;
	}

	.fancybox__backdrop {
		backdrop-filter: blur(4px);
	}
}

.fancybox__container {
	z-index: 2050;
}

.fancybox__slide {
	&.is-draggable {
		.fancybox__content {
			> img {
				opacity: 1;
			}
		}
	}

	&.can-zoom_in {
		.fancybox__content {
			background: none;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			border-radius: $border-radius-lg;
			background-size: var(--bg-w) var(--bg-h);

			&:hover {
				background-image: var(--bg);

				/* > img {
					opacity: 0;
				} */
			}
		}
	}
}

.fancybox__content {

}

.fancybox__image {
	border-radius: $border-radius-lg;

	&:hover {
		transition: $transition-base;
	}
}

.fancybox__thumbs {
	.carousel__slide {
		.fancybox__thumb:after {
			border: 2px solid $color__gold !important;
		}
	}
}

.fancybox__counter {
	display: none;
}

.fancybox__button--slideshow, .fancybox__button--zoom, .fancybox__button--fullscreen, .fancybox__button--thumbs {
	display: none;
}

.fancybox__button--close {
	width: 30px;
	height: 30px;
}

.ya-share2__badge_more {
	display: none !important;
}

[data-parallax=true] {
	transition: all $transition-default-time-smaller $transition-default-effect;
}

.scrollyeah_shadow {
	.scrollyeah__shadow_next {
		right: -3px;
		background-image: -webkit-linear-gradient(transparent, rgba(0, 0, 0, .3) 25%, rgba(0, 0, 0, .3) 75%, transparent), -webkit-radial-gradient(100% 50%, farthest-side, rgba(0, 0, 0, .5), transparent);
		background-image: linear-gradient(transparent, rgba(0, 0, 0, .3) 25%, rgba(0, 0, 0, .3) 75%, transparent), radial-gradient(farthest-side, rgba(0, 0, 0, .4), transparent);

		&:before {
			right: 3px;
			background: $color__graylight;
		}
	}

	.scrollyeah__shadow_prev {
		left: -3px;
		background-image: -webkit-linear-gradient(transparent, rgba(0, 0, 0, .3) 25%, rgba(0, 0, 0, .3) 75%, transparent), -webkit-radial-gradient(0 50%, farthest-side, rgba(0, 0, 0, .5), transparent);
		background-image: linear-gradient(transparent, rgba(0, 0, 0, .3) 25%, rgba(0, 0, 0, .3) 75%, transparent), radial-gradient(farthest-side, rgba(0, 0, 0, .4), transparent);

		&:before {
			left: 3px;
			background: $color__graylight;
		}
	}
}

.scrollyeah_shadow_no-left {
	.scrollyeah__shadow_prev {
		left: -10px;
	}
}

.scrollyeah_shadow_no-right {
	.scrollyeah__shadow_next {
		right: -10px
	}
}

.scrollyeah__shadow_next, .scrollyeah__shadow_prev {
	&:before {
		content: '';
		display: block;
		width: 1px;
		height: 100%;
		position: absolute;
		background: transparent;
	}
}

@keyframes show-cart-additional {
	0% {
		top: -5rem;
		background-color: rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		pointer-events: none;
		z-index: -1;
		opacity: 0;
	}
	80% {
		z-index: -1;
	}
	100% {
		background-color: #fff;
		/*box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .08);*/
		box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .08), 0 1px 2px 0 rgba(0, 0, 0, .02);
		opacity: 1;
		pointer-events: all;
		top: 0;
		z-index: 10;
	}
}

.bx-sls {
	.dropdown-block {
		border: 0;
		border-bottom: 1px solid #ccc;
		box-shadow: none;
		font-size: 1rem !important;
		line-height: 1.375rem;
		outline: none;
		padding: 1.875rem 1.375rem 1rem 1.25rem !important;
		border-radius: 0;
		transition: $transition-base;

		&:hover {
			border-bottom-color: $color__gold;
		}

		@include media-breakpoint-down(sm) {
			padding: 1rem !important;
		}
	}

	.bx-ui-sls-clear {
		margin-top: 36px;

		@include media-breakpoint-down(sm) {
			margin-top: 20px;
		}
	}

	.bx-ui-sls-pane {
		border: 1px solid #ccc;
		border-radius: $border-radius-sm;
		box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, .16);

		&::-webkit-scrollbar {
			width: .5rem;
		}

		&::-webkit-scrollbar-track {
			background: none;
			height: 100%;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #E3E2DE;
			border-radius: 10px;
			border: none;
		}
	}

	.dropdown-item {
		border-radius: $border-radius-sm;
	}

	.bx-ui-sls-variant {
		transition: $transition-base;
	}

	.bx-ui-sls-variants .bx-ui-sls-variant:hover, .bx-ui-sls-variant-active {
		background-color: #f7f7f7;
	}

	.bx-ui-sls-variants .bx-ui-sls-variant, .bx-ui-sls-error {
		padding: 12px 32px 12px 32px;
	}

	.bx-ui-sls-fake, .bx-ui-sls-route {
		color: #333 !important;
		font-size: 1rem;
		font-family: Museo Sans Cyrl, Helvetica, Arial, sans-serif;
		font-weight: 300;
	}

	.dropdown-fade2white {
		top: inherit;
		bottom: 6px;
		right: 10px;
		z-index: 5;
	}

	.bx-ui-sls-clear {
		display: block;
		z-index: 6;
		top: -2px;
	}
}

.nav {
	display: flex;
	flex-wrap: wrap;
}

.nav-link {
	@include color(#333);
	font-size: 1.125rem;
	line-height: 1.375rem;
	margin: 0 .5rem .5em 0;
	padding: .5rem 1.5rem;
	border-radius: $border-radius;
	background-color: #f7f7f7;
	text-decoration: none;
	transition: $transition-base;

	& > span {
		@include color($color__gray);
		font-size: .875rem;
		font-weight: 300 !important;
		transition: $transition-base;
	}

	&.active {
		@include color($white !important);
		background-color: $color__gold !important;
		cursor: default;

		& > span {
			@include color($white !important);
		}
	}

	&:hover {
		@include color($color__dark);
		background-color: hsla(0, 0%, 90%, .4);

		& > span {
			@include color(#999999);
		}
	}

	&:active {
		background-color: hsla(0, 0%, 90%, .6);
		box-shadow: none;
	}
}

.search_nav {
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 1.375rem;
	padding-bottom: .625rem;
}

.account__menu-button {
	display: none;

	.burger {
		z-index: inherit;
	}

	@include media-breakpoint-down(lg) {
		display: flex;
	}
}

.swiper {
	&:not(.swiper-initialized) {
		.catalog__products_slider__nav__button {
			opacity: 0;
		}
	}
}

.scrollyeah__wrap {
	width: 100% !important;
}

.scrollyeah__shaft {
	display: flex !important;
}

.modal__auth {
	.form__input {
		padding-top: 1rem !important;
		padding-left: 1.25rem !important;
	}

	.pseudo {
		@include color($color__gray);

		&:hover {
			@include color($color__gold);
		}
	}
}

.modal {
	.auth__background-image {
		top: -144px;
		color: #333;
		left: -194px;
		height: 600px;
		width: 385px;
		pointer-events: none;
		position: absolute;
	}

	&.cart_modal {
		[data-role=title-phone] {
			display: none;
		}

		[data-role=title-auth] {
			display: block !important;
		}

		.skip_login {
			display: block;
		}
	}

	.skip_login {
		display: none;
	}
}

.pseudo {
	transition: $transition-base;
	cursor: pointer;

	&.color-gray {
		@include color($color__gray !important);
	}

	&.color-muted {
		@include color($color__muted !important);
	}

	&.color-gold {
		@include color($color__gold !important);
	}

	&:hover {
		&.color-gray {
			@include color($color__gold !important);
		}

		&.color-muted {
			@include color($color__gold !important);
		}

		&.color-gold {
			@include color($color__gray !important);

			.color-gray {
				@include color($color__gold !important);
				transition: $transition-base;
			}
		}
	}
}

.form__field__code_wrapp {
	.form__item__code {
		display: flex;
		column-gap: 1rem;

		.form__input {
			padding: 1rem .5rem .75rem .5rem !important;
			text-align: center;
		}

		label.error {
			display: none !important;
		}
	}
}

.form__field_SUM {
	.option[data-value="Сумма"] {
		display: none;
	}
}

.form__field {
	&.active {
		.form__label {
			pointer-events: all;
		}
	}

	.form__label {
		pointer-events: none;
	}

	&.nice-select {
		position: relative;
		z-index: 1;
	}
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type=number] {
	-moz-appearance: textfield;
}

[data-role=resend-sms-timer-wrapp] {
	[data-role=resend-sms] {
		@include color($color__muted);
	}

	[data-role=timer-countdown] {
		display: none;
	}

	&.disabled-timer {
		pointer-events: none;

		[data-role=resend-sms] {
			@include color($color__gray);
		}

		[data-role=resend-text] {
			display: none;
		}

		[data-role=timer-countdown] {
			display: inline;
		}
	}
}

.modal__bg {
	@include wh(224px);
	@include color($color__light);
	position: absolute;
	right: -35px;
	bottom: 42px;
	z-index: 1;
	transform: rotateY(180deg);

	&.modal__bg_checked {
		@include wh(200px);
		right: 0;
		top: 50%;
		bottom: auto;
		margin: -100px 0 0;
		transform: rotateY(0);
	}

	&.modal__bg_pos_left {
		@include wh(160px);
		right: auto;
		bottom: auto;
		left: -90px;
		top: 42px;
		transform: rotateY(0);
		z-index: 0;

		&.modal__bg_auth {
			height: 180px;
			left: -50px;
			top: 30px;

			&.modal__bg_auth_success {
				height: 160px;
				left: -60px;
				top: 10px;
			}
		}
	}
}

/* .icon_online {
	background-color: $color__gold_active;
	border-radius: 100%;
	bottom: 5px;
	right: 14px;
	height: 3px;
	position: absolute;
	width: 3px;
} */

.tooltip {
	transition: opacity $transition-default-time $transition-default-effect, box-shadow $transition-default-time $transition-default-effect, margin $transition-default-time $transition-default-effect;
	opacity: 0;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	margin-bottom: -1rem !important;
	z-index: 0 !important;

	&.show {
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .08), 0 1px 2px 0 rgba(0, 0, 0, .02);
		margin-bottom: 3px !important;
		opacity: 1;
		z-index: 1 !important;
	}
}

.carousel__button {
	svg {
		transition: $transition-base;
	}

	&:hover {
		svg {
			color: $color__gold_hover;
		}
	}

	&:active {
		svg {
			color: $color__gold_active;
		}
	}
}

.payment__retry_wrapper {
	top: -2.75rem;
	background: transparent;
	font-size: 1rem;
	line-height: 1.25rem;
	color: $white;
	left: 0;
	right: 0;
	z-index: 10;
	opacity: 0;
	transition: all $transition-default-time-bigger linear;
	width: 100%;
	max-width: 100%;

	&.loaded {
		opacity: 1;
		background: #6F5C55;

		.payment__retry__container_dark {
			.container_bg {
				&:before {
					opacity: 1;
					top: 0;
					left: 0;
					right: 0;
				}

				&:after {
					opacity: 1;
					animation: payment__retry__container_fade 3s ease-out infinite;
					left: 0;
					right: 0;
				}
			}
		}
	}
}

@keyframes payment__retry__container_fade {
	0%, 100% {
		opacity: .2;
	}
	50% {
		opacity: 1;
	}
}

.payment__retry__container_dark {
	height: 2.75rem;
	background: #6F5C55;

	.container_bg {
		display: flex;
		align-items: center;
		max-height: 100%;
		height: 44px;
		position: relative;
		overflow: hidden;

		&:before {
			position: absolute;
			display: block;
			content: '';
			margin: auto;
			width: 566px;
			height: 45px;
			opacity: 0;
			left: 0;
			background-image: url("data:image/svg+xml,%3Csvg width='566' height='45' viewBox='0 0 566 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_13444_28547)'%3E%3Cellipse cx='229.77' cy='22' rx='135.5' ry='40.5' fill='%236F5C55'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_13444_28547' x='-105.73' y='-218.5' width='671' height='481' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='100' result='effect1_foregroundBlur_13444_28547'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");;
			transition: all $transition-default-time-bigger linear;
			pointer-events: none;
		}

		&:after {
			position: absolute;
			display: block;
			content: '';
			top: 0;
			opacity: 0;
			margin: auto;
			width: 672px;
			height: 45px;
			background-image: url("data:image/svg+xml,%3Csvg width='672' height='45' viewBox='0 0 672 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.72' filter='url(%23filter0_f_13444_28545)'%3E%3Cellipse cx='336.101' cy='23.5366' rx='135.5' ry='97' fill='%23A89180'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_13444_28545' x='0.600586' y='-273.463' width='671' height='594' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='100' result='effect1_foregroundBlur_13444_28545'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
			transition: all $transition-default-time-bigger linear;
			pointer-events: none;
		}
	}

	.container_abs {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		align-items: center;
		display: flex;
		justify-content: center;
		width: 100%;
		box-sizing: border-box;
	}

	&:hover {
		.payment__retry__order_single__payment_link {
			span {
				text-shadow: 0 0 18px #fff, 0 0 1px rgba(0, 0, 0, .2);
			}
		}
	}
}

.payment__retry__order_single {
	max-height: 2.75rem;
	gap: .5rem;
	display: flex;
	justify-content: space-between;
	font-weight: 300;

	@include media-breakpoint-down(lg) {
		width: 100%;
	}

	@include media-breakpoint-down(md) {
		gap: 0 !important;
		width: 100%;
	}

	@include media-breakpoint-down(sm) {

	}

	.payment__retry__order_single__payment_mobile {
		display: none;
	}

	.nice-select {
		z-index: 9;
	}

	.nice-select-dropdown {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.nice-select__button {
		@include media-breakpoint-down(md) {
			overflow: hidden;
		}
	}
}

.payment__retry__order_single__pay_time {
	color: #E3DAD4;
	display: flex;
	align-items: center;

	[data-role=timer-time] {
		font-weight: 600;
		position: relative;
		z-index: 1;
		min-width: 42px;
		text-align: center;
	}

	.payment__retry__order_single__pay_time_icon {
		display: none;
		transition: $transition-base;
	}

	@include media-breakpoint-down(md) {
		.payment__retry__order_single__pay_time_title {
			display: none;
		}

		.payment__retry__order_single__pay_time_icon {
			display: flex;
			margin-right: .375rem;
		}
	}

	@include media-breakpoint-down(sm) {
		.payment__retry__order_single__pay_time_icon {
			display: none;
		}
	}
}

.payment__retry__order_single__payment_title {
	color: #E3DAD4;
	display: flex;
	align-items: center;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.payment__retry__order_single__payment_link {
	@include color($white);
	text-decoration: none;
	display: flex;
	align-items: center;
	min-width: 96px;
	transition: $transition-base;

	@include media-breakpoint-down(md) {
		justify-content: end;
	}

	span {
		transition: $transition-base;
		border-bottom: 1px solid !important;
	}

	&:hover {
		@include color($color__gold !important);

		span {
			text-shadow: 0 0 18px #fff, 0 0 1px rgba(0, 0, 0, .2);
		}
	}
}

.payment__retry__order_single__payment {
	display: flex;
	position: relative;
	z-index: 2;
	min-width: 220px;
	margin-left: 5.5rem;

	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 610px;
		height: 44px;
		background-image: url("data:image/svg+xml,%3Csvg width='610' height='44' viewBox='0 0 610 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_13444_21235)'%3E%3Cellipse cx='305' cy='59' rx='105' ry='62' fill='%236F5C55'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_13444_21235' x='0' y='-203' width='610' height='524' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='100' result='effect1_foregroundBlur_13444_21235'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
		pointer-events: none;
		max-width: 100%;
	}

	&:hover {
		.payment__retry__order_single__payment_link {
			span {
				text-shadow: none;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		justify-content: center;
		margin: 0 !important;
		min-width: inherit !important;
	}
}

.payment__retry__order_single__payment_mobile {
}

select.payment__retry__payment_select {
	height: 32px;
	background: rgba(255, 255, 255, .1);
	backdrop-filter: blur(6px);
	color: transparent;
	border: 0;
	border-radius: $border-radius;
	position: relative;
	width: 0;
	display: none;
}

.payment__retry__payment_select {
	height: 2.75rem;
	display: flex;
	align-items: center;
	background: none;
	color: $white;
	position: static;
	transition: $transition-base;

	&.open {
		.nice-select-dropdown {
			margin-top: -.1875rem;

			/* @include media-breakpoint-down(md) {
				display: none;
			} */
		}
	}

	&:hover {
		.current {
			&:after {
				background-color: $color__gold !important;
			}

			/*&:before {
				background-color: $color__gold !important;
			}*/
		}
	}

	&:active {
		.current {
			&:after {
				background-color: $color__gold_active !important;
			}

			/*&:before {
				background-color: $color__gold_active !important;
			}*/
		}
	}

	.nice-select-dropdown {
		margin-left: -1rem;
		max-width: min-content;

		@include media-breakpoint-down(md) {
			margin-left: 0;
			max-width: inherit;
		}

		.list {
			padding: .375rem 0 !important;

			li {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: .75rem;
				padding: .75rem 1rem .75rem 1rem;

				.extra {
					@include fs-lh(.875rem, 1.25rem);
					@include color(#8D91A0);
				}
			}
		}

		.option {
			font-size: 1rem;
			line-height: 1.25rem;
			letter-spacing: -0.005em;
			@include color(#191919);

			.payment__retry__payment__option {
				display: flex;
				align-items: center;
				transition: $transition-base;
			}

			.payment__retry__payment_option_price {
				opacity: 0;
				transition: $transition-base;
				font-size: .875rem;
				line-height: 1.25rem;
				@include color(#8D91A0);

				&.color-gold {
					@include color($color__gold);
				}
			}

			.payment__retry__payment__icon {
				@include color($color__gold);
				height: 1.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: $transition-base;
			}

			&:hover {
				.payment__retry__payment_option_price {
					opacity: 1;
				}

				.payment__retry__payment__icon {
					@include color($color__gold_hover);
				}
			}
		}
	}

	.current {
		line-height: 1.25rem;
		color: $white;
		font-weight: 300;
		margin-left: .625rem;
		border-bottom: 1px dashed !important;
		padding: 0 !important;
		background: none !important;
		margin-right: 1rem !important;
		border-bottom: 1px dashed;

		&:hover {
			&:after, &:before {
				@include color($color__gold !important);
				background-color: $color__gold !important;
			}
		}

		&:active {
			&:after, &:before {
				@include color($color__gold !important);
				background-color: $color__gold_active !important;
			}
		}

		&:after {
			background: none !important;
			background-color: $white !important;
			--mask: url('data:image/svg+xml,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.50102 5.29422L0.892578 0.433105L8.10946 0.433106L4.50102 5.29422Z" fill="white"/></svg>') !important;
			mask-image: var(--mask);
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-position: center;
			right: -1rem !important;
		}

		&:before {
			background: none !important;
			background-color: $white !important;
			--mask: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="currentColor"><path d="M6.58964 5.93066L11.4508 1.06948L10.8616 0.480225L6.00039 5.3414L1.14503 0.486042L0.555773 1.0753L5.41113 5.93066L0.411133 10.9307L1.00039 11.5199L6.00039 6.51991L10.999 11.5185L11.5882 10.9293L6.58964 5.93066Z" fill="currentColor"/></svg>') !important;
			mask-image: var(--mask);
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-position: center;
			right: -1rem !important;
			top: calc(50% - .3125rem) !important;
		}

		&.loading {
			opacity: .6;

			&:after {
				mask-image: none;
				width: 22px;
				height: 22px;
				margin-top: -5px;
				border-color: $white !important;
				background-color: transparent !important;
			}

			.payment__retry__payment__option {
				@include color($color__gold);
				border-bottom-color: $color__gold !important;
				opacity: .5;
			}
		}

		.payment__retry__payment_option_price {
			display: none;
		}

		.payment__retry__payment__icon {
			display: none;
		}
	}
}

.modal__paysystem_change {
	p {
		@include fs-lh(1.125rem, 1.5rem);
		font-weight: 400;
		margin-top: 1.75rem;

		&:first-child {
			margin-top: 0;
		}
	}

	.modal-buttons {
		display: flex;
		justify-content: end;
		gap: .75rem;

		@include media-breakpoint-down(sm) {
			justify-content: center;

			.button {
				padding-left: 1rem;
				padding-right: 1rem;
				width: 50%;
			}
		}
	}
}

.tags__wrapper {
	padding: .625rem 0;
	margin: -.125rem 0 -.625rem;
	background: $white;
	top: 0;
	width: 100%;
	transition: all $transition-default-time-bigger $transition-default-effect;
	position: sticky;
	z-index: 3;
	gap: .5rem;
	display: flex;
	flex-wrap: wrap;

	.container {
		& > .d-flex {
			display: flex;
			flex-wrap: wrap;
			gap: .5rem;

			@include media-breakpoint-down(sm) {
				gap: .25rem;
			}
		}
	}

	& + .news__wrapper, & + [data-role=tags-trigger] + .news__wrapper {
		margin-top: 2rem;
	}

	.scrollyeah__shaft {
		gap: .8rem;
	}
}

.tags__item {
	@include color($color__dark);
	background-color: #efefef;
	border: 1px solid #efefef;
	display: flex;
	align-items: center;
	font-size: 1rem;
	font-weight: 300;
	white-space: nowrap;
	height: 2rem;
	line-height: 1.25rem;
	padding: .25rem .625rem .25rem .625rem;
	text-decoration: none;
	border-radius: $border-radius-xs;
	transition: $transition-base;
	box-sizing: border-box;

	&.active, &:hover {
		@include color($color__gold);
		background-color: transparent;
		border-color: $color__gold;
	}

	&.active {
		.tags__item_hash {
			@include color($color__gold);
		}
	}

	&:after {
		display: none;
		position: absolute;
		width: 1rem;
		height: 1rem;
		top: calc(50% - .5rem);
		border-width: 1px 0;
		left: .5625rem;
	}

	&.loading {
		@include color($color__gray);
		pointer-events: none;
		border: 1px solid $color__gold;

		.tags__item_hash {
			opacity: 0;
		}
	}

	&:hover {
		.tags__item_hash {
			@include color($color__gold);
		}
	}

	&:active {
		@include color($color__darklight);
		background-color: hsla(0, 0%, 90%, .9);
	}

	&.no-hover {
		&:not(.active) {
			@include color($color__graydark !important);
			background-color: #efefef !important;
			border-color: #efefef !important;
		}
	}
}

.tags__item_clear {
	@include color($color__dark, true);
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: -0.005em;
	padding: .25rem .25rem .25rem .625rem;
	background: transparent;
	border-color: transparent;
	cursor: pointer;
	border: 0;

	svg {
		margin-left: .25rem;
	}
}

.tags__item_hash {
	@include color(rgba(0, 0, 0, .4));
	opacity: 1;
	transition: $transition-base;
	height: 1.25rem;
	width: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.subscribe_form__wrapper {
	background: $color__darklight;
	margin-bottom: -3rem;
	margin-top: 3rem;
	position: relative;
	z-index: 3;

	/* @include media-breakpoint-down(md) {
		margin-top: 10rem;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 7.5rem;
	} */
}

.subscribe__form_container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	gap: .75rem 6.5rem;
	flex-wrap: wrap;
	overflow: hidden;

	@include media-breakpoint-down(lg) {
		justify-content: start;
	}

	@include media-breakpoint-down(md) {
		gap: 1.5rem 6.5rem;
	}

	@include media-breakpoint-down(sm) {
		gap: 2rem 6.5rem;
	}
}

.subscribe_form__background-image {
	color: $white;
	height: 600px;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: -144px;
	width: 385px;
}

.subscribe_form__title {
	font-size: 24px;
	line-height: 32px;
	color: $white;
	font-weight: 300;
	text-transform: uppercase;
}

.subscribe_form__form {
	display: flex;
	gap: 1.75rem 3.25rem;

	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
	}

	.form__field {
		display: flex;
		align-items: baseline;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

	.form__input {
		height: 3rem;
		color: $white;
	}

	.button {
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
}

.subscribe__input_wrap {
	display: flex;
}

.subscribe__input_title, .subscribe__errors {
	@include color(#999999);
	font-size: 18px;
	line-height: 32px;
	white-space: nowrap;
	margin-right: 1rem;
}

#subscribe_form__sticky_point {
	pointer-events: none;
	position: relative;
	top: -5rem;
}

.subscribe_form__sticky {
	position: fixed;
	left: 0;
	right: 0;
	z-index: 3;
	opacity: 1;
	background-color: transparent;
	bottom: -100px;
	transition: all $transition-default-time-bigger $transition-default-effect;
	width: min-content;

	&.subscribe_sticky {
		opacity: 1;

		@include media-breakpoint-down(lg) {
			left: 1.375rem;
			bottom: 82px;
		}

		@include media-breakpoint-down(sm) {
			left: 1rem;
			bottom: 72px;
		}
	}

	.container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 2rem;
	}
}

.subscribe_form__sticky_title {
	@include color($white);
	@include fs-lh(1.25rem, 1.75rem);

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.carousel__button {
	svg {
		transition: $transition-base;
	}

	&:hover {
		svg {
			color: $color__gold_hover;
		}
	}

	&:active {
		svg {
			color: $color__gold_active;
		}
	}
}

.arrow-up {
	@include color($white);
	display: flex;
	width: 2.5rem;
	height: 2.5rem;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: $color__gold;
	border-radius: $border-radius-xs;
	position: fixed;
	bottom: 44px;
	right: 44px;
	transition: $transition-base;
	opacity: 0;
	z-index: 4;

	&:hover {
		background: $color__gold_hover !important;
		opacity: 1 !important;
	}

	&:active {
		background: $color__gold_active !important;
	}

	&.active {

	}

	@include media-breakpoint-down(lg) {
		right: 1.375rem;
		bottom: 82px;
	}

	@include media-breakpoint-down(sm) {
		right: 1rem;
		bottom: 72px;
	}
}

[hidden] {
	display: none !important;
}

.toast {
	width: min-content;
	white-space: nowrap;
	transition: $transition-base;

	.btn-close {
		opacity: 1;
		mask: var(--btn-close-bg) center / 1em auto no-repeat;
		background: $color__dark;
		filter: none !important;
		cursor: pointer;
		width: 2rem;
		height: 2rem;
		padding: 0;
		margin-left: .5rem;
		transition: $transition-base;

		&.btn-close-white {
			background: $white;

			&:hover {
				background: $color__gold;
			}

			&:active {
				background: $color__gold_active;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		margin: 0 !important;
		width: calc(100% - 2rem) !important;
		bottom: 125px !important;
	}
}

.toast-body {
	padding-left: .375rem;
	padding-right: .375rem;
	letter-spacing: -0.005em;
	font-weight: 300;
	line-height: 1.25rem;

	.d-flex {
		align-items: center;
	}
}

.toast_body__content {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.toast_body__content__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2rem;
	height: 2rem;
}

.toast_body__content__title {
	@include color($white !important);
	border-bottom: 1px dashed;
	text-decoration: none;
	cursor: pointer;
	margin-left: .75rem;
	transition: $transition-base;

	&:hover {
		@include color($color__gold !important)
	}

	&.loading {
		@include color(#666 !important);
		pointer-events: none;
	}
}

[data-role=subscribe-sticky-trigger] {
	position: relative;
	top: -5rem;
}

.rounded-pill {
	@include color($white);
	background: $color__gold_active;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50% !important;
	font-size: .75rem;
	line-height: 1rem;
	min-width: 1.25rem;
	height: 1.25rem;
}

.zi-1 {
	z-index: 1;
}

.zi-2 {
	z-index: 2;
}

.zi-3 {
	z-index: 3;
}
