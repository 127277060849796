.loyalty-form {
	position: relative;
	z-index: 1;
	margin: 1.5rem 0 120px;

	.form__field {
		&:not(.checkbox) {
			max-width: 560px;
		}
	}

	> p, .contact-form__text {
		@include display-text-small;
		@include color($color__dark);
		margin: 25px 0;
		font-weight: 300;
		text-align: left;
	}

	@include media-breakpoint-down(md) {
		margin: 25px 0 60px;
	}

	.button {
		min-width: 16rem;
	}

	.contact-form__button {
		margin: inherit;
	}

	.contact-form__text-wrapper {
		text-align: left;
	}

	.checkbox__text {
		line-height: 100%;
	}

	.not-found__button {
		margin-left: 0;
		margin-right: 0;
	}
}
