.products-showcase-card {
	position: relative;
	flex-shrink: 0;
	width: calc(100% / 3 - 17px);
	height: 540px;
	margin-left: 24px;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		width: 32%;
		height: auto;
		margin-left: 16px;
		transition: $transition-base;

		&::before {
			display: block;
			padding-bottom: 114%;
			content: "";
		}
	}

	@include media-breakpoint-down(sm) {
		width: 56.1%;
		height: 188px;
		margin-left: 16px;
		box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05);
		transition: $transition-base;
	}

	@include media-breakpoint-down(md) {
		margin-left: 16px;
	}

	&.products-showcase-card_active {
		height: 640px;

		@include media-breakpoint-down(lg) {
			height: auto;
		}

		@include media-breakpoint-down(sm) {
			height: 240px;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
			transition: $transition-base;

			a {
				max-height: 100%;

				img {
					max-height: 100%;
				}
			}
		}

		.products-showcase-card {
			@include media-breakpoint-down(sm) {
				.products-showcase-card__desc {
					background: rgba(0, 0, 0, 0.3);
				}

				.products-showcase-card__title, .products-showcase-card__price {
					color: $white;
				}
			}

			.products-showcase-card__image {
				&:last-child:not(.lazy):not(:first-child), &.loaded:last-child:not(:first-child) {
					opacity: 1;
				}
			}

			.products-showcase-card__video {
				&:last-child:not(.lazy):not(:first-child), &.loaded:last-child:not(:first-child) {
					opacity: 1;
				}
			}
		}
	}
}

.products-showcase-card__link {
	position: relative;
	display: block;
	line-height: 0;
}

.products-showcase-card__icon {
	color: $white;
	fill: none;
	stroke: $white;
}

.products-showcase-card__gallery {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.products-showcase-card__video {
	width: 100%;
	height: 100%;
	object-fit: cover;


	&:last-child {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: $transition-base;

		@include media-breakpoint-down(lg) {
			display: block;
			/* opacity: 1; */
		}
	}
}

.products-showcase-card__image {
	width: 100%;
	height: 100%;
	object-fit: cover;

	/* &.lazy:not(.loaded):first-child {
		color: transparent;
		background-color: #e8e6f0;
		animation: blink 1.1s linear infinite;
	} */

	@include media-breakpoint-down(lg) {
		&:last-child {
			display: block;
			/* opacity: 1; */
		}
	}

	@include media-breakpoint-down(sm) {
		max-height: 144px;
	}

	&:last-child {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: $transition-base;
	}
}

.products-showcase-card__desc {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 24px 4px 30px;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.3);

	@include media-breakpoint-down(lg) {
		padding: .5rem .25rem;
	}

	@include media-breakpoint-down(sm) {
		background: transparent;
	}
}

.products-showcase-card__title {
	@include color($white);
	display: inline-block;
	font-weight: normal;
	font-size: 25px;
	line-height: 30px;
	margin: 0 0 1rem;
	text-decoration: none;

	&:hover {
		@include color($white !important);
	}

	@include media-breakpoint-down(lg) {
		@include display-text-small;
		margin: 0;
	}

	@include media-breakpoint-down(sm) {
		@include text-smallest;
		@include color($black);
		margin: 0;
		line-height: 100%;

		&:hover {
			@include color($black !important);
		}
	}
}

.products-showcase-card__price {
	@include display-text-small;
	@include color($white);

	@include media-breakpoint-down(lg) {
		font-weight: 300;
		font-size: 12px;
	}

	@include media-breakpoint-down(sm) {
		@include color($black);
		font-weight: 400;
	}

	.products-showcase-card__price_discount {
		margin-left: 1.5rem;
		line-height: 19px;
		text-decoration-line: line-through;

		@include media-breakpoint-down(lg) {
			margin-left: .875rem;
			line-height: 130%;
		}
	}
}