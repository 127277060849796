.badge {
	letter-spacing: .01555em;
	padding: .5rem;
	background-color: $color__gold;
	clip-path: polygon(100% 0, 100% 100%, 50% 86%, 0 100%, 0 0);
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: subpixel-antialiased;

	@include media-breakpoint-down(sm) {
		padding: .25rem .5rem;
	}
}

.badge_dark {
	background-color: rgba(51,51,51,.98);
	/*background-color: #444;*/
}

.badge_red {
	background-color: $color__red;
}

.badge_green {
	background-color: $color__green;
}

.badge__text {
	@include text-small;
	@include lh(1);
	@include color($white);
	text-transform: uppercase;

	@include media-breakpoint-down(lg) {
		font-size: .875rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: .75rem;
	}
}

.badge_geometry_rect {
	clip-path: none;
}


