.top-bar {
	width: 100%;
	background-color: #f0f0f0;
	position: relative;
	z-index: 11;

	@include media-breakpoint-down(md) {
		.top-bar__container {
			display: none;
		}
	}
}

.top-bar__container {
	display: flex;
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.top-bar__icon {
	@include color($color__dark);
	margin-right: 1rem;
	fill: none;
}

.top-bar__icon_phone {
	width: 1.25rem;
	height: 1.25rem;
	margin-right: 1rem;

	@include media-breakpoint-down(lg) {
		margin-right: .375rem;
	}
}

.top-bar__separator {
	display: block;
	width: .75px;
	height: 1.125rem;
	margin: 0 2rem;
	background-color: $color__gray;
	border-radius: .15rem;

	@include media-breakpoint-down(lg) {
		margin: 0 1rem;
	}
}

.top-bar__list {
	display: flex;
}

.top-bar__item {
	@include text-small;
	@include color($black);
	font-weight: 300;
	text-decoration: none;
	transition: $transition-base;

	&:not(:last-child) {
		margin-right: 2rem;
		@include media-breakpoint-down(lg) {
			margin-right: 1rem;
		}
	}
}

.top-bar__link {
	@include text-small;
	@include color($color__dark, true);
	display: flex;
	align-items: center;
	margin-left: auto;
	text-decoration: none;
	font-weight: 400;
	white-space: nowrap;

	&:active {
		@include color($color__gold_active !important);
	}
}

