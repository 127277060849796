.products-showcase {
	position: relative;
	margin-top: 30px;
}

.products-showcase__viewport {
	overflow: hidden;
}

.products-showcase__list {
	display: flex;
	align-items: center;
	height: 640px;

	@include media-breakpoint-down(lg) {
		height: auto;
		margin-bottom: 24px;
	}

	@include media-breakpoint-down(sm) {
		height: 250px;
	}
}

.products-showcase__button {
	position: absolute;
	top: 50%;
	background-color: transparent;
	border: none;
	outline: none;
	transform: translateY(-50%);
	cursor: pointer;

	@include media-breakpoint-down(md) {
		display: none;
	}

	svg {
		pointer-events: none;

		@include media-breakpoint-down(lg) {
			height: 50px;
		}
	}

	&.products-showcase__button_prev {
		left: -46px;

		@include media-breakpoint-down(lg) {
			left: -40px;
		}
	}

	&.products-showcase__button_next {
		right: -46px;

		@include media-breakpoint-down(lg) {
			right: -40px;
		}
	}
}

.products-showcase__catalog-link {
	@include color($color__darklight, true);
	position: absolute;
	right: calc(100% / 6);
	bottom: 0;
	color: $color__darklight;
	font-weight: 300;
	font-size: 20px;
	line-height: 25px;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 3px;
	transform: translateX(50%);

	@include media-breakpoint-down(lg) {
		@include color($color__gold, true);
		right: 50%;
		font-size: 12px;
		line-height: 130%;
	}

	@include media-breakpoint-down(md) {
		right: 18%;
	}
}
