.cart-product {
	position: relative;
	display: grid;
	grid-gap: 2.625rem .75rem;
	grid-template-areas:
        'photo label count total'
        'photo name quantity price'
        'photo . . .';
	grid-template-columns: 1fr 1.75fr .75fr .75fr;
	align-items: center;
	padding: 1rem 0 1rem;

	@include media-breakpoint-down(xl) {
		grid-gap: 0 5%;
		grid-template-areas: 'photo name quantity price';
		grid-template-columns: .5fr 1.5fr .35fr .75fr;
		padding: 1rem 0;
	}

	@include media-breakpoint-down(md) {
		grid-template-areas:
            'photo name name'
            'photo price quantity';
		grid-template-columns: 114px 87px 1fr;
		padding: .5rem 0;

		.cart-product__price-discount {
			text-align: left;
		}
	}

	@include media-breakpoint-down(sm) {
		grid-template-columns: 105px 87px 1fr;
	}

	&:hover {
		.cart-product__image {
			img {
				filter: saturate(1.1);
			}
		}
	}

	&.loading {
		pointer-events: none;

		&:before {
			opacity: 1;
			z-index: 1;
		}
	}

	&.cart-product_small {
		grid-gap: 0 2.5rem;
		grid-template-areas:
            'photo name empty'
            'photo attributes price';
		grid-template-columns: 72px 1fr max-content;
		padding: 1rem 0;
		border: 0;
		border-bottom: 1px solid rgba(229, 229, 229, 0.6);
		align-items: center;

		@include media-breakpoint-down(xl) {
			grid-template-columns: 72px minmax(200px, 1fr)  max-content;

			.cart-product__price-total {
				@include text-normal;
				line-height: 22px;
			}
		}

		@include media-breakpoint-down(sm) {
			grid-template-areas:
                'photo name'
                'photo price'
                'photo attributes';
			grid-template-columns: 100px 1fr;
			grid-gap: 0 1.875rem;
			padding: 1rem 0;
		}

		.cart-product {
			@include media-breakpoint-down(xl) {
				.cart-product__price {
					justify-self: flex-end;
				}
			}

			@include media-breakpoint-down(md) {
				.cart-product__name {
					margin-bottom: 0;
				}
			}

			.cart-product__image {
				margin: 0;

				@include media-breakpoint-down(sm) {
					margin: 0 auto;
				}
			}

			.cart-product__attributes {
				max-width: 380px;
			}

			.cart-product__attribute {
				&:not(:last-child) {
					margin-bottom: 4px;
				}
			}

			.cart-product__sale-cities {
				position: relative;
				grid-area: cities;
				margin: 24px 0 16px;
				padding: 20px 48px 20px 50px;
				background: #f0f0f0;
				border-radius: 9px;

				@include media-breakpoint-down(xl) {
					margin-top: 16px;
					@include text-smallest;
				}
			}

			.cart-product__sale-cities-text {
				@include text-normal;
				@include color($black);
				font-weight: 300;
				line-height: 22px;

				&:first-of-type {
					margin-bottom: 10px;
				}
			}

			svg {
				position: absolute;
				top: 20px;
				left: 23px;
				width: 20px;
				height: 20px;
			}
		}
	}

	+ .cart__sale-cities {
		margin-top: -28px;

		@include media-breakpoint-down(md) {
			margin-top: 16px;
		}
	}
}

.cart-product__image {
	flex-shrink: 0;
	grid-area: photo;
	max-width: 160px;

	img {
		width: 100%;
		max-height: 160px;
		object-fit: contain;
		transition: $transition-base;
	}
}

.cart-product__name {
	@include color($color__dark);
	grid-area: name;
	font-weight: 400;
	font-size: 20px;
	line-height: 127%;
	text-transform: uppercase;

	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}

	@include media-breakpoint-down(md) {
		@include text-small;
		padding-right: 40px;
	}

	a {
		@include color($color__dark, true);
	}
}

.cart-product__attributes {
	grid-area: attributes;

	@include media-breakpoint-down(md) {
		max-width: 400px;
	}
}

.cart-product__attribute {
	@include display-text-small;
	display: flex;
	align-items: baseline;

	@include media-breakpoint-down(xl) {
		grid-template-columns: 56px 1fr;
		font-size: 12px;

		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	&:not(:last-child) {
		margin-bottom: 16px;
	}
}

.cart-product__key {
	@include color($color__darklight);
	font-weight: 300;
}

.cart-product__value {
	@include color($color__darklight);
	margin-left: 24px;
	font-weight: 500;

	&:not(:last-child) {
		margin-bottom: 4px;
	}
}

.cart-product__value-wrapper {
	width: 100%;
	max-width: 250px;
}

.cart-product__control {
	grid-area: quantity;

	@include media-breakpoint-down(xl) {
		margin: 0 auto;
	}

	@include media-breakpoint-down(md) {
		margin: 0 20px;
	}
}

.cart-product__price {
	@include color($color__darklight);
	grid-area: price;
	width: max-content;
	line-height: 130%;

	.catalog-product-card__price_old {
		justify-content: right;
		margin-top: .125rem;
		margin-bottom: 0;

		@include media-breakpoint-down(xl) {
			margin-top: .0625rem;
		}

		@include  media-breakpoint-down(md) {
			justify-content: left;
			margin-top: 0;
		}

		@include  media-breakpoint-down(sm) {
			margin-bottom: 0;
			margin-top: -.125rem;
		}

		.catalog-product-card__price_old__value {
			position: relative;
			text-decoration: none;

			@include media-breakpoint-down(xl) {
				font-size: 14px;
			}

			@include  media-breakpoint-down(sm) {
				font-size: 13px;
			}
		}

		&.hidden {
			display: none !important;
		}
	}
}

.cart-product__price-total {
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;

	@include media-breakpoint-down(lg) {
		@include text-normal;
		line-height: 22px;
	}

	@include media-breakpoint-down(md) {
		@include text-small;
	}
}

.cart-product__price-discount {
	@include text-small;
	font-weight: 300;
	text-align: right;
	text-decoration-line: line-through;

	@include media-breakpoint-down(xl) {
		font-weight: 300;
		font-size: 12px;
	}

	&.hidden {
		display: none;
	}
}

.cart-product__close {
	@include color($color__graylight);
	position: absolute;
	top: calc(50% + 10px);
	right: 0;
	width: 23px;
	height: 23px;
	padding: 0;
	background: none;
	border: 0;
	transform: translateY(-50%);
	cursor: pointer;
	transition: $transition-base;

	@include media-breakpoint-down(xl) {
		top: calc(50% + 2px);

		svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	@include media-breakpoint-down(md) {
		top: 1.75rem;
	}

	&:hover {
		@include color($color__gray);
	}

	svg {
		pointer-events: none;
	}
}

.cart-product__subtitle {
	@include color($color__darklight);
	font-weight: 250;
	line-height: 19px;

	@include media-breakpoint-down(xl) {
		display: none;
	}
}

.cart-product__subtitle_label {
	grid-area: label;
}

.cart-product__subtitle_count {
	grid-area: count;
}

.cart-product__subtitle_total {
	grid-area: total;
}

.cart-product__includes {
	display: flex;
}
