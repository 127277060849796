.birthday-form {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);

	.form__field {
		display: flex;
		justify-content: end;
	}
}
