.link {
	color: $color__gold;
	background-color: transparent;
	border: none;
}

.link__text {
	@include display-text-small;
	font-weight: 300;

	.link__text_underline {
		text-decoration: underline;
		cursor: pointer;
	}

	.link__text_hide {
		display: none;
	}
}

.link___disabled {
	color: $color__light;
}