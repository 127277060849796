.testimonial-gallery {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 80px;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);

    &.active {
        display: flex;
    }
}

.testimonial-gallery__photo {
	display: block;
	width: 100%;
	height: 100%;
	margin: auto;
	object-fit: contain;
}

.testimonial-gallery__close {
	position: absolute;
	top: 30px;
	right: 30px;
	width: 30px;
	height: 30px;
	margin: 0;
	padding: 0;
	color: $white;
	background: none;
	border: 0;
	cursor: pointer;

	svg {
		width: 30px;
		height: 30px;
	}
}
