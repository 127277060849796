.order-header {
	@include color($white);
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1.4fr;

	@include media-breakpoint-down(md) {
		display: block;

		&.active {
			.order-header__bottom {
				display: flex;
			}
		}
	}

	@media (width <= 1600px) {
		.order-header_profile {
			display: block;

			&.active {
				.order-header__bottom_profile {
					display: flex;
				}
			}
		}
	}

	.order-header__top {
		padding: 16px 32px;
		background-color: $black;

		@media (width <= 1600px) {
			.order-header__top_profile {
				padding: 8px 16px;
				text-align: center;
				background-color: $color__darklight;
			}
		}

		@include media-breakpoint-down(md) {
			padding: 8px 16px;
			text-align: center;
			background-color: $color__darklight;
		}
	}

	.order-header__bottom {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 16px 70px 16px 0;
		background-color: $black;

		@media (width <= 1600px) {
			.order-header__bottom_profile {
				padding: 8px 16px;
				background-color: $color__darklight;
				opacity: 0.9;
			}
		}

		@include media-breakpoint-down(md) {
			display: none;
			padding: 8px 16px;
			background-color: $color__darklight;
			opacity: 0.9;

			.order-header__bottom_profile {
				display: flex;
			}
		}
	}

	.order-header__item {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
