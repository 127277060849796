.header {
	position: sticky;
	top: 0;
	z-index: 11;
	width: 100%;
	background-color: $white;
	border-bottom: 1px solid transparent;
	transition: all $transition-default-time-bigger linear;
}

.header_hide_top-bar {
	border-color: $color__light;
	transform: translateY(-58px);

	.payment__retry_wrapper {
		transform: translateY(58px);
	}

	@include media-breakpoint-down(md) {
		transform: translateY(0);

		.payment__retry_wrapper {
			transform: translateY(0);
		}
	}

	.menu__list, .menu__list_right_wrapp {
		max-height: calc(100vh - 148px);

		@include media-breakpoint-down(lg) {
			max-height: calc(100vh - 90px);
		}
	}

	.page-header {
		position: relative;
		top: -100%;
		height: 0;
		opacity: 0;
		overflow: hidden;
	}

	.header__scrolling_title {
		@include color($color__dark);
	}

	&.header__scroll_tight {
		.logo {
			.logo__link {
				flex-direction: row;

				/*.logo__icon {
					width: 49px;
					height: 37px;
					margin-right: 2px;
					image-rendering: -webkit-optimize-contrast;
				}

				.logo__text {
					width: 106px;
					margin-top: .25rem;
					image-rendering: -webkit-optimize-contrast;
				}*/
			}
		}

		.header__container {
			padding: .75rem 0;
		}

		.header__form {
			&.active {
				@include media-breakpoint-down(md) {
					top: 46px;
				}
			}
		}
	}

	& + main {
		.product-gallery {
			top: calc(150px + 1rem);
		}

		.tags__wrapper:not(.tags__no_sticky) {
			background: transparent;
			opacity: 0;

			&.tags_sticky {
				top: 120px;
				background: $white;
				opacity: 1;
				box-shadow: 0 2px 0 -1px rgba(99, 99, 99, .05), inset 0 0 2px -1px rgba(0, 0, 0, .1);
				padding: 1rem 0 1rem;
				background: #d9d9d9;

				@include media-breakpoint-down(lg) {
					top: 120px;
				}

				@include media-breakpoint-down(md) {
					top: 94px;
				}
			}
		}

		.page-header {
			h1 {
				opacity: 0;
			}
		}
	}

	.page-header {
		opacity: 1;
		top: 0;
	}

	.header__scrolling_top {
		background: $white;
		box-shadow: 0 4px 8px 0 rgba(25, 25, 25, .122);
		height: inherit;
		opacity: 1;
		padding: .5rem 0;
		pointer-events: auto;
		top: 100%;
	}
}

.sticky_breadcrumbs_show {
	.tags_sticky {
		top: 160px !important;

		@include media-breakpoint-down(lg) {
			top: 160px !important;
		}

		@include media-breakpoint-down(md) {
			top: 134px !important;
		}
	}

	.payment__retry_wrapper {
		position: fixed;
		top: 0;

		&.loaded {
			& + .container {
				padding-top: 44px;
			}
		}
	}
}

.sticky_tags_show {
	.header__scrolling_top {
		box-shadow: none;
	}
}

.header__container {
	position: relative;
	padding-bottom: 2rem;
	padding-top: 1.75rem;
	z-index: 10;
	background: $white;

	& > .container {
		display: flex;
		column-gap: 1rem;
		align-items: center;
		overflow: visible;

		& + {
			.page-header {
				padding-top: 2rem;
				padding-bottom: 0;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		display: block;
		padding-top: 20px;
		padding-bottom: 20px;
		transition: $transition-base;
	}
}

.header__logo {
	margin: -.5rem 2.25rem 0 0;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		margin: 0 2rem 0 0;
	}

	@include media-breakpoint-down(md) {
		margin: -.625rem 0;
	}
}

.header__logo-wrapper {
	display: flex;
	align-items: center;

	@include media-breakpoint-down(md) {
		flex-direction: row-reverse;
		justify-content: space-between;
		width: 100%;
	}
}

.header__burger {
	display: flex;
	align-items: center;
	/*box-shadow: 0 0 6px 0 rgba(0, 0, 0, .04);*/
	border: 1px solid $color__gold;

	&:hover {
		background-color: $white !important;
		color: $color__gold !important;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .05);
	}

	&:active {
		box-shadow: none;
	}

	&.active {
		background-color: $color__light !important;
		border: 1px solid $color__light !important;

		.burger {
			&.active {
				&:before, &:after {
					background-color: $color__graydark;
				}
			}
		}

		.header__burger-text {
			@include color($white);
		}

		&:active {
			background-color: #b7b7b7 !important;
			border: 1px solid #b7b7b7 !important;
			box-shadow: none;
		}
	}

	@include media-breakpoint-down(md) {
		padding: 0;
		border-style: none !important;
		background-color: transparent;

		.header__burger-text {
			display: none;
		}

		&.active {
			background-color: transparent !important;
			border-color: transparent !important;

			&:active {
				background-color: transparent !important;
				border-color: transparent !important;
			}

			.header__burger-text {
				display: none;
			}

			.burger {
				border-color: $color__dark;

				&:before, &:after {
					background-color: $color__dark;
				}

				&:hover {
					border-color: $color__gold_hover;

					&:before, &:after {
						background-color: $color__gold_hover;
					}

				}
			}
		}

		&:hover {
			box-shadow: none !important;

			.burger {
				border-color: $color__gold_hover;

				&:before, &:after {
					background-color: $color__gold_hover;
				}
			}
		}
	}
}

.header__burger-text {
	@include text-small;
	margin-left: .5rem;
	font-weight: 400;
	text-transform: uppercase;
	transition: $transition-base;
	letter-spacing: -0.005em;
}

.header__search-button {
	display: none;
	padding: 0;

	@include media-breakpoint-down(md) {
		display: block;
		width: 1.875rem;
		height: 1.375rem;
		background: none;
		border: none;
		cursor: pointer;
		text-align: right;

		&:hover, &.active {
			.header__search-button__icon {
				@include color($color__dark);
			}
		}

		.header__search-button__icon {
			@include color($color__gold);
			height: 1.375rem;

			path {
				stroke-width: 1;
			}
		}
	}
}

.header__form {
	width: 100%;
	margin: 0 1.875rem 0 0;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		margin: 0;
	}

	@include media-breakpoint-down(md) {
		position: absolute;
		pointer-events: none;
		opacity: 0;
		top: -100%;
		right: 0;
		left: 0;
		padding: 0 2.625rem 1.25rem 2.625rem;
		border-bottom: 1px solid transparent;
		background: transparent;

		&.active {
			pointer-events: all;
			opacity: 1;
			top: 60px;
			background: $white;
			border-bottom-color: $design-layout__color_gray_light;
		}
	}

	@include media-breakpoint-down(sm) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.header__control {
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.header__control-list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 3rem;

	@include media-breakpoint-down(xl) {
		column-gap: 1.75rem;
	}

	/*> *:not(:last-child) {
		margin-right: 48px;

		@media (width <= 1240px) {
			margin-right: 16px;
		}
	}*/
}

.header__control-link {
	position: relative;
	display: block;
	line-height: 0;
	cursor: pointer;

	&:hover, &:focus {
		.header__control_icon {
			color: $color__gold;
			stroke: $color__gold;
		}

		.header__control_count  {
			background: $color__gold_hover;
		}
	}

	&:active {
		.header__control_icon {
			color: $color__gold_active;
			stroke: $color__gold_active;
		}

		.header__control_count  {
			background: $color__gold_active;
		}
	}
}

.header__control_icon {
	width: 1.5rem;
	height: 1.5rem;
	color: $black;
	transition: $transition-base;
	fill: none;
	stroke: $black;
	z-index: -1;

	&.header__control_icon_auth {
		width: 30px;
		height: 30px;
	}
}

.header__control-avatar {
	width: 25px;
	height: 25px;
	object-fit: cover;
	border-radius: 50%;
}

.header__control_count  {
	@include color($white);
	position: absolute;
	left: 14px;
	top: -1px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	background-color: $color__gold;
	font-weight: 400;
	padding: 0 .1875rem;
	line-height: .825rem;
	min-width: .825rem;
	border-radius: $border-radius-xl;
	transition: $transition-base;

	&:empty {
		display: none;
	}
}

[data-role=small-wishlist] {
	.header__control_count  {
		left: 15px;
	}
}

.header__scrolling_top {
	height: 0;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	top: 50%;
	width: 100%;
	z-index: -1;
	transition: $transition-base;

	&.no_shadow {
		box-shadow: none;
	}

	.container {
		display: flex;
		justify-content: space-between;
	}

	.news_tags__control_panel {
		width: auto;
	}

	@include media-breakpoint-down(sm) {
		.news_tags__control_panel {
			.news_tags__control_panel__title, .news_tags__control_panel__list {
				display: none;
			}
		}
	}
}

.news_tags__control_panel__button {
	@include color($color__dark);
	height: 2rem;
	width: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: transparent;
	border: 0;
	outline: 0;
	transition: $transition-base;
	border-radius: $border-radius-xs;

	&:hover {
		@include color($color__gold !important);
		border-color: #f0f0f0 !important;
	}

	&:active {
		@include color($color__gold_active !important);
		background-color: hsla(0, 0%, 90%, .9);
	}

	&.active {
		@include color($white !important);
		background: $color__gold_active;
		border-color: $color__gold_active;

		&.news_tags__control_panel__button__close {
			background-color: transparent !important;
			border-color: transparent !important;
		}

		&:hover {
			background: $color__gold_hover !important;
			border-color: $color__gold_hover !important;

			&.news_tags__control_panel__button__close {
				background-color: transparent !important;
				border-color: transparent !important;
			}
		}

		&:active {
			background: $color__gold !important;

			&.news_tags__control_panel__button__close {
				background-color: transparent !important;
				border-color: transparent !important;
			}
		}
	}
}

.news_tags__control_panel__button__close {
	background-color: transparent !important;
	border-color: transparent !important;

	&.loading {
		&:after {
			width: 1rem;
			height: 1rem;
			left: calc(50% - .5rem);
			top: calc(50% - .5rem);
		}

		svg {
			opacity: 0;
		}
	}
}

.tags__filter_content {
	width: 100%;
}

.tags__controll_panel {
	justify-content: space-between;
}

.header__scrolling_title__wrapp {
	display: flex;
	cursor: pointer;

	&:hover {
		.header__scrolling_title__button {
			@include color($color__gold !important);
		}
	}

	&:active {
		.header__scrolling_title__button {
			@include color($color__gold_active !important);
			background-color: hsla(0, 0%, 90%, .9);
		}
	}

	.catalog__products_count {
		font-size: .875rem;
		align-self: flex-end;

		[data-role=news-count-description] {
			display: none;
		}
	}
}

.header__scrolling_title {
	@include fs-lh(26px, 32px);
	@include color(transparent);
	text-transform: uppercase;
	font-weight: 400;
	margin-right: .75rem;
	transition: $transition-base;
}

.header__scrolling_title__button {
	@include color($color__dark);
	background: transparent;
	height: 2rem;
	width: 2rem;
	min-width: 2rem;
	box-sizing: border-box;
	margin-right: .5rem;
	align-items: center;
	justify-content: center;
	display: flex;
	cursor: pointer;
	border-radius: $border-radius-xs;
	transition: $transition-base;

	&:hover {
		@include color($color__gold);
	}

	&.active {
		@include color($white);
		background: $color__gold_active !important;
		border-color: $color__gold_active !important;
	}
}

.news_tags__control_panel {
	display: flex;
	font-size: 16px;
	line-height: 20px;
	align-items: center;
	gap: 2vw;
	cursor: pointer;
	justify-content: space-between;
	width: 100%;
	transition: $transition-base;

	&:hover {
		.news_tags__control_panel__title {
			@include color($black);
		}

		.news_tags__control_panel__list {
			@include color($color__gold_hover);
		}

		.news_tags__control_panel__button__toggle {
			border-color: #f0f0f0 !important;
		}

		&.active {
			.news_tags__control_panel__button__toggle {
				@include color($white !important);
				background: $color__gold_active;
				border-color: $color__gold_active;
			}
		}
	}
}

.news_tags__control_panel__button__toggle {
	@include color($color__gold !important);
	position: relative;

	.rounded-pill {
		position: absolute;
		top: -.5rem;
		right: -.75rem;
	}
}

.news_tags__control_panel__title_wrapp {
	display: flex;
	align-items: center;
}

.news_tags__control_panel__title {
	@include color($color__dark);
	margin-right: .5rem;
	transition: $transition-base;
}

.news_tags__control_panel__list {
	@include color($color__gold);
	transition: $transition-base;
	white-space: nowrap;
}

.news_tags__control_panel__buttons {
	display: flex;
	gap: .25rem;
}

.header__scrolling_breadcrumb {
	opacity: 0;
	background: transparent;
	height: 0;
	pointer-events: none;
	overflow: hidden;
	transition: $transition-base;

	&:not(.active) {
		svg, .breadcrumb__item {
			color: transparent;
		}
	}

	&.active {
		background: #f7f7f7;
		padding: .5rem 0;
		margin: -.5rem 0 .5rem 0;
		opacity: 1;
		height: auto;
		pointer-events: all;
	}
}