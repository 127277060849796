.subscribe-form {
	display: flex;
	flex-direction: column;
	align-items: center;

	@include media-breakpoint-down(md) {
		grid-template-areas:
            'title'
            'form';
		grid-template-columns: 1fr;
	}
}

.subscribe-form__header {
	max-width: 673px;
	margin-bottom: 60px;
	text-align: center;

	@include media-breakpoint-down(lg) {
		max-width: 544px;
		margin-bottom: 40px;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 20px;
	}
}

.subscribe-form__title {
	margin-bottom: 22px;
	font-weight: 300;
	font-size: 38px;
	line-height: 46px;

	@include media-breakpoint-down(lg) {
		margin-bottom: 12px;
		font-size: 25px;
		line-height: 30px;
	}
}

.subscribe-form__kicker {
	color: $color__darklight;
	font-weight: 250;
	font-size: 20px;
	line-height: 130%;
	text-align: center;
}

.subscribe-form__form {
	position: relative;
	display: grid;
	grid-gap: 16px 0;
	grid-template-areas:
            'field button'
            'politics politics';
	grid-template-columns: 1fr 183px;
	width: 100%;
	max-width: 673px;

	@include media-breakpoint-down(lg) {
		max-width: 544px;
	}

	@include media-breakpoint-down(md) {
		grid-template-areas:
                'field'
                'politics'
                'button';
		grid-template-columns: 1fr;
	}
}

.subscribe-form__field {
	grid-area: field;
	height: 50px;

	@include media-breakpoint-down(lg) {
		margin-right: 16px;
	}

}

.subscribe-form__input {
	@include display-text-small;
	@include color($color__darklight);
	height: 50px;
	padding-left: 40px;
	font-weight: 300;

	&::placeholder {
		@include display-text-small;
		color: $color__darklight;
		font-weight: 300;
	}
}

.subscribe-form__icon {
	position: absolute;
	top: 13px;
}

.subscribe-form__button {
	@include display-text-small;
	grid-area: button;
	max-width: 186px;
	height: 51px;
	padding: 0;
	color: $color__gold;
	font-weight: 400;
	background-color: transparent;
	border: 1px solid $color__gold;

	@include media-breakpoint-down(md) {
		max-width: 100%;
	}
}

.subscribe-form__politics {
	@include display-text-small;
	@include color($black);
	grid-area: politics;
	font-weight: 300;
	text-align: center;

	@include media-breakpoint-down(lg) {
		font-size: 12px;
		line-height: 130%;
		text-align: center;
	}

	.subscribe-form__politics-link {
		color: $black;
		text-decoration: underline;

		@include media-breakpoint-down(lg) {
			color: $color__gold;
		}

		&:hover {
			color: $color__gold;
		}
	}
}
