.orders__item {
	&:not(:first-child) {
		margin-top: 56px;

		@include media-breakpoint-down(md) {
			margin-top: 40px;
		}
	}
}

.orders__pagination {
	display: flex;
	justify-content: center;
	margin-top: 2.875rem;

	.pagination__item {
		&:hover {
			background-color: hsla(0, 0%, 90%, .6);
		}

		&:active {
			background-color: hsla(0, 0%, 90%, .9);
		}
	}
}

.orders__list {
	margin-top: 48px;

	@include media-breakpoint-down(lg) {
		margin-top: 0;
	}
}