.service {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: space-between;
	text-decoration: none;

	@include media-breakpoint-down(md) {
		flex-flow: column;
		min-width: 100%;
	}

	&:hover {
		.service__text {
			@include color($color__gold);
		}
	}
}

.service__icon {
	width: 90px;
	height: 90px;
	mask-image: var(--mask);
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;
	background-color: $color__gold !important;

	@include media-breakpoint-down(lg) {
		width: 80px;
		height: 70px;
	}

	@include media-breakpoint-down(md) {
		width: 70px;
		height: 60px;
	}

	> img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.service__text {
	@include color($black);
	@include display-text-big;
	max-width: 160px;
	font-weight: 300;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		@include display-text-normal;
		justify-content: space-around;
		max-width: 134px;
	}

	@include media-breakpoint-down(md) {
		max-width: 200px;
		text-align: center;
	}

	@include media-breakpoint-down(sm) {
		font-size: 13px;
		line-height: 15.6px;
	}
}