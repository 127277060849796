.separator {

}

.separator__line {
	width: 100%;
	height: 3px;
	background-color: $color__dark;

	&.separator__line_color_gray {
		background-color: $color__light;
	}

	&.separator__line_size_small {
		height: 1px;
	}
}