.company-description {
	max-width: $container__max_width;
	margin: 0 auto;
	padding: 0 2.625rem;

	@include media-breakpoint-down(xxl) {
		max-width: 100%;
		padding: 0;
	}
}

.company-description__icon {
	position: absolute;
	top: -396px;
	right: 90px;
	z-index: -5;
	transform: scaleX(-1);

	@include media-breakpoint-down(xxl) {
		display: none;
	}
}

.company-description__image {
	position: absolute;
	top: -73px;
	width: 814px;
	max-width: 50%;
	height: 685px;
	object-fit: cover;

	@include media-breakpoint-down(xxl) {
		position: unset;
		width: 100%;
		max-width: inherit;
		max-height: 560px;
	}

	@include media-breakpoint-down(md) {
		max-height: 360px;
	}
}

.company-description__text {
	max-width: 440px;
	margin-right: 93px;
	margin-left: auto;
	padding: 72px 0;

	@include media-breakpoint-down(xxl) {
		max-width: 100%;
		margin-right: 0;
		padding: 48px 2.625rem 0;
	}

	@include media-breakpoint-down(md) {
		padding: 32px 1rem 0;
	}
}

.company-description__paragraph {
	&:not(:last-child) {
		margin-bottom: 32px;
	}
}