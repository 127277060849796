.exchange-rate {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 56px -152px;
	padding: 72px 256px;
	overflow: hidden;
	color: $white;
	background: $color__darklight;
	opacity: 0.9;

	@media (width <= 1700px) {
		padding: 72px 150px;
	}

	@include media-breakpoint-down(xxl) {
		margin: 72px -80px;
	}

	@include media-breakpoint-down(md) {
		margin: 32px -8px;
		padding: 32px 8px;

		.exchange-rate__price-list {
			width: 100%;
		}

		.exchange-rate__list-item {
			display: flex;
			flex-flow: column nowrap;
			grid-gap: 0;
			align-items: center;
			width: 100%;
			margin: 0 auto 40px;
		}

		.exchange-rate__value {
			grid-gap: 8px;
			width: 100%;
			margin-top: 8px;
		}

		.exchange-rate__subtitle {
			text-align: center;
		}
	}

	@include media-breakpoint-down(sm) {
		align-items: unset;
		text-align: center;
	}

	.exchange-rate__logo {
		display: flex;
		flex-direction: column;
		align-items: center;

		.exchange-rate__logo-icon {
			width: 89px;
			height: 76px;
			margin-bottom: 13px;

			color: $color__gold;
		}

		.exchange-rate__logo-text {
			width: 222px;
			height: 40px;

			color: $color__gold;
		}
	}

	.exchange-rate__icon {
		position: absolute;
		top: 312px;
		right: -500px;
		bottom: 0;
		width: 1148px;
		height: 1106px;
		color: $white;

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.exchange-rate__title {
		margin: 56px 0 64px;
		font-weight: 300;
		font-size: 38px;
		line-height: 46px;
		text-transform: uppercase;


		@include media-breakpoint-down(md) {
			font-weight: 400;
			font-size: 20px;
			line-height: 127%;
		}
	}

	.exchange-rate__item {
		display: grid;
		grid-gap: 48px;
		grid-template-columns: 1fr 383px;
		align-items: end;
		max-width: 530px;
		margin-bottom: 40px;

		@include media-breakpoint-down(sm) {
			display: block;
			max-width: 380px;
			margin: 0 auto 40px;
		}
	}

	.exchange-rate__key {
		position: relative;
		font-weight: 500;
		font-size: 48px;
		line-height: 58px;

		&::after {
			position: absolute;
			top: .25rem;
			width: 1.125rem;
			height: 1.625rem;
			border: 5px solid $white;
			border-radius: 45%;
			content: "";
		}
	}

	.exchange-rate__value {
		display: grid;
		grid-auto-rows: minmax(1fr, auto);
		grid-gap: 16px 32px;
		grid-template-columns: 1fr 1fr;
	}

	.exchange-rate__subtitle {
		@include display-text-small;
		font-weight: 300;
		text-transform: uppercase;
	}

	.exchange-rate__price {
		padding: 16px 0;
		font-weight: 500;
		font-size: 25px;
		line-height: 30px;
		text-align: center;
		border: 1px solid $color__gold;
	}
}
