.services {
	position: relative;
	padding-top: 120px;

	@include media-breakpoint-down(lg) {
		padding-top: 0;
		overflow-x: hidden;
	}

	@include media-breakpoint-down(md) {
		padding-top: 0;
	}
}

.services__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 967px;
	margin: 0 auto;

	@include media-breakpoint-down(lg) {
		max-width: 674px;
	}

	@include media-breakpoint-down(md) {
		max-width: 674px;
	}
}

.services__list {
	display: grid;
	grid-gap: 72px;
	grid-template-columns: repeat(3, 1fr);
	margin-top: 72px;

	@include media-breakpoint-down(lg) {
		display: grid;
		grid-gap: 24px;
		margin-top: 54px;
	}

	@include media-breakpoint-down(md) {
		display: grid;
		grid-gap: 10px;
		margin-top: 32px;
	}
}

.services__icon {
	@include color($color__graylight);
	position: absolute;
	top: 0;
	left: 0;
	z-index: -5;
	width: 100%;
	height: 128px;
	opacity: 0.36;

	@include media-breakpoint-down(lg) {
		height: 58px;
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.services__ring {
	position: absolute;
	right: 100px;
	bottom: calc(100% - 100px);
	z-index: -5;

	@include media-breakpoint-down(lg) {
		display: none;
	}
}