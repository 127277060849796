.company-breadcrumb {
	margin: 64px 0 51px;

	@include media-breakpoint-down(xxl) {
		margin: 40px 0 32px;
	}

	@include media-breakpoint-down(sm) {
		margin: 16px 0 32px;
	}
}

