.burger {
	position: relative;
	display: block;
	width: 1.125rem;
	height: .75rem;
	background-color: transparent;
	border: none;
	border-top: 2px solid $color__gold;
	border-bottom: 2px solid $color__gold;
	outline: none;
	cursor: pointer;
	transition: $transition-base;

	&:before, &:after {
		position: absolute;
		top: calc(50% - 1px);
		left: 0;
		display: block;
		width: 100%;
		height: 2px;
		background-color: $color__gold;
		transition: $transition-base;
		content: '';
	}

	&.active {
		border-color: transparent !important;

		&:before {
			background-color: $white;
			transform: rotate(45deg);
		}

		&:after {
			background-color: $white;
			transform: rotate(-45deg);
		}
	}

	&.burger_sm {
		border-width: 2px;
		height: 14px;
		width: 20px;

		&:before {
			height: 2px;
		}
	}

	@include media-breakpoint-down(md) {
		height: 18px;
		width: 30px;
		border-top: 2px solid $color__gold;
		border-bottom: 2px solid $color__gold;

		&:before, &:after {
			height: 2px;
		}

		&.active {
			background-color: transparent !important;

			&:before {
				background-color: $color__gold;
				transform: rotate(45deg);
			}

			&:after {
				background-color: $color__gold;
				transform: rotate(-45deg);
			}
		}
	}
}
