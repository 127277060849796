.profile {
	display: grid;
	grid-column-gap: 40px;
	grid-template-areas:
        "photo contacts"
        "order order"
        "discount discount"
        "link link";
	grid-template-columns: 137px 1fr;
	margin-top: 20px;

	@include media-breakpoint-down(md) {
		grid-column-gap: 24px;
		grid-template-areas:
            "photo contacts"
            "order order"
            "discount discount"
            "link link";
		grid-template-columns: 104px 1fr;
		align-items: center;
	}

	.profile__photo {
		grid-area: photo;
	}

	.profile__form {
		display: flex;
		align-items: center;
	}

	.profile__contacts {
		display: flex;
		flex-direction: column;
		grid-area: contacts;
		align-items: flex-start;
		justify-content: center;
		min-height: 138px;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			min-height: 104px;
		}
	}

	.profile__contact {
		@include display-text-small;
		@include color($color__darklight);
		font-weight: 300;

		@include media-breakpoint-down(md) {
			@include text-smallest;
		}

		&:not(:last-child) {
			margin-bottom: .375rem;
		}
	}

	.profile__last-order {
		grid-area: order;
	}

	.profile__discount {
		grid-area: discount;

		@include media-breakpoint-down(md) {
			margin: 0 auto;
		}
	}

	.profile__edit-link {
		grid-area: link;
		margin: 4rem auto 0;


		@include media-breakpoint-down(md) {
			grid-area: link;
			margin: 2rem auto 0;
		}
	}
}
