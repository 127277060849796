.catalog-sort_wrapper {
	transition: $transition-base;

	&.disabled {
		opacity: .3;
		pointer-events: none;
	}
}

.catalog-sort_desktop {
	display: inline-grid;
	grid-auto-flow: column;
	grid-gap: 1.5rem;
	align-items: center;

	@include media-breakpoint-down(xxl) {
		.catalog-sort__title {
			display: none;
		}
	}

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.catalog-sort__select {
	min-width: 190px;
}

.catalog-sort_mobile {
	@include media-breakpoint-up(lg) {
		display: none;
	}

	min-height: 3.375rem;
	background-color: #f7f7f7;
	border-radius: $border-radius;

	.nice-select__button {
		.current {
			transition: $transition-base;

			@include color($white);

			&:hover {
				@include color(#f2f2f2);
			}
		}
	}

	/*.catalog-sort__select {
		padding: .5rem 38px .5rem .5rem;
		color: $white;
		background-color: $black;
		border: none;

		.catalog-sort__select-wrapper {
			position: relative;
			display: block;

			&::after {
				position: absolute;
				top: 1rem;
				right: .5rem;
				width: 14px;
				height: 9px;
				background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1C10.6569 3.73367 9.34315 5.26633 7 8L1 1' stroke='%23D4A67C' stroke-width='0.654' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
				content: '';
				pointer-events: none;
			}
		}
	}*/
}

.catalog-sort__title {
	@include display-text-small;
	@include color($color__dark);
	font-weight: 300;
}

.catalog-sort__link {
	@include display-text-small;
	@include color($color__darklight);
	padding: .75rem 1.25rem;
	font-weight: 300;
	text-decoration: none;
	cursor: pointer;
	border-radius: $border-radius-xs;
	transition: $transition-base;

	&.active {
		background-color: $color__gold;
		cursor: default;

		& > span {
			@include color($white !important);
		}
	}

	&:hover {
		background-color: #f7f7f7;

		& > span {
			@include color($black);
		}
	}

	&:active {
		background-color: hsla(0, 0%, 90%, .6);
		box-shadow: none;
	}

	&.active {
		&:hover {
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .05);
			background-color: $color__gold_hover;
		}
	}

	.catalog-sort__link_label {
		white-space: nowrap;
		transition: $transition-base;
	}
}