.color-inherit {
  @include color(inherit);
}

.color-white {
  @include color($white);
}

.color-info {
  @include color($color__info);
}

.color-light {
  @include color($color__light);
}

.color-dark {
  @include color($color__darklight);
}

.color-gold {
	@include color($color__gold);
}

.color-gold-hover {
	@include color($color__gold_hover);
}

.color-gold-active {
	@include color($color__gold_active);
}

.color-green {
  @include color($color__green);
}

.color-success {
  @include color($color__green);
}

.color-warning {
  @include color($color__warning);
}

.color-danger {
  @include color($color__red);
}

.color-red {
  @include color($color__red);
}

.color-muted {
  @include color($color__muted);
}

.color-gray {
  @include color($color__gray);
}

.bg-white {
  background: $white !important;
}

.bg-info {
  background: $color__info !important;
}

.bg-success {
  background: $color__green !important;
}

.bg-green {
  background: $color__green !important;
}

.bg-warning {
  background: $color__warning !important;
}

.bg-yellow {
  background: $color__warning !important;
}

.bg-danger {
  background: $color__red !important;
}

.bg-red {
  background: $color__red !important;
}

.bg-dark {
  background: $color__darklight !important;
}

.bg-white {
  background: $white !important;
}

.bg-info {
  background: $color__info !important;
}

.bg-success {
  background: $color__green !important;
}

.bg-green {
  background: $color__green !important;
}

.bg-warning {
  background: $color__warning !important;
}

.bg-danger {
  background: $color__red !important;
}

.bg-red {
  background: $color__red !important;
}

.bg-none {
  background: none !important;
}

.border-white {
  border-color: $white;
}

.border-info {
  border-color: $color__info !important;
}

.border-light {
  border-color: $color__light !important;
}

.border-success {
  border-color: $color__green !important;
}

.border-green {
  border-color: $color__green !important;
}

.border-warning {
  border-color: $color__warning !important;
}

.border-danger {
  border-color: $color__red !important;
}

.border-red {
  border-color: $color__red !important;
}

.border-dark {
  border-color: $color__darklight !important;
}
