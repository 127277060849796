.cart-form {
	display: grid;
	grid-template-columns: 1fr 186px;
	align-items: flex-start;
	width: 100%;
	max-width: 465px;

	@include media-breakpoint-down(sm) {
		grid-template-columns: 1fr;
	}

	&.hidden {
		display: none;
	}
}

.cart-form__button {
	@include display-text-small;
	@include color($color__gold);
	padding: 14px 29px;
	font-weight: 400;
	text-transform: uppercase;
	background-color: $white;
	border: 1px solid $color__gold;
}

.cart-form__field {
	&:not(:last-child) {
		margin-bottom: 0;

		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
	}

	.form__field-message {
		margin-left: 0;
	}
}

.cart-form__input {
	width: calc(100% - 1rem);
	height: 50px;
	padding: 15px 0 17px;

	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}