.page-header {
	position: relative;
	overflow: hidden;
	padding-bottom: 3.25rem;

	@include media-breakpoint-down(xxl) {
		.page-header__icon {
			display: none;
		}
	}

	@include media-breakpoint-down(md) {
		padding-bottom: 2.5rem;
	}

	@include media-breakpoint-down(sm) {
		padding-bottom: 2rem;
	}
}

.page-header__breadcrumb {
	margin: 0;
	padding: .5rem 0 0;
}