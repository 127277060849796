.ordererror {
	position: relative;
	padding: 172px 0 373px;

	@include media-breakpoint-down(md) {
		padding: 97px 0;
		background-color: rgba(0, 0, 0, 0.4);
	}

	& + .section {
		display: none;
	}
}

.ordererror__wrapper {
	margin: 0 auto;
	text-align: center;

	.button {
		max-width: 220px;
		margin: 0 auto;
	}

	@include media-breakpoint-down(sm) {
		.button {
			width: 100%;
			max-width: inherit;
		}
	}
}

.ordererror__title {
	@include color($black);
	margin: 0 0 .25rem 0;
	font-weight: 400;
	font-size: 64px;
	line-height: 130%;
	text-transform: none;

	@include media-breakpoint-down(lg) {
		font-size: 38px;
	}

	@include media-breakpoint-down(md) {
		@include color($white);
		margin-bottom: 10px;
		font-size: 25px;
	}
}

.ordererror__description {
	@include color($black);
	max-width: 580px;
	margin: 0 auto 27px;
	font-weight: 300;
	font-size: 25px;
	line-height: 130%;

	@include media-breakpoint-down(lg) {
		font-size: 20px;
	}

	@include media-breakpoint-down(md) {
		@include text-normal;
		@include color($white);
		margin-bottom: .675rem;
	}
}

.ordererror__note {
	@include color($black);
	max-width: 580px;
	margin: 27px auto 0;
	font-weight: 250;
	font-size: 20px;
	line-height: 130%;

	@include media-breakpoint-down(lg) {
		@include text-small;
	}

	@include media-breakpoint-down(md) {
		@include color($white);
		margin-top: .625rem;
		font-size: 12px;
	}

	a {
		@include color($color__gold, true);
		font-weight: 400;
		text-decoration: none;
	}
}