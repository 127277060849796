.page {
	display: grid;
	grid-gap: 2.75rem;
	grid-template-columns: 1.2fr 4.5fr;

	@include media-breakpoint-down(xxl) {
		display: block;
	}
}

.page_overview_account {
	display: grid;

	@include media-breakpoint-down(lg) {
		display: block !important;
	}
}

.page_overview {
	grid-template-columns: 338px 1043px;

	@media (width <= 1700px) {
		grid-template-columns: 338px 1fr;
		margin-right: 20px;

		.page_account {
			grid-gap: 16px;
			grid-template-columns: 0.25fr 0.75fr;
			margin-right: 0;
		}
	}

	@include media-breakpoint-down(xxl) {
		margin-right: 0;

		.page_account {
			display: grid;
			grid-gap: 16px;
			grid-template-columns: 0.25fr 0.75fr;
		}
	}

	.page_overview_account {
		grid-gap: 16px;
		grid-template-columns: 0.25fr 0.75fr;
	}
}

.page__title {
	max-width: 100%;
	color: $black;
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;

	@include media-breakpoint-down(xxl) {
		@include display-text-normal;
	}

	@include media-breakpoint-down(sm) {
		@include text-smallest;
		line-height: 130%;
	}

	.page__title_uppercase {
		text-transform: uppercase;
	}
}

.page__paragraph {
	@include display-text-normal;
	font-weight: 300;

	@include media-breakpoint-down(sm) {
		@include text-smallest;
		line-height: 130%;

		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&.page__paragraph_margin_bottom_small {
			margin-bottom: 8px;
		}
	}

	& > a {
		text-decoration: none;
	}

	&:not(:last-child) {
		margin-bottom: 32px;
	}
}

.page__paragraph_margin_bottom_null {
	margin-bottom: 0;
}

.page__paragraph_color_gold {
	color: $color__gold;
}

.page__paragraph_medium {
	font-weight: 500;
}

.page__paragraph_small {
	@include text-small;
}

.page__paragraph_align_center {
	text-align: center;
}

.page__backgorund_full {
	background: $color__darklight;
	opacity: 0.9;
}

.page__article {
	max-width: 820px;

	@include media-breakpoint-down(sm) {
		margin-top: 32px;
	}

	.page__article-title {
		margin: 56px 0 40px;
		font-weight: 400;
		font-size: 25px;
		line-height: 30px;

		@include media-breakpoint-down(xxl) {
			margin: 80px 0 40px;
		}

		@include media-breakpoint-down(sm) {
			@include text-smallest;
			margin: 64px 0 24px;
			font-size: 20px;
			line-height: 127%;
		}


		&:first-child {
			margin-top: 0;
		}
	}

	.page__article-title_big {
		@include color($color__darklight);
		margin-bottom: 32px;
		font-weight: 300;
		font-size: 38px;
		line-height: 46px;
		text-transform: uppercase;

		&:not(:first-child) {
			margin-top: 64px;
		}
	}

	.page__article-subtitle {
		font-size: 1.125rem;
		line-height: 1.375rem;
	}

	& > ul {
		margin: 2rem 0;
		list-style: disc;

		@include media-breakpoint-down(sm) {
			margin: 1rem 0;
		}

		& > li {
			margin-left: 18px;
		}
	}
}

.page__parallax-bottom {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 380px;
	height: 250px;
	opacity: 0.3;

	@include media-breakpoint-down(xxl) {
		display: none;
	}
}

.content__bg-image {
	@include color($color__darklight);
	position: absolute;
	bottom: 0;
	left: -320px;
	width: 650px;
	height: 560px;

	@include media-breakpoint-down(xxl) {
		display: none;
	}
}

.loyalty-slider {
	margin-bottom: 32px;
}
