.page-overview {
	position: relative;
	padding: 72px 152px;
	overflow: hidden;
	background-color: #f7f7f7;

	@include media-breakpoint-down(xxl) {
		padding: 0;
		background-color: transparent;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 2rem;

		.page__article {
			margin-top: 0;
		}
	}
}

.page-overview__separator {
	margin-top: 2rem;
	border: 1px solid $color__graylight;
	opacity: .6;
}

.page-overview_pb-small {
	padding-bottom: 54px;
}