.map-form {
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(sm) {
		margin-top: 64px;
	}
}

.map-form__map {
	height: 680px;
}

.map-form__container {
	position: absolute;
	top: 0;
	right: 262px;
	height: 100%;

	@include media-breakpoint-down(xxl) {
		right: 100px;
	}

	@include media-breakpoint-down(lg) {
		position: static;
		height: auto;
	}
}

.map-form__content {
	max-width: 1580px;
	height: 100%;
	margin: 0 auto;

	@include media-breakpoint-down(lg) {
		padding: 0;
	}
}

.map-form__form {
	max-width: 540px;
	margin-left: auto;
	height: 100%;

	@include media-breakpoint-down(lg) {
		margin: 80px auto 0;
	}
}
