@mixin before {
	position: relative;

	&:before {
		content: '';
		position: absolute;
	}
}

@mixin mw($mw,$width:100%) {
	width: $width;
	max-width: $mw;
}

@mixin wh($width,$height:$width) {
	width: $width;
	height: $height;
}

@mixin fw($fw) {
	flex: 0 0 $fw;
	max-width: $fw;
}

@mixin offset($margin,$padding) {
	margin: $margin;
	padding: $padding;
}

@mixin px($px__l,$px__r:$px__l) {
	padding: {
		left: $px__l;
		right: $px__r;
	}
}

@mixin py($py__t,$py__b:$py__t) {
	padding: {
		top: $py__t;
		bottom: $py__b;
	}
}

@mixin mx($mx__l,$mx__r:$mx__l) {
	margin: {
		left: $mx__l;
		right: $mx__r;
	}
}

@mixin my($mx__t,$mx__b:$mx__t) {
	margin: {
		top: $mx__t;
		bottom: $mx__b;
	}
}

@mixin pos($pt,$pl:$pt,$pp:absolute) {
	top: $pt;
	left: $pl;
	position: $pp;
}

@mixin border($bw,$bc,$bs:solid) {
	border: {
		width: $bw;
		color: $bc;
		style: $bs;
	}
}

@mixin color($color,  $addHover:false, $iconHover: true) {
	color: $color;

	@if ($addHover == true) {
		@if ($color == $color__gold) {
			&:hover {
				color: $color__dark !important;

				@if ($iconHover == true) {
					[class*=__icon] {
						color: $color__graydark;
					}

					&.color-white {
						[class*=__icon] {
							color: $color__gold;
						}
					}
				}
			}
		}
		@if ($color == $color__dark) {
			&:hover {
				color: $color__gold !important;

				@if ($iconHover == true) {
					[class*=__icon] {
						color: $color__gold;
					}
				}
			}
		}
		@if ($color == $color__graydark) {
			&:hover {
				color: $color__gold !important;

				@if ($iconHover == true) {
					[class*=__icon] {
						color: $color__gold;
					}
				}
			}
		}
		@if ($color == $color__graydark) {
			&:hover {
				color: $color__gold !important;

				@if ($iconHover == true) {
					[class*=__icon] {
						color: $color__gold;
					}
				}
			}
		}
		@if ($color == $black) {
			&:hover {
				color: $color__gold !important;

				@if ($iconHover == true) {
					[class*=__icon] {
						color: $color__gold;
					}
				}
			}
		}
		@if ($color == $color__darklight) {
			&:hover {
				color: $color__gold !important;

				@if ($iconHover == true) {
					[class*=__icon] {
						color: $color__gold;
					}
				}
			}
		}
	}
}

@mixin bg($bgs,$bgp:0 0) {
	background: {
		size: $bgs;
		position: $bgp;
		repeat: no-repeat;
		color: rgba(0, 0, 0, 0);
	}
}

@mixin grad($bg__top,$bg__bottom) {
	background: $bg__top;
	background: -moz-linear-gradient(top, $bg__top 0%, $bg__bottom 100%);
	background: -webkit-linear-gradient(top, $bg__top 0%, $bg__bottom 100%);
	background: linear-gradient(to bottom, $bg__top 0%, $bg__bottom 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bg__top', endColorstr='$bg__bottom', GradientType=0);
}

@mixin font($fs,$fw) {
	font: {
		size: $fs;
		weight: $fw;
	}
}

@mixin fs-lh($fs,$lh) {
	font-size: $fs;
	line-height: $lh;
}

@mixin fs($fs) {
	font-size: $fs;
}

@mixin lh($lh) {
	line-height: $lh;
}

@mixin text-big {
	@include fs(1.5625rem);
}

@mixin text-normal {
	@include fs(1.125rem);
}

@mixin text-small {
	@include fs(1rem);
}

@mixin text-smallest {
	@include fs(.875rem);
}

@mixin display-text-big {
	@include text-big;
	@include lh(1.875rem);
}

@mixin display-text-normal {
	@include text-normal;
	@include lh(1.375rem);
}

@mixin display-text-small {
	@include text-small;
	@include lh(1.375rem);
}

@mixin display-text-smallest {
	@include text-smallest;
	@include lh(1rem);
}

/*

@mixin h2 {
  @include fs-lh(1.5rem, 1.75rem);
}

@mixin h3 {
  @include fs-lh(1.125rem, 1.5rem);
}

@mixin h4 {
  @include fs-lh(1rem, 1.125rem);
}

@mixin h5 {
  @include fs-lh(.875rem, 1.375rem);
}

@mixin h6 {
  @include fs-lh(.75rem, 1);
}

@mixin text-biggest { //h1
  @include fs-lh(2.25rem, 2.5rem)
}

@mixin text-big { //h2
  @include fs(1.5rem);
}

@mixin text-normal { //h3
  @include fs(1.125rem);
}

@mixin text-medium { //h4
  @include fs(1rem);
}

@mixin text-small { //h5
  @include fs(.875rem);
}

@mixin text-smallest { //h6
  @include fs(.75rem);
}

@mixin display-text-biggest { //h1
  @include text-biggest;
  @include lh(2.5rem);
  font-weight: bold;
}

@mixin display-text-big { //h2
  @include text-big;
  @include lh(2rem);
  font-weight: bold;
}

@mixin display-text-normal { //h3
  @include text-normal;
  @include lh(1.375rem);
}

@mixin display-text-medium { //h4
  @include text-medium;
  @include lh(1.25rem);
}

@mixin display-text-small { //h5
  @include text-small;
  @include lh(1.375rem);
}

@mixin display-text-smallest { //h6
  @include text-smallest;
  @include lh(1rem);
}

@mixin zi-1 {
  position: relative;
  z-index: 1;
}

@mixin zi-2 {
  position: relative;
  z-index: 2;
}
*/