.page-dark-block {
	position: relative;
	margin: 40px 0;
	overflow: hidden;
	background-color: $color__darklight;
	opacity: 0.9;

	@include media-breakpoint-down(xxl) {
		margin: 32px 0;
		padding: 32px;
		border: 2px solid $color__gold;
		box-shadow: 0 8px 27px rgba(0, 0, 0, 0.1);
	}

	@include media-breakpoint-down(sm) {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		padding: 24px 24px;
	}

	.page-dark-block__info {
		max-width: 100%;
		padding: 48px 200px 48px 66px;
		color: $white;

		@include media-breakpoint-down(xxl) {
			padding: 0;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 16px;
		}
	}

	.page-dark-block__image {
		position: absolute;
		right: 0;
		bottom: 48px;
		width: 248px;
		transform: translateX(25%);

		@include media-breakpoint-down(xxl) {
			display: none;
		}

		@include media-breakpoint-down(sm) {
			position: unset;
			display: block;
			width: 246px;
			height: 100%;
			transform: none;
		}

		.page-dark-block__image_wide {
			bottom: 0;
			z-index: -1;
			width: auto;
			height: 288px;
			transform: none;

			@media (width <= 1700px) {
				display: none;
			}
		}
	}
}
