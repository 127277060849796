.logo__link {
	@include color($black);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	max-width: 10.375rem;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		flex-direction: row;
	}

	&:hover {
		.logo__icon {
			@include color($color__gold);
		}

		.logo__text {
			@include color($color__darklight);
		}
	}
}

.logo__icon {
	/* width: 3.625rem;
	height: 4.125rem; */
	width: 62px;
	height: 52px;
	image-rendering: -webkit-optimize-contrast;

	/*
	height: 3.325rem;
	max-width: 100%;
	 */

	transition: $transition-base;

	/*@include media-breakpoint-down(xl) {
		width: 2.75rem;
		height: 2.375rem;
	}*/

	@include media-breakpoint-down(lg) {
		width: 3rem;
		height: 2.625rem;
		margin-right: .375rem;
	}
}

.logo__text {
	/* width: 10.375rem;
	height: 1.875rem; */
	width: 153px;
	height: 28px;
	image-rendering: -webkit-optimize-contrast;

	@include media-breakpoint-up(md) {
		margin-top: .5rem;
	}

	@include media-breakpoint-down(lg) {
		width: 8rem;
		height: 1.875rem;
		margin-bottom: -.25rem;
	}
}