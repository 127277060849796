.company-container {
	padding: 0 42px;

	@include media-breakpoint-down(xxl) {
		padding: 0;
	}
}

.company {
	position: relative;
	display: flex;
	align-items: center;
	margin: 104px auto 77px;

	@include media-breakpoint-down(xxl) {
		flex-direction: column;
		align-items: start;
		margin: 48px auto 80px;

		.company_news {
			margin: 0;
		}

		.company__icon {
			display: none;
		}

		.company__subtitle {
			display: none;
		}

		.company__history {
			margin-left: 0;
		}

		.company__requisites {
			margin: 40px 0 0;

			& > img {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		margin: 32px auto 64px;

		.company__requisites {
			margin-top: 24px;

			.company__requisites-link {
				@include text-smallest;
			}
		}

		.company__paragraph {
			&:not(:last-child) {
				margin-bottom: 24px;
			}
		}
	}
}

.company__subtitle {
	@include color($color__darklight);
	margin-bottom: 40px;
	font-weight: 300;
	font-size: 38px;
	line-height: 46px;
}

.company__history {
	max-width: 786px;
}

.company__requisites {
	max-width: 258px;
	margin-left: 137px;
	text-align: center;

	& > img {
		margin-bottom: 24px;
	}
}

.company__paragraph {
	&:not(:last-child) {
		margin-bottom: 32px;
	}
}

.company-banner {
	max-width: unset;
	height: auto;
	max-height: 564px;
	margin: 0;
	padding: 0;
	object-position: center;

	@include media-breakpoint-down(xxl) {
		max-height: 560px;
	}

	@include media-breakpoint-down(xl) {
		width: 100%;
	}
}
