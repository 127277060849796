.order {
	position: relative;
	z-index: 1;

	&.active {
		.order__container {
			display: block;
		}

		.order__arrow {
			transform: rotate(180deg);
		}

		@include media-breakpoint-down(md) {
			.order__header-bottom {
				display: grid;
				grid-template-columns: 1fr 40% 1fr;
			}
		}
	}
}

.order__button {
	position: absolute;
	top: 26px;
	right: 26px;
	background-color: transparent;
	border: 0;
	cursor: pointer;

	@include media-breakpoint-down(md) {
		top: 20px;
		right: 20px;
	}
}

.order__arrow {
	width: 20px;
	height: 13px;
	color: $color__gold;
	transform: rotate(0deg);
	transition: $transition-base;
}

.order__container {
	display: none;
	padding: 32px 32px 36px;
	background-color: $white;
	border: 1px solid $color__gold;

	@include media-breakpoint-down(sm) {
		padding: 12px 8px;
	}
}

.order__list {
	margin-top: 18px;

	@include media-breakpoint-down(md) {
		margin-top: 0;
	}
}

.order__item {
	&:not(:first-child) {
		margin-top: 22px;
	}
}

.order__attribute {
	@include text-small;
	@include color($color__darklight);
	font-weight: 300;

	.order__attribute-list {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 50px 3fr 1fr 1.1fr 0.7fr 0.6fr;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}
