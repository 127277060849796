.category-list {
	margin-left: -1.375rem;
}

.category-list__link {
	@include text-normal;
	@include lh(1.375rem);
	@include color($color__darklight);
	position: relative;
	display: flex;
	padding: 12px 0 12px 1.375rem;
	font-weight: 300;
	text-decoration: none;
	background-color: transparent;
	transition: backgrond-color .3s ease-out, color .3s ease-out, border-color .6s cubic-bezier(0.05, 0.7, 0.6, 1), border-width .6s cubic-bezier(0.04, 0.58, 1, 1.54);
	border-right: 0 solid transparent;
	justify-content: space-between;
	border-radius: $border-radius-xs 0 0 $border-radius-xs;
	/*border-top: 1px solid transparent;*/

	@include media-breakpoint-down(lg) {
		@include text-small;
		@include lh(1.2 !important);
		padding: 10px 0 10px 1rem;
	}

	&:before {
		display: block;
		content: '';
		width: calc(100% + 5px);
		height: 1px;
		background: $white;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.active, &:hover {
		@include color($color__dark !important);
		background-color: #F7F7F7;
		border-right-color: $color__gold_hover;
		border-right-width: 5px;
		/*border-top-color: $white;*/

		.arrow-wrapp {
			margin-right: -5px;

			.arrow-right {
				border-top-color: $color__gold_hover;
				border-left-color: $color__gold_hover;
			}
		}
	}

	&:active {
		background-color: hsla(0, 0%, 90%, .6);
		border-right-color: $color__gold_active;

		.arrow-wrapp {
			.arrow-right {
				border-left-color: $color__gold_active;
				border-top-color: $color__gold_active;
			}
		}
	}

	&.active {
		.arrow-wrapp {
			transform: rotate(90deg);
			padding-right: 0;
		}
	}

	&.category-list__link_dropdown {
		& > span {
			padding-right: 30px;
		}
	}

	.arrow-wrapp {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 100%;
		margin-top: 1px;
		position: absolute;
		right: 0;
		top: 0;
		transition: margin $transition-default-time-smaller $transition-default-effect, border-color $transition-default-time $transition-default-effect, transform $transition-default-time $transition-default-effect;

		@include media-breakpoint-down(lg) {
			width: 46px;
		}

		.arrow-right {
			border-top-color: $color__gold;
			border-left-color: $color__gold;
			transition: $transition-base;
		}

		&:hover {
			.arrow-right {
				border-top-color: $color__darklight;
				border-left-color: $color__darklight;
			}
		}
	}
}

.category-list__link_dropdown {
	&.active {
		& + .category-list__dropdown {
			height: inherit;
			pointer-events: all;
			opacity: 1;
			top: -2px;
			transition: $transition-base;

			.active {
				& + .category-list__dropdown {
					padding-top: .5rem;

					@include media-breakpoint-down(lg) {
						padding-top: .375rem;
					}
				}
			}
		}
	}
}

.category-list__dropdown {
	height: 0;
	opacity: 0;
	pointer-events: none;
	position: relative;
	top: -16px;

	.category-list__link {
		@include text-small;
		@include lh(1.375rem);
		padding: .625rem 0 .625rem 1.375rem;
	}

	.category-list__dropdown {
		.category-list__link {
			padding: .375rem 0 .375rem 1.375rem;

			&:before {
				content: none;
			}

			@include media-breakpoint-down(lg) {
				@include text-smallest;
			}
		}
	}
}