.services-slider {
	position: relative;

	@include media-breakpoint-down(md) {
		.services-slider__viewport {
			width: calc(100% - 160px);
			margin: 0 auto;
			overflow: hidden;
		}
	}
}

.services-slider__button {
	position: absolute;
	top: 62px;
	display: none;
	width: 33px;
	height: 59px;
	padding: 0;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;

	@include media-breakpoint-down(md) {
		display: block;
	}

	* {
		pointer-events: none;
	}

	.services-slider__button_prev {
		left: 24px;
	}

	.services-slider__button_next {
		right: 24px;

		transform: rotate(180deg);
	}
}

.services-slider__pagination {
	display: none;
	justify-content: space-between;
	max-width: 136px;
	margin: 32px auto 0;

	@include media-breakpoint-down(md) {
		display: flex;
	}

	.services-slider__pagination-button {
		width: 16px;
		height: 16px;
		padding: 0;
		background-color: $color__light;
		border: none;
		outline: none;
		cursor: pointer;

		&.active {
			background-color: $color__dark;
		}
	}
}