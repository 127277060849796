.testimonial-slider {
	position: relative;
	margin: 24px 0 0;

	@include media-breakpoint-down(md) {
		.testimonial-slider__viewport {
			width: calc(100% + 16px);
			margin-left: -8px;
			padding: 0 8px;
			overflow: hidden;
		}
	}
}

