.radio {
	position: relative;
	display: block;
	padding-left: 2.5rem;
	cursor: pointer;

	&:hover {
		.radio__icon-wrapper {
			border-color: $color__gold_hover;
		}

		.radio__title {
			@include color($color__dark);
		}

		.radio__icon {
			background-color: hsla(0, 0%, 90%, .6);
		}
	}

	&:active {
		.radio__icon-wrapper {
			border-color: $color__gold_active;
		}

		.radio__icon {
			background-color: hsla(0, 0%, 90%, .8);
		}
	}
}

.radio__title {
	@include display-text-small;
	@include color($color__darklight);
	display: flex;
	align-items: center;
	font-weight: 300;
	transition: $transition-base;

	img {
		display: inline;
		width: auto;
		height: 25px;
		margin-left: 10px;
		vertical-align: middle;
	}
}

.radio__desc {
	@include color($color__darklight);
	max-width: 362px;
	font-weight: 300;
	font-size: 10px;
	line-height: 130%;

	a {
		@include color($color__gray, true);
		text-decoration: underline;
	}
}

.radio__input {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
}

.radio__icon {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	transition: $transition-base;
}

.radio__icon-wrapper {
	position: absolute;
	top: 50%;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	border: 1px solid $color__gold;
	border-radius: 50%;
	transform: translateY(-50%);
	transition: $transition-base;
}

.radio__input:checked {
	& + .radio__icon-wrapper {
		.radio__icon {
			background-color: $color__gold;
		}
	}
}