@import '../catalog-product-card-slider/catalog-product-card-slider.scss';

.detailed-catalog-product-card {
	flex-direction: row;
	column-gap: 1.375rem;
	padding: 1.125rem 1rem 1.375rem 1.25rem;


	&:hover {
		.catalog-product-card-slider__pagination-wrapper {
			opacity: 1;

			.catalog-product-card-slider__pagination-button {
				&:before {
					background-color: hsla(0, 0%, 90%, .6);
				}
			}
		}
	}

	.catalog-product-card-slider__pagination-wrapper {
		bottom: -10px;
	}

	.catalog-product-card__title {
		margin-top: 0;
		font-size: 18px;
		line-height: 115%;
		display: block;
		margin-bottom: .625rem;
	}

	.catalog-product-card__price {
		margin: 0;
		padding: .125rem 1rem .125rem .875rem;
	}

	.card__like-button {
		position: relative;
		top: inherit;
		right: inherit;
	}

	@include media-breakpoint-down(lg) {
		.catalog-product-card-slider__overlay, .catalog-product-card-slider__pagination {
			display: none;
		}
	}
}

.detailed-catalog-product-card_badges {
	position: absolute;
	top: 0;
	left: 1.25rem;
	z-index: 9;

	.badge__text {
		font-size: .875rem;
	}
}

.detailed-catalog-product-card__gallery {
	position: relative;
	flex-shrink: 0;
	width: 230px;
	height: 230px;

	@include media-breakpoint-down(xxl) {
		width: 160px;
		height: 160px;
	}

	@include media-breakpoint-down(xl) {
		width: 230px;
		height: 230px;
	}

	@include media-breakpoint-down(sm) {
		width: 160px;
		height: 160px;
	}
}

.detailed-catalog-product-card__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.detailed-catalog-product-card__description {
	width: 100%;
	max-width: 300px;

}

.detailed-catalog-product-card__list {
	margin: 1.125rem 0 1.25rem;

}

.detailed-catalog-product-card__attribute {
	display: flex;
	align-items: baseline;
	/* justify-content: space-between; */

	&:not(:first-child) {
		margin-top: .5rem;
	}
}

.detailed-catalog-product-card__attribute-title {
	min-width: 70px;
	margin-right: 10px;
	font-weight: 300;
	font-size: 12px;
	line-height: 130%;
}

.detailed-catalog-product-card__attribute-value {
	@include color($color__darklight);
	font-weight: 700;
	font-size: 12px;
	line-height: 130%;

	&:not(:first-child) {
		margin-top: .25rem;
	}

	.detailed-catalog-product-card__attribute-value-wrapper {
		width: 100%;
		max-width: 174px;
	}
}

.detailed-catalog-product-card__price {
	@include display-text-small;
	font-weight: 500;

	&.detailed-catalog-product-card__price_discount {
		@include color($color__graydark);
		margin-left: 1.5rem;
		font-weight: 300;
		text-decoration: line-through;
	}
}

.detailed-catalog-product-card__buttons {
	display: flex;
	flex-flow: wrap;
	margin-top: 1rem;
	position: relative;
	z-index: 2;
	column-gap: 1rem;
	align-items: center;

	.button_md {
		padding: 0 1.75rem;
	}
}

.detailed-catalog-product-card__button-like {
	margin-left: 1.125rem;
	padding: .875rem;
	background-color: transparent;
	border: none;
	cursor: pointer;

	svg {
		transition: $transition-base;
		fill: transparent;
		stroke: $color__gold;

		@include media-breakpoint-down(sm) {
			stroke: $color__gray;

		}

		&.active, &:hover {
			svg {
				fill: $color__gold;
				stroke: $color__gold;
			}
		}

		&:hover {
			svg {
				stroke: $color__gold;
			}
		}
	}
}

.detailed-catalog-product-card__icon {
	@include color($white);
	margin-right: 1.5rem;
	fill: transparent;
}

.detailed-catalog-product-card__icon-like {
	width: 23px;
	height: 20px;
}