.subscribe-status {
    display: none;
    margin: 0 auto;
    padding: 104px 0;
}

.subscribe-status__title {
	@include color($color__gold);
	font-weight: 300;
	font-size: 38px;
	line-height: 46px;
	text-align: center;
}

.subscribe-status__kicker {
	@include color($color__graydark);
	@include display-text-normal;
	margin-top: 34px;
	font-weight: 300;
	text-align: center;
}