.auth {
	position: relative;
	overflow: hidden;
	background-color: #f7f7f7;

	h1 {
		text-align: center;
		margin-right: 0 !important;
		display: block;
	}

	@include media-breakpoint-down(xxl) {
		margin: auto -2.625rem;
	}

	@include media-breakpoint-down(sm) {
		margin: auto -.5rem;
	}
}

.auth__background-image {
	@include color($color__darklight);
	position: absolute;
	bottom: 0;
	left: -318px;
	width: 100%;
	max-width: 72.625rem;
	height: 100%;
	max-height: 580px;
	pointer-events: none;
}

.auth__container {
	z-index: 10;
	max-width: 620px;
	margin: 0 auto;
	padding: 72px 0 120px;

	@include media-breakpoint-down(xxl) {
		padding: 40px 40px 80px;
	}

	@include media-breakpoint-down(sm) {
		padding: 2rem 8px 64px;
	}
}

.auth__title {
	@include color($color__darklight);
	margin-bottom: 2.625rem;
	font-weight: 300;
	font-size: 38px;
	line-height: 46px;
	text-align: center;

	@include media-breakpoint-down(sm) {
		font-size: 25px;
		line-height: 30px;
	}
}

.auth__tabs {
	display: flex;
	justify-content: space-around;
	margin-bottom: 2rem;
	border-bottom: 1px solid #ececec;
}

.auth__tab {
	@include color($black);
	width: 100%;
	max-width: 244px;
	padding: 0 0 14px;
	font-weight: 300;
	font-size: 20px;
	line-height: 130%;
	background-color: transparent;
	border: 0;
	border-bottom: 3px solid transparent;
	cursor: pointer;
	transition: $transition-base, font-weight 0s linear;

	@include media-breakpoint-down(sm) {
		max-width: 124px;
	}

	&.active {
		font-weight: 500;
		font-size: 20px;
		line-height: 127%;
	}

	&.active, &:hover, &:focus {
		@include color($color__gold);
		border-bottom-color: $color__gold;
	}
}

.auth__field {
	&:last-of-type {
		margin-bottom: 2rem;

		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
	}

	.bx-ui-sls-pane {
		top: calc(100% + 6px);
	}
}

.auth__input {
	padding-left: 2rem !important;

	@include media-breakpoint-down(sm) {
		padding-left: 1rem;
	}
}

.auth__link {
	text-underline-offset: 3px;
}

.auth__forgot-link {
	margin-left: 2rem;

	@include media-breakpoint-down(sm) {
		margin-left: 1rem;
	}
}

.auth__submit {
	display: block;
	margin: 2.25rem 0 3rem;
	min-width: 18rem;
	max-width: 50%;

	@include media-breakpoint-down(lg) {
		min-width: inherit;
	}

	@include media-breakpoint-down(lg) {
		min-width: inherit;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		margin: 3.25rem auto 1rem;
	}
}

.auth__sign-up {
	@include color($color__graydark);
	text-align: center;
	text-underline-offset: 3px;
}

.auth__eye {

}

.auth__eye-button {
	position: absolute;
	top: 18px;
	right: 26px;
	width: 20px;
	height: 17px;
	background-color: transparent;
	border: none;

	@include media-breakpoint-up(md) {
		top: 32px;
	}

	&.active {
		.auth__eye-icon {
			display: none;
		}

		.auth__eye-icon_visible {
			display: block;
		}
	}
}

.auth__eye-icon {
	@include color($color__light);
	width: 20px;
	height: 17px;
	cursor: pointer;
	transition: $transition-base;
}

.auth__eye-icon_visible {
	display: none;
}

.auth__checkbox {

	@include media-breakpoint-down(sm) {
		margin-left: 1rem;
	}
}


.auth__checkbox-text {
	text-decoration: none;
}

.auth__buttons-group {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;

	@include media-breakpoint-down(sm) {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		max-width: 485px;
	}
}

.auth__description {
	@include display-text-normal;
	@include color($color__graydark);
	font-weight: 300;
	text-align: center;
}

.auth__recovery-message {
	@include text-normal;
	@include color($color__graydark);
	display: none;
	font-weight: 300;
	text-align: center;

	&.active {
		display: block;
	}

	& > div {
		font-weight: 400;
	}
}