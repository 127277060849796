//***************************************************************************************************
// Устанавливаем начальные значения переменных бутстрапа для сборки самого бутстрапа и верстки
//***************************************************************************************************

//******************************
// * Значения из Figma
//******************************

/*
//H1 32/38
font-family: Museo Sans Cyrl;
font-size: 32px;
font-weight: 400;
line-height: 38px;
letter-spacing: 0em;
text-align: left;

//H2
//styleName: 25/300;
font-family: Museo Sans Cyrl;
font-size: 24px;
font-weight: 400;
line-height: 29px;
letter-spacing: 0em;
text-align: center;

*/

/***** TextStyles *****
//H1 36/110
font-family: 'Noto Sans';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 110%; //identical to box height, or 40px
//H2 24/28
font-family: 'Noto Sans';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px; //identical to box height, or 117%
//H3 18/auto
font-family: 'Noto Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 25px; //identical to box height
//H4 16/18
font-family: 'Noto Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px; //identical to box height, or 112%
//SimpleText 14/22
font-family: 'Noto Sans';
font-style: normal;
font-weight: 400;
@include text-smallest;
line-height: 22px; //identical to box height, or 157%
//SmallText 12/12
font-family: 'Noto Sans';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 12px; //identical to box height, or 100%

/***** ColorStyles *****
//dark
background: #525252;
//gray dark
background: #A1A1A1;
//gray
background: #E1DDDD;
//gray_white
background: #FCFCFC;
//blue_white
background: #EDF5FA;
//white
background: #FFFFFF;
//blue
background: #008BD1;
//gradient_blue
background: radial-gradient(43.38% 65% at 32.57% 40%, #41AFE7 0%, #008BD1 100%);
//green
background: #61BD2B;
//yellow
background: #F3CA2C;
//greenblue
background: #30D3CA;
//gradient_blue_green
background: linear-gradient(94.02deg, #2DC87D 12.4%, #30D3CA 75.91%);

/***** EffectStyles *****
//neomorfizm
background: #C4C4C4;
box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.16);
//shadow
background: #C4C4C4;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
//shadow_small
background: #C4C4C4;
box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.14);
//button_shadow
background: #C4C4C4;
box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.16);
//border_shadow
background: #C4C4C4;
box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);

*/

// Собираем значения
// https://getbootstrap.com/docs/4.6/getting-started/theming/#introduction

// Custom.scss
// Option B: Include parts of Bootstrap
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)

@import "node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here


// ************* ЦВЕТА ИЗ МАКЕТА

$design-layout__color_dark: #000; //dark
$design-layout__color_dark_light: #191919; //dark
$design-layout__color_gray_dark: #666; // gray_dark
$design-layout__color_gray: #333; // gray
$design-layout__color_gray_light: #ccc; // gray_light
$design-layout__color_gray_white: #E5E5E5; // gray_white
$design-layout__color_gold: #D4A67C; // gold
$design-layout__color_gold_light: #F8E5B4; // brown_light
$design-layout__color_border_light: #D9D9D9; // border_light

//Остальные цвета для бутсрапа
$design-layout__color_blue: #5ecaff; //
$design-layout__color_dark_blue: #157fa9; //
$design-layout__color_danger: #c40000;
$design-layout__color_yellow: $design-layout__color_gold_light;
$design-layout__color_green: #96D469;
$design-layout__color_dark_green: #51AD1B;
$design-layout__color_greenblue: #1ba8ad;
/*
$design-layout__color_gray_white: #FCFCFC; // gray_white
$design-layout__color_gray_light: #F3F3F3; // gray_light
$design-layout__color_blue_white: #EDF5FA; // blue_white
$design-layout__color_white: #FFFFFF; // white
$design-layout__color_blue: #008BD1; // blue
$design-layout__color_dark_blue: #0074B9; //dark_blue
$design-layout__color_gradient_blue: radial-gradient(43.38% 65% at 32.57% 40%, #41AFE7 0%, #008BD1 100%); // gradient_blue
$design-layout__color_green: #61BD2B; // green
$design-layout__color_dark_green: #51AD1B; // green
$design-layout__color_yellow: #F3CA2C; // yellow
$design-layout__color_greenblue: #30D3CA; // greenblue

//доп. цвета
$design-layout__color_danger: #EB5757; // greenblue*/

// ************* СОПОСТАВЛЯЕМ С ЦВЕТАМИ ИЗ ВЕРСТКИ

// Color system

$white: #fff !default;
$gray-100: $design-layout__color_gray_white;
$gray-200: #e9ecef !default;
$gray-300: $design-layout__color_gray;
$gray-400: $design-layout__color_gray_light;
$gray-500: #adb5bd !default;
$gray-600: $design-layout__color_gray;
$gray-700: #495057 !default;
$gray-800: $design-layout__color_gray-dark;
$gray-900: $design-layout__color_dark;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
				(
						"100": $gray-100,
						"200": $gray-200,
						"300": $gray-300,
						"400": $gray-400,
						"500": $gray-500,
						"600": $gray-600,
						"700": $gray-700,
						"800": $gray-800,
						"900": $gray-900
				),
				$grays
);

$blue: $design-layout__color_blue;
$dark_blue: $design-layout__color_dark_blue;
$indigo: $dark_blue;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: $design-layout__color_danger;
$orange: #fd7e14 !default;
$yellow: $design-layout__color_yellow;
$green: $design-layout__color_green;
$dark_green: $design-layout__color_dark_green;
$teal: $design-layout__color_greenblue;
$cyan: #17a2b8 !default;

$colors: () !default;
$colors: map-merge(
				(
						"blue": $design-layout__color_blue,
						"indigo": $indigo, //default
						"purple": $purple, //default
						"pink": $pink, //default
						"red": $design-layout__color_danger,
						"orange": $orange, //default
						"yellow": $design-layout__color_yellow,
						"green": $design-layout__color_green,
						"teal": $design-layout__color_greenblue,
						"cyan": $cyan, //default
						"white": $white,
						"gray": $design-layout__color_gray,
						"gray-dark": $design-layout__color_gray-dark
				),
				$colors
);

$primary: $design-layout__color_blue;
$secondary: $design-layout__color_green;
$success: $design-layout__color_green;
$info: $design-layout__color_greenblue;
$warning: $design-layout__color_yellow;
$danger: $design-layout__color_danger;
$light: $design-layout__color_gray_white;
$dark: $design-layout__color_dark;

$theme-colors: () !default;
$theme-colors: map-merge(
				(
						"primary": $design-layout__color_blue,
						"secondary": $design-layout__color_green,
						"success": $design-layout__color_green,
						"info": $design-layout__color_greenblue,
						"warning": $design-layout__color_yellow,
						"danger": $design-layout__color_danger,
						"light": $design-layout__color_gray_white,
						"dark": $design-layout__color_dark
				),
				$theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
				("<", "%3c"),
				(">", "%3e"),
				("#", "%23"),
				("(", "%28"),
				(")", "%29"),
) !default;


$tooltip-bg: $white;
$tooltip-color: $design-layout__color_blue;
$tooltip-opacity: 1;

$border-color: $design-layout__color_gray;

////// ОБЩИЕ  НАСТРОЙКИ

// Options
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: true;
$enable-print-styles: false;
$enable-responsive-font-sizes: true;

// Body
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $design-layout__color_dark;

// Links
// Style anchor elements.
$link-color: $design-layout__color_dark;
$link-decoration: none;
$link-hover-color: $design-layout__color_blue;
$link-hover-decoration: none;

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: .5rem !default;
$grid-row-columns: 6 !default;

// Components
// Define common padding and border radius sizes and more.

$border-color: $design-layout__color_gray;

$border-radius-xs: .12555rem;
$border-radius-sm: .25555rem;
$border-radius: .55555rem;
$border-radius-lg: .75555rem;
$border-radius-xl: .88888rem;
$border-radius-pill: 50%;

$rounded-pill: $border-radius-lg;

$box-shadow-lighter: 1px 1px 8px -1px rgba(0, 0, 0, .12);
$box-shadow-sm: 1px 1px 3px 0 rgba(0, 0, 0, .14);
$box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, .16);
$box-shadow-card: 0 4px 10px 0 rgba(0, 0, 0, .12);
$box-shadow-lg: 0 8px 20px 0 rgba(0, 0, 0, .2);

$btn-box-shadow: 3px 4px 8px -1px rgba(0, 0, 0, .18);

$transition-default-effect: ease-out;
$transition-default-time-smallest: .1s;
$transition-default-time-smaller: .2s;
$transition-default-time: .3s;
$transition-default-time-bigger: .5s;

$transition-base: all $transition-default-time $transition-default-effect; //all .3s ease-out
$transition-slow: all .5s ease-in; //all .3s ease

$font-family-sans-serif: 'Noto Sans', Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

$link-color: $gray-900;
$link-decoration: none;
$link-hover-color: $blue;
$link-hover-decoration: none;

$font-size-base: .875rem;
$line-height-base: 1.5 !default;

$enable-negative-margins: true;
$enable-rounded: true !default;

$component-active-color: $design-layout__color_blue;
$component-active-bg: $white;

$close-font-size: $font-size-base;
$enable-gradients: false;

// Alerts

$alert-border-radius: $border-radius;
$alert-padding-y: .75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: 600;
$alert-border-width: 1px;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// Breadcrumbs

$prefix: '';
$breadcrumb-font-size: .75rem;

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: .375rem;
$breadcrumb-item-padding-x: $breadcrumb-item-padding;

$breadcrumb-margin-bottom: .75rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $design-layout__color_dark;
$breadcrumb-active-color: $design-layout__color_dark;
$breadcrumb-divider: quote("/");

$breadcrumb-border-radius: none;

// Navs

$nav-link-padding-y: .5rem;
$nav-link-padding-x: .5rem;
$nav-link-disabled-color: $design-layout__color_gray-dark !important;

$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-color: $design-layout__color_blue !important;
$nav-tabs-link-active-bg: $white !important;
$nav-tabs-link-active-border-color: transparent;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $design-layout__color_blue !important;

$nav-divider-color: $border-color;
//$nav-divider-margin-y: $spacer * .5 !default;

$btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M9.94421 8.88866L17.7221 1.11078L16.7793 0.167969L9.0014 7.94585L1.23283 0.177277L0.290018 1.12009L8.05859 8.88866L0.0585938 16.8887L1.0014 17.8315L9.0014 9.83147L16.9992 17.8292L17.942 16.8864L9.94421 8.88866Z' fill='%23000000'/%3E%3C/svg%3E");

// Toasts
$toast-max-width: 350px;
$toast-padding-x: .375rem;
$toast-padding-y: .375rem;
$toast-font-size: 1rem;
$toast-color: $white;
$toast-background-color: $design-layout__color_dark_light;
$toast-border-width: 0;
$toast-border-color: transparent;
$toast-border-radius: .375rem;
$toast-box-shadow: none;


// Tooltips

$tooltip-max-width: 340px;
$tooltip-border-radius: $border-radius-sm;
$tooltip-padding-x: .5rem;
$tooltip-padding-y: .125rem;
$tooltip-margin: 0;
$tooltip-bg: $white;

// Modal
$modal-header-padding: 0;
$modal-content-inner-border-radius: 0;
$modal-content-border-width: 0;
$modal-content-box-shadow-xs: $box-shadow;
$modal-content-box-shadow-sm-up: $box-shadow;
$modal-dialog-margin-y-sm-up: 3rem;
$modal-backdrop-bg: rgba(82, 82, 82, .2);
$modal-backdrop-opacity: 1;
$modal-inner-padding: 0;

$zindex-modal: 1090;
$zindex-modal-backdrop: 1089;

$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 640px;
$modal-xl: 1140px;

// Types
$mark-bg: #fffada;
$mark-color: #323232;
$mark-padding: 0;

// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-dark-mode: false !default;
$color-mode-type: data !default; // `data` or `media-query`

// Prefix for :root CSS variables

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;


// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y: 1rem !default;
$accordion-padding-x: 1.25rem !default;
$accordion-color: $design-layout__color_gray;
$accordion-bg: $white;
$accordion-border-width: 1px;
$accordion-border-color: $design-layout__color_gray;
$accordion-border-radius: 0;
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width) !default;

$accordion-body-padding-y: $accordion-padding-y !default;
$accordion-body-padding-x: $accordion-padding-x !default;

$accordion-transition: $transition-base;
$accordion-button-active-bg: $black !default;
$accordion-button-active-color: #191919;

$accordion-button-focus-border-color: $black;
$accordion-button-focus-box-shadow: none;

$accordion-icon-width: 1.25rem !default;
$accordion-icon-color: $design-layout__color_gray;
$accordion-icon-active-color: $black;
$accordion-icon-transition: transform .2s ease-in-out !default;
$accordion-icon-transform: rotate(-180deg) !default;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

$accordion-button-padding-y: $accordion-padding-y !default;
$accordion-button-padding-x: $accordion-padding-x !default;
$accordion-button-color: $white;
$accordion-button-bg: $white;
$accordion-transition: $transition-base;
$accordion-button-active-bg: $white;
$accordion-button-active-color: $design-layout__color_gray;

$accordion-button-focus-border-color: $input-focus-border-color !default;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow !default;


// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-dark-mode: false !default;
$color-mode-type: data !default; // `data` or `media-query`

// Prefix for :root CSS variables

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

//Modal

$modal-content-bg: $white;
$modal-backdrop-bg: rgba(51, 51, 51);
$modal-backdrop-opacity: .7;
$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius-lg;