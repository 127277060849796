.modal-form {
	position: relative;
	width: 100%;
	max-width: 561px;
	height: max-content;
	margin: 42px auto 0;

	@include media-breakpoint-down(lg) {
		padding: 20px;
	}

	@include media-breakpoint-down(sm) {
		margin: 0;
		padding: 0;

		.modal-form__input {
			padding: 9px 16px 16px;
		}

		.modal-form__button {
			display: block;
			width: 100%;
			max-width: unset;
			margin: 33px auto;
		}
	}

	.modal-form__success {
		max-width: 481px;
		text-align: center;
	}

	.modal-form__title {
		@include color($black);
		font-weight: 500;
		font-size: 25px;
		line-height: 30px;
		text-align: center;
	}

	.modal-form__text {
		@include display-text-normal;
		@include color($color__graydark);
		margin-top: 2rem;
		font-weight: 300;
	}

	.modal-form__form {
		height: calc(100% - 100px);
		margin-top: 56px;
		overflow: auto;

		@include media-breakpoint-down(sm) {
			margin-top: 40px;
			overflow: initial;
		}
	}

	.modal-form__field {
		@include media-breakpoint-down(sm) {
			&:not(:first-child) {
				margin-top: 0;
			}

			.modal-form__field_indent:nth-child(n) {
				margin: 36px 0 32px;
			}

			.modal-form__field_file-upload {
				display: flex;
				flex-direction: column-reverse;

				.button {
					justify-content: center;
					width: 100%;
				}

				&.modal-form__field_indent {
					margin: 8px 0;
				}

				& + .modal-form__button {
					margin-top: 0;
				}
			}
		}

		&:not(:first-child) {
			margin-top: 0;
		}

		&_indent:nth-child(n) {
			margin: 44px 0 32px;
		}
	}

	.modal-form__button {
		display: block;
		max-width: 160px;
		margin: 68px auto 0;
	}

	.modal-form__upload-button {
		display: flex;
		align-items: center;

		svg {
			flex-shrink: 0;
			margin-right: 8px;
		}
	}

	.modal-form__image {
		position: relative;

		@include media-breakpoint-down(sm) {
			flex-shrink: 0;
			width: 170px;
		}

		&::before {
			display: block;
			padding-bottom: 100%;
			content: "";
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		button {
			position: absolute;
			top: .25rem;
			right: .25rem;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 1.125rem;
			height: 1.125rem;
			background-color: $color__gold;
			border: 0;
			cursor: pointer;

			svg {
				flex-shrink: 0;
				width: 10px;
				height: 10px;
				color: $white;
				pointer-events: none;
			}
		}
	}
}
