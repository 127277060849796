.page-sidebar-wrapp {
	display: flex;
	justify-content: space-between;
}

.page-sidebar-user {
	display: flex;
	gap: 1.125rem;
	margin-bottom: 1.5rem;

	@include media-breakpoint-down(lg) {
		gap: 16px;
	}
}

.page-sidebar-user__photo {
	position: relative;
	width: 78px;
	height: 78px;

	@include media-breakpoint-down(lg) {
		width: 46px;
		height: 46px;
	}
}

.page-sidebar-user__photo-container {
	position: absolute;
	z-index: 8;
	width: 100%;
	height: 100%;
}

.page-sidebar-user__photo img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 50%;
}

.page-sidebar-user__info {
	display: flex;
	flex-direction: column;
	gap: 6px;
	justify-content: center;
}

.page-sidebar-user-name {
	font-size: 22px;
	line-height: 1;

	@include media-breakpoint-down(lg) {
		font-size: 20px;
	}
}

.page-sidebar-user-city {
	@include text-small;
	font-weight: 100;
	line-height: 1;

	@include media-breakpoint-down(lg) {
		font-size: 12px;
	}
}


