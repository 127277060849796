.content {
	position: relative;
	padding: 72px 116px 72px 144px;
	overflow: hidden;
	background-color: #f7f7f7;

	@include media-breakpoint-down(lg) {
		margin: 0 -2.625rem !important;
		padding-left: 2.625rem !important;
		padding-right: 2.625rem !important;
	}

	@include media-breakpoint-down(md) {
		margin: 0 -1rem;
		padding: 40px 1rem ;
	}
}

.content__title {
	@include color($color__darklight);
	font-weight: 500;
	font-size: 25px;
	line-height: 130%;
	text-transform: uppercase;

	@include media-breakpoint-down(lg) {
		&.content__title_order {
			display: none;
		}
	}

	@include media-breakpoint-down(md) {
		@include color($color__darklight);
		margin: 32px 0;
		font-weight: 400;
		font-size: 20px;
		line-height: 127%;
		text-align: center;

		&.content__title_profile {
			margin-top: 0;
		}
	}

	@include media-breakpoint-down(md) {

		&.content__title_align_center {
			text-align: center;
		}
	}
}

.content__bg-image {
	@include color($color__darklight);
	position: absolute;
	bottom: 0;
	left: -320px;
	width: 650px;
	height: 560px;

	@include media-breakpoint-down(md) {
		right: -200px;
		left: unset;
		display: block;
		width: 400px;
		height: 340px;
	}
}

.content__subtitle {
	@include color($color__darklight);
	margin-bottom: 32px;
	font-weight: 300;
	font-size: 38px;
	line-height: 46px;
	text-transform: uppercase;

	&:not(:first-child) {
		margin-top: 64px;
	}

	@include media-breakpoint-down(md) {
		font-size: 25px;
		line-height: 30px;

		&:not(:first-child) {
			margin-top: 40px;
		}
	}
}

.content__paragraph {
	font-size: 20px;

	&:first-of-type {
		margin-top: 32px;
	}

	@include media-breakpoint-down(md) {
		font-size: 14px;
	}
}



