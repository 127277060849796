.exchange-page-banner {
	@include color($white !important);
	position: relative;
	z-index: 5;
	margin: 0 -152px;
	padding: 72px 0 114px 154px;

	@include media-breakpoint-down(xxl) {
		margin: 0 -80px;
		padding: 72px 0 114px 80px;
	}

	@include media-breakpoint-down(md) {
		margin: 0 -8px;
	}

	@include media-breakpoint-down(sm) {
		display: flex;
		flex-flow: column nowrap;
		margin: 0;
		padding: 24px 24px 0;
		background-color: $color__darklight;
		border: 1px solid $color__gold;
		opacity: 0.9;

		.exchange-page-banner__logo-container {
			position: relative;
			display: flex;
			justify-content: center;
			height: 100%;
			margin-top: 38px;
		}
	}
}

.exchange-page-banner__title {
	@include color($white);
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;

	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 127%;
	}
}

.exchange-page-banner__advantages {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	margin-top: 32px;
}

.exchange-page-banner__advantage {
	display: grid;
	grid-gap: 16px;
	grid-template-columns: 24px 1fr;
	width: 100%;

	&:not(:last-child) {
		margin-bottom: 16px;
	}
}

.exchange-page-banner__advantage-icon {
	width: 24px;
	height: 24px;
	margin-right: 16px;
	color: $color__gold;
}

.exchange-page-banner__advantage-text {
	@include display-text-small;
	max-width: 430px;
	font-weight: 300;

	@include media-breakpoint-down(sm) {
		@include text-smallest;
	}
}

.exchange-page-banner__background {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	max-height: 406px;
	object-fit: cover;
	filter: brightness(0.5);

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.exchange-page-banner__background-logo {
	position: absolute;
	bottom: -33px;
	display: none;
	height: 265px;
	color: $color__gold;

	@include media-breakpoint-down(sm) {
		position: static;
		display: block;
		width: 100%;
		height: 240px;
	}
}