.mobile-pane {

}

.mobile-pane__container {
	padding-left: 2.625rem;
	padding-right: 2.125rem;
	position: relative;

	@include media-breakpoint-down(sm) {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	&.payment__retry__payment_select {
		padding-left: 0;
		padding-right: 0;
		margin-right: -.5rem;

		.nice-select-dropdown {
			position: static;
			opacity: 1;
			pointer-events: all;
			margin: 0 !important;
			max-width: inherit !important;

			.list {
				padding-top: 0 !important;
				padding-bottom: .75rem !important;

				.option {
					padding-left: 2.625rem !important;
					padding-right: 2.125rem !important;

					@include media-breakpoint-down(sm) {
						padding-left: 1.25rem !important;
						padding-right: 1.25rem !important;
					}

					position: relative;
					border-right: 0 solid transparent;
					transition: background-color .3s ease-out, color .3s ease-out, border-color .6s cubic-bezier(.05, .7, .6, 1), border-width .6s cubic-bezier(.04, .58, 1, 1.54);

					&.active {
						border-width: 5px;
						border-right-color: $color__gold;
					}
				}
			}
		}

		.modal-buttons {
			padding-left: 2.625rem;
			padding-right: 2.125rem;

			@include media-breakpoint-down(sm) {
				padding-left: 1.25rem;
				padding-right: 1.25rem;
			}

			.button {
				width: 100%;
			}
		}
	}

	.modal-content {
		margin: 0;
		padding: 0;
	}

	.modal-body {
		margin-top: 0 !important;

		&.modal-gallery-card {
			margin-top: -.5rem !important;
		}
	}

	.modal-content-offers {
		overflow: visible;
	}

	.tags__filter_content {
		flex-direction: column;
		gap: 0;
		padding: 0;

		.container {
			padding: 0;

			& > .d-flex {
				gap: .5rem;
				flex-wrap: wrap;
			}
		}
	}

	/*&:before {
		box-shadow: 0 0 10px 5px #fff;
		content: '';
		display: block;
		height: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;
	}

	&:after {
		box-shadow: 0 0 10px 5px #fff;
		content: '';
		display: block;
		height: 0;
		position: absolute;
		bottom: 0;
		width: 100%;
		z-index: 2;
	}*/

	&::-webkit-scrollbar {
		width: .5rem;
	}

	&::-webkit-scrollbar-track {
		height: 100%;
		background: none;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #e3e2de;
		border: none;
		border-radius: 10px;
	}
}


.cupertino-pane-wrapper {
	z-index: 11;

	.modal-title {
		margin-bottom: 2rem;
	}

	.pane {
		max-width: 100% !important;
		margin: .5rem 0 0 !important;
		padding-right: .5rem;
		padding-top: 40px;
		padding-bottom: 40px;
		border-radius: $border-radius-lg $border-radius-lg 0 0;
		box-shadow: 1px -1px 8px 0px rgba(0, 0, 0, .16);

		/*&:before {
			content: '';
			display: block;
			width: 100%;
			position: absolute;
			z-index: 1;
			background-color: $white;
			height: .5rem;
		}

		&:before {
			top: -.5rem;
			box-shadow: 0 3px 4px -1px hsla(0, 0%, 100%, .9), 0 2px 5px hsla(0, 0%, 100%, .8);
		}*/
	}

	.btn-close {
		display: none;
	}

	.destroy-button {
		display: none;

		/*position: absolute;
		top: 30px;
		right: 40px;
		width: 30px;
		height: 30px;
		border: none;
		color: $black;
		background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 23'%3E%3Cpath fill='%23333' d='M2.3 0 23 20.7 20.698 23-.001 2.3z'/%3E%3Cpath fill='%23333' d='M0 20.7 20.699 0l2.3 2.3L2.3 23z'/%3E%3C/svg%3E");
		background-color: transparent;
		transition: $transition-base;
		cursor: pointer;
		border-radius: 0;

		&:hover {
			opacity: .5;
		}

		svg {
			display: none;
		}*/
	}

	/*.backdrop {
		transition: $transition-base;
		background-color: $color__darklight !important;
		opacity: .7 !important;
	}*/

	.draggable {
		.move {
			transition: $transition-base;
		}

		&:hover {
			.move {
				background-color: $color__gray;
			}
		}

		&:active {
			.move {
				background-color: $color__graydark;
			}
		}
	}
}


.pane .destroy-button svg {
	fill: $color__gold;
}