.cart {
	position: relative;
	overflow: hidden;
	transition: $transition-base;
	z-index: 1;

	&.loading {
		cursor: wait;

		&:after {
			content: none !important;
			color: inherit !important;
		}

		.quantity-control__button, .cart-product__close, .cart-form__button, .cart__checkout-link {
			pointer-events: none;
		}
	}
}

.cart__list {
	width: 100%;
}

.cart_wrapper {
	position: relative;
	background: $white;
	opacity: 1;
	z-index: 3;
	top: 0;
	width: 100%;
	transition: $transition-base;

	&.cart_wrapper__old_data {
		position: absolute;
		transition: opacity .5s ease-in;

		&.animating {
			opacity: 0;
		}
	}

	&.cart_wrapper__new_data {
		position: absolute;
		z-index: 2;
		opacity: 0;
		transition: opacity .3s ease-out;

		&.animating {
			height: inherit;
			opacity: 1;
		}
	}
}

.cart__item {
	transition: $transition-base;

	.cart-product {
		border-top: 1px solid rgba(229, 229, 229, .6);
	}

	&:last-child {
		.cart-product {
			border-bottom: 1px solid rgba(229, 229, 229, .6);
		}
	}

	@include media-breakpoint-down(xxl) {
		.cart__item {
			&:last-child {
				border-bottom: none;
			}
		}
	}

	&:before {
		content: '';
		display: block;
		background: rgba(255, 255, 255, .4);
		backdrop-filter: blur(2px);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		z-index: -1;
		pointer-events: none;
		transition: $transition-base;
	}

	&:after {
		position: absolute;
		display: block;
		content: '';
		left: 50%;
		top: 50%;
		width: 0;
		height: 0;
		z-index: -1;
		opacity: 0;
		transition: $transition-base;
	}

	&.loading {
		color: inherit !important;
		pointer-events: none;

		&:before {
			opacity: 1;
			z-index: 1;
		}

		&:after {
			border-width: 5px 0;
			height: 3.625rem;
			left: calc(50% - 1.875rem);
			top: calc(50% - 1.875rem);
			width: 3.625rem;
			border-color: hsla(0, 0%, 90%, .3);
			z-index: 1;
			opacity: 1;
		}
	}
}

.cart__totals {
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
	align-items: start;

	@include media-breakpoint-down(lg) {
		flex-flow: column nowrap;
		align-items: flex-end;
	}

	@include media-breakpoint-down(sm) {
		align-items: center;
	}
}

.cart__totals-sum {
	display: flex;
	align-items: center;

	@include media-breakpoint-down(lg) {
		margin-top: 2rem;
	}

	@include media-breakpoint-down(md) {
		flex-flow: column nowrap;
		width: 100%;
		margin-top: .5rem;
		text-align: center;
	}
}

.cart__price {
	@include color($color__darklight);
	font-weight: 400;
	display: flex;
	flex-direction: column;
	align-items: end;

	@include media-breakpoint-down(md) {
		align-items: center;
	}
}

.cart__price-total {
	@include display-text-big;
}

.cart__price-discount {
	@include color($color__gold);

	font-size: 20px;
	line-height: 100%;
	text-align: right;

	&:empty {
		display: none;
	}
}

.cart__checkout-link {
	height: 53px;
	margin-left: 2rem;
	min-width: 18rem;
	text-transform: uppercase;

	@include media-breakpoint-down(md) {
		width: 100%;
		margin-top: 1rem;
		margin-left: 0;
	}
}

.cart__sale-cities {
	position: relative;
	margin-bottom: 16px;
	padding: 26px 26px 26px 50px;
	background: #f0f0f0;
	border-radius: 9px;

	@include media-breakpoint-down(md) {
		margin: 16px 0 32px;
		padding: 26px 18px 26px 38px;

		svg {
			top: 26px;
			left: 11px;
		}
	}

	.cart__sale-cities-text {
		@include color($black);
		@include display-text-normal;
		font-weight: 300;

		&:first-of-type {
			margin-bottom: .625rem;;
		}
	}

	svg {
		position: absolute;
		top: 26px;
		left: 23px;

		width: 20px;
		height: 20px;
	}
}