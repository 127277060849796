.parallax-title {
	@include color($color__graylight);
	position: absolute;
	top: 58px;
	right: -432px;
	z-index: -5;
	width: 1468px;
	height: 128px;
	opacity: 0.36;

	@include media-breakpoint-down(xxl) {
		display: none;
	}

	&.parallax-title_center {
		top: -116px;
		left: -64px;
	}
}

