.product-prop {
	@include color($color__graydark, true);
    padding: 10px 16px;
    line-height: 1;
    text-decoration: none;
    background-color: rgba(229, 229, 229, 0.6);
    border: 1px solid transparent;
    cursor: pointer;
    transition: $transition-base;
	display: inline-block;
	margin: 0 .5rem .5rem 0;

    &:hover, &:focus {
        background-color: $white;
        border-color: $color__gold;
    }
}

.product-prop_active {
	@include color($color__darklight !important);
	background-color: $white;
	border-color: $color__gold;
	cursor: default;

	&:hover {
		@include color($color__dark !important);
	}
}

.product-prop_incompatible {
	border: 1px dashed $color__gray;
}