.page-warning {
	@include display-text-normal;
	padding: 32px 0;
	font-weight: 500;
	text-align: center;
	border: 1px solid;
	border-color: $color__gold transparent;

	@include media-breakpoint-down(sm) {
		font-weight: 300;
	}
}


.page-warning_with_image {
	position: relative;
	margin-top: 58px;
	padding: 32px 48px;
	text-align: center;
	background-color: $white;
}

.page-warning__icon {
	position: absolute;
	top: 25px;
	left: -79px;
	width: 140px;
	height: auto;
}