.personal-data__wrap {
	display: flex;
	gap: 10px;
	align-items: flex-start;
}

.tooltip_birthday {
	position: relative;
	box-sizing: border-box;
	width: 22px;
	height: 22px;
	margin: 53px 0 0;
	font-size: 15px;
	line-height: 1.5;
	text-align: center;
	background: $white;
	border: 1px solid $color__darklight;
	border-radius: 50%;

	@include media-breakpoint-down(md) {
		width: 20px;
		height: 20px;
		margin: 34px 0;
		@include text-smallest;
		border-radius: 50%;
	}

	.tooltip_text {
		@include color($white);
		position: absolute;
		bottom: 140%;
		left: 0;
		z-index: 1;
		width: 120px;
		padding: 5px;
		font-size: 12px;
		text-align: center;
		background-color: black;
		border-radius: 6px;
		visibility: hidden;
		opacity: 0;
		transition: opacity 1s;

		&::after {
			position: absolute;
			top: 100%;
			left: 10%;
			margin-left: 0;
			border-color: black transparent transparent transparent;
			border-style: solid;
			border-width: 5px;
			content: " ";
		}
	}

	&:hover {
		.tooltip_text {
			visibility: visible;
			opacity: 1;
		}
	}
}
