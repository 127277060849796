.pagination {
	display: inline-grid;
	grid-auto-flow: column;
	grid-gap: .25rem;
	align-items: center;
}

.pagination__item {
	@include text-normal;
	@include color($color__dark);
	text-decoration: none;
	font-size: 1.125rem;
	line-height: 1.75rem;
	padding: .25rem .875rem;
	font-weight: 300;
	border-radius: $border-radius-xs;
	transition: $transition-base;

	&.active {
		background-color: $color__gold !important;

		span {
			@include color($white !important);
		}
	}

	&.active {
		margin: 0 .375rem;

		&:hover {
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .05);
		}
	}

	&:hover {
		background-color: #f7f7f7;

		span {
			@include color($color__dark);
		}
	}

	&:active {
		background-color: hsla(0, 0%, 90%, .6);
		box-shadow: none;
	}
}

.pagination__item_prev, .pagination__item_next {
	@include color($color__gold);
	display: flex;
	align-items: center;
	height: 2rem;
	padding: .125rem .5rem;
	border-radius: $border-radius-sm;

	&:hover {
		@include color($color__gold_hover !important);
	}

	&:active {
		@include color($color__gold_active !important);
	}
}