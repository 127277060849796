.form {
	margin: 0;
}

.form__previews-gallery {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(3, minmax(0, 170px));
	margin-top: 2rem;

	@include media-breakpoint-down(sm) {
		display: flex;
		width: calc(100% + 40px);
		min-height: 170px;
		margin-top: 0;
		margin-bottom: 56px;
		margin-left: -20px;
		padding: 0 20px;
		overflow-x: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.form__fields {
	.form__field {
		margin-top: 1rem;

		&:first-child {
			margin-top: 0;
		}
	}
}

.form__field {
	position: relative;

	&.error {
		.form__input, .form__select {
			border-color: $color__red !important;
		}

		.form__field-message:not([data-chosen-city-error]) {
			display: block;
			position: absolute;
			font-size: 14px;
			top: 2px;
			right: 0;
		}

		.checkbox__box {
			border-color: $color__red;
		}
	}

	label.error {
		position: absolute;
		right: 0;
		font-size: .75rem;
		color: $color__gold;
		bottom: -1.25rem;
	}

	&.active {
		.form__field {
			&::placeholder {
				opacity: 1;
			}
		}

		.form__label_title {
			@include fs(.75rem !important);
			bottom: 2.625rem !important;
			top: initial !important;
			left: 0 !important;
			color: $color__gray;
		}

		.form__textarea {
			& + .form__label_title, & + label + .form__label_title {
				bottom: calc(100% - .75rem) !important;
			}
		}

		[aria-invalid=false] {
			& + .form__label_title {
				@include color($color__gray);
			}
		}

		[aria-invalid=true] {
			& + .form__label_title {
				@include color($color__dark !important);
			}
		}
	}

	&.checked {
		.form__label_title {
			opacity: 0;

			/* @include color($color__gray !important);

			.color-gold {
				font-weight: 400;
			}*/
		}
	}
}

.form__field_coupon {
	@include media-breakpoint-down(lg) {
		.paragraph {
			text-align: right;
		}
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 1.625rem;
		width: 100%;

		.paragraph {
			text-align: center;
		}
	}

	.form__field_coupon__data {
		display: flex;
		gap: 1rem 1.375rem;

		@include media-breakpoint-down(sm) {
			gap: 1rem 1.125rem;
		}
	}

	.form__input {
		max-height: 3.125rem;
	}
}

[name=form_auth] {
	.form__field {
		&.active {
			.form__label_title {
				bottom: 3.125rem !important;
			}
		}

		label.error {
			bottom: -1.375rem;
			color: $color__gray;
			font-size: .75rem;
			left: 0;
			right: inherit !important;
			position: absolute;
		}
	}
}

.form__label {
	margin-bottom: 0;
	cursor: pointer;
	user-select: none;
	transition: $transition-base;

	&.form__label_title {
		@include fs(1rem);
		@include color($color__graydark);
		font-weight: 300;
		position: absolute;
		left: 1.25rem;
		bottom: calc(100% - 2.5rem);

		& > b {
			transition: $transition-base;
		}
	}
}

.form__field_hidden {
	display: none;
}

.form__field_margin-top_null {
	&:not(:first-child) {
		margin-top: 0;
	}
}

.form__field-message {
	@include display-text-small;
	@include color($color__red);
	display: none;
	margin-top: .5rem;
	margin-left: 2rem;
	font-weight: 300;

	@include media-breakpoint-down(sm) {
		margin-left: 1rem;
	}

	&.visible {
		display: block;
	}
}

.form__input, .form__textarea {
	@include display-text-small;
	@include color($color__darklight);
	width: 100%;
	padding: 1rem 1.375rem 1rem 1.25rem !important;
	font-weight: 500;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $color__graylight;
	border-radius: 0;
	outline: none;
	transition: $transition-base;

	@include media-breakpoint-down(sm) {
		padding: 1rem !important;
	}

	&.error {
		border-color: $color__gold !important;
	}

	&:hover {
		border-color: $color__gold;

		&::placeholder {
			@include color($color__graydark);
		}

		&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
			margin: 0;
			-webkit-appearance: none;
		}

		&[type=number] {
			-moz-appearance: textfield;
		}
	}

	&::placeholder {
		@include color($color__graydark);
		position: relative;
		font-weight: 300;
		transition: $transition-base;
	}

	&:focus {
		border-bottom: 1px solid $color__gold;

		/* &::placeholder {
			@include text-smallest;
			@include color($color__light !important);
			top: -1.5rem;
		} */
	}

	&[disabled] {
		@include color($color__muted);
		pointer-events: none;
		border-bottom: 0;
		background: hsla(0, 0%, 90%, .6);
		border-radius: $border-radius-xs;
	}
}

.form__textarea {
	min-height: 54px;
	resize: none;

	& + label.error {
		bottom: -.875rem;
	}
}

.form__input_border_gray, .form__textarea_border_gray {
	border-bottom: 1px solid $design-layout__color_gray_light;
}

.form__input_date {
	@include color($color__graydark);
	@include display-text-small;
	padding: 0 0 1rem 0;
	font-weight: 300;
	text-align: center;
	border-color: $color__darklight;

	&:hover, &:focus {
		@include color($color__gold);
		border-color: $color__gold;

		&::placeholder {
			@include color($color__gold);
		}
	}

	&:disabled {
		opacity: .4;
		border-color: $color__gray;
	}
}

.form__textarea_block {
	display: block;
}

.form__radio {
}

.form__radio-item {
	&:not(:first-child) {
		margin-top: .5rem;
	}
}

.form__text {
	@include text-small;
	@include color($color__dark);
	font-weight: 300;
}

.form__button {
	text-transform: uppercase;
}

.form__link {
	@include display-text-small;
	@include color($color__gold, true);
	font-weight: 300;
	transition: $transition-base;
}

.form__select {
	@include color($color__graydark);
	@include display-text-small;
	width: 100%;
	font-weight: 300;
	text-indent: 1px;
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid #ccc;
	outline: 0;
	transition: $transition-base;

	&:hover, &:focus {
		@include color($color__gold);
		border-color: $color__gold;
	}

	.select__button {
		border: 0;
	}

	&:disabled {
		border-color: $color__gray !important;
		opacity: .4 !important;
	}
}

.form__select_border-light {
	border-color: $color__graylight;
}

.form_button_wrapp {
	button {
		position: relative;
		z-index: 9;

		&.disabled {
			background-color: $color__gold;
			border-color: $color__gold;

			&:hover {
				background-color: #ccc !important;
				border-color: #ccc !important;
			}
		}
	}
}

.form_color_white {
	.form__input, .form__textarea, .form__select {
		@include color($white);
		border-bottom: 1px solid $white;

		@include media-breakpoint-down(lg) {
			@include color($color__darklight);
			border-bottom: 1px solid $color__darklight;
		}

		&::placeholder {
			@include color($white);

			@include media-breakpoint-down(lg) {
				@include color($color__darklight);
			}
		}
	}

	.form__text {
		@include color($white);

		@include media-breakpoint-down(lg) {
			@include color($color__darklight);
		}
	}

	.form__link {
		&:hover {
			@include color($color__gray !important);
		}
	}

	.form__label_title {
		color: $white;

		@include media-breakpoint-down(lg) {
			@include color($color__darklight);
		}
	}

	.contact-form__text {
		color: $white;

		@include media-breakpoint-down(lg) {
			@include color($color__darklight);
			font-weight: 300;
		}
	}

	.form__input, .form__textarea {
		&:focus, &:hover {
			border-bottom: 1px solid $color__gold !important;
		}
	}

	.nice-select {
		.current {
			color: $white;

			@include media-breakpoint-down(lg) {
				@include color($color__darklight);
				font-weight: 300;
			}
		}
	}

	.color-dark {
		color: $white !important;
	}
}

.form__status {
	padding: 72px 48px;
	font-weight: 300;
	text-align: center;
	box-shadow: 0 3px 25px rgba(0, 30, 69, 0.05);
}

.form__status_title {
	@include color($color__gold);
	font-size: 25px;
	line-height: 30px;
}

.form__status_kicker {
	@include display-text-small;
	@include color($color__graydark);
	margin-top: 2rem;

	&.color-white {
		color: $white;
	}
}