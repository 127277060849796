.accent-card {
	position: relative;
	max-width: 100%;
	margin: 40px 0;
	padding: 48px 144px 48px 208px;
	overflow: hidden;
	background-color: $white;
	border: 2px solid $color__gold;
	box-shadow: 0 8px 27px rgba(0, 0, 0, 0.1);

	@include media-breakpoint-down(xxl) {
		margin: 32px auto;
		padding: 32px;

		.accent-card__image {
			display: none;
		}

		.accent-card_align_center {
			text-align: center;
		}
	}

	@include media-breakpoint-down(sm) {
		padding: 24px;

		.accent-card_align_center {
			text-align: center;
		}
	}
}

.accent-card_padding_small {
	padding: 40px 48px;
}

.accent-card_flex {
	display: flex;
	flex-flow: column nowrap;
}

.accent-card__image {
	position: absolute;
	bottom: 48px;
	left: -63px;
	width: 248px;
}

.accent-card-address {
	.accent-card-address__title {
		margin-bottom: 32px;
		font-weight: 500;
		font-size: 25px;
		line-height: 30px;

		@include media-breakpoint-down(sm) {
			font-size: 20px;
			line-height: 127%;
			text-transform: uppercase;
		}
	}

	.accent-card-address__icon {
		position: absolute;
		right: 0;
		bottom: -40px;
		width: 270px;
		height: 370px;

		@include media-breakpoint-down(xxl) {
			width: 246px;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.accent-card-address__logo {
		@include color($color__gold);

		.accent-card-address__logo-wrapper {
			display: none;
			height: 212px;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;

			.accent-card-address__logo-wrapper {
				display: flex;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		@include color($color__darklight);
		display: flex;
		flex-flow: column nowrap;
		padding: 24px 24px 0;
		font-size: 20px;
		line-height: 127%;
	}
}


