.personal-data {
	display: grid;
	grid-gap: 56px;
	grid-template-columns: 138px 1fr;
	margin-top: 20px;

	@include media-breakpoint-down(lg) {
		grid-template-columns: 1fr;
	}

	.dropdown-fade2white {
		background: transparent;
	}

	.bx-sls {
		.dropdown-block {
			padding: 2rem 1.375rem 1rem 2rem !important;
		}

		.bx-ui-sls-fake, .bx-ui-sls-route {
			font-size: 1rem;
		}
	}
}

.personal-data__title {
	margin: 48px 0 0;
}

.personal-data__button {
	margin-top: 4rem;
	min-width: 16rem;

	@include media-breakpoint-down(md) {
		width: 100%;
	}
}

.personal-data__photo {
	position: relative;
	width: 138px;
	height: 138px;
	margin-left: 10px;
	background-color: $white;
	border-radius: 50%;

	@include media-breakpoint-down(md) {
		width: 104px;
		height: 104px;
	}
}

.personal-data__photo-content {
	@include media-breakpoint-down(lg) {
		display: flex;
		align-items: center;
		max-width: 100%;
	}

	@include media-breakpoint-down(md) {
		flex-direction: column;
		justify-content: center;
	}
}

.personal-data__photo-delete {
	position: absolute;
	right: 0;
	z-index: 9;
	display: none;
	width: 1.125rem;
	height: 1.125rem;
	background-color: $color__gold;

	@include media-breakpoint-down(md) {
		display: block;
	}
}

.personal-data__photo-delete-icon {
	@include color($white);
	position: absolute;
	width: 10px;
	height: 10px;
	margin: 4px;
}

.personal-data__photo-main {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.personal-data__photo-container {
	position: absolute;
	z-index: 8;
	width: 100%;
	height: 100%;

	@include media-breakpoint-down(md) {
		pointer-events: none;
	}
}

.personal-data__photo-input {
	display: none;
}

.personal-data__photo-avatar {
	position: absolute;
	width: 64px;
	height: 66px;
	margin: 35px 0 0 36px;
}

.personal-data__photo-controls {
	text-align: center;

	@include media-breakpoint-down(lg) {
		max-width: 138px;
	}
}

.personal-data__photo-control {
	@include display-text-small;
	@include color($color__gold, true);
	font-weight: 300;
	text-align: center;
	text-decoration: underline;
	background-color: transparent;
	border: none;
	text-underline-offset: 3px;
	cursor: pointer;

	&:first-of-type {
		margin: 16px 0 8px;
	}
}

.personal-data__photo-control_color_dark {
	@include color($color__darklight, true);

	@include media-breakpoint-down(md) {
		display: none;
	}
}