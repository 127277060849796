.purchase {
	@media (768px < width <= 1400px) {
		flex-flow: row nowrap;
	}
}

.purchase-cards {
	display: grid;
	grid-gap: 11px;
	grid-template-columns: repeat(2, 1fr);

	@include media-breakpoint-down(xxl) {
		display: flex;
		flex-wrap: wrap;
	}
}

.purchase-cards__text {
	@include color($white);
	width: 360px;
	max-width: 100%;
	margin-bottom: 32px;
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;

	@include media-breakpoint-down(xxl) {
		width: 100%;
		font-size: 20px;
		line-height: 127%;
	}

	@include media-breakpoint-down(sm) {
		@include text-smallest;
		line-height: 130%;
	}
}

.purchase-cards__card {
	width: 100%;
	height: 100%;

	@include media-breakpoint-down(xxl) {
		max-width: 295px;
	}

	@include media-breakpoint-down(sm) {
		max-width: 311px;
	}
}

.purchase__card-image {
	width: 361px;
	height: 203px;
	margin-left: 32px;

	@media (width <= 1700px) {
		margin-top: 32px;
		margin-left: 0;
	}

	@media (768px < width <= 1400px) {
		margin-top: 0;
		margin-left: 32px;
	}
}

.purchase__card {
	display: flex;

	@media (width <= 1700px) {
		flex-flow: column nowrap;
	}

	@media (min-width: 769px) and (max-width: 1400px) {
		flex-flow: row nowrap;
	}
}

