.lending-program {
	position: relative;
	margin: 56px -152px;
	padding: 104px 150px 104px 148px;
	overflow: hidden;
	background-color: $color__darklight;
	opacity: 0.9;

	@media (width <= 1700px) {
		padding: 32px;
	}

	@include media-breakpoint-down(xxl) {
		margin: 32px 0;
		padding: 32px;
	}

	@include media-breakpoint-down(md) {
		margin: 24px 0;
		padding: 24px;
	}
}

.lending-program__icon {
	position: absolute;
	bottom: -26px;
	left: 189px;
	z-index: -1;
	width: 1600px;
	height: 100%;
	max-height: 750px;
	color: #4d4d4d;
	opacity: 0.3;

	@include media-breakpoint-down(xxl) {
		display: none;
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}
