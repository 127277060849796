.accent-card-image {
	align-self: self-end;
	width: 280px;
	height: 94px;
	margin-right: -58px;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.accent-card-image_reverse {
	align-self: flex-start;
	margin-left: -52px;
	transform: scale(-1);
}