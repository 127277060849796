.accent-card-address {
	@include media-breakpoint-down(sm) {
		@include color($color__darklight);
		display: flex;
		flex-flow: column nowrap;
		padding: 24px 24px 0;
		font-size: 20px;
		line-height: 127%;
	}
}

.accent-card-address__title {
	margin-bottom: 32px;
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;

	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 127%;
		text-transform: uppercase;
	}
}

.accent-card-address__icon {
	position: absolute;
	right: 0;
	bottom: -40px;
	width: 270px;
	height: 370px;

	@include media-breakpoint-down(xxl) {
		width: 246px;
	}

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.accent-card-address__logo {
	@include color($color__gold);

	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.accent-card-address__logo-wrapper {
	display: none;
	height: 212px;

	@include media-breakpoint-down(sm) {
		display: flex;
	}
}