.catalog-view {
	display: grid;
	grid-gap: .5rem;
	grid-template-columns: repeat(2, 2.875rem);

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.catalog-view__item {
	display: grid;
	grid-gap: .125rem;
	grid-template-rows: .75rem .75rem;
	grid-template-columns: .75rem .75rem;
	place-content: center;
	width: 2.875rem;
	height: 2.875rem;
	transition: $transition-base;
	border-radius: $border-radius-xs;

	& > i {
		display: block;
		width: 100%;
		height: 100%;
		background-color: $color__graylight;
		transition: $transition-base;
	}

	&.catalog-view__item_horizontal {
		grid-template-rows: .75rem .75rem;
		grid-template-columns: 1.75rem;
	}

	&:hover, &.active {
		background-color: #f5f5f5;

		& > i {
			background-color: $color__dark;
		}
	}

	&:active {
		background-color: hsla(0, 0%, 90%, .88);
	}
}
