.content-banner {
	max-height: 478px;

	@include media-breakpoint-down(lg) {
		width: calc(100% + 5.25rem);
		max-width: 100vw;
		margin: 0 -2.625rem;
	}

	@include media-breakpoint-down(sm) {
		height: 240px;
		margin: 0 -1rem;
		object-fit: cover;
	}
}
