.section {
	margin: 104px 0;

	@include media-breakpoint-down(lg) {
		margin: 80px 0;
	}

	@include media-breakpoint-down(md) {
		margin: 64px 0;
	}

	@include media-breakpoint-down(sm) {
		margin: 40px 0;
	}
}

.section__title {
	@include color($color__dark);
	font-weight: 400;
	font-size: 38px !important;
	line-height: 46px !important;
	text-align: center;
	text-transform: uppercase;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: subpixel-antialiased;

	@include media-breakpoint-down(lg) {
		@include fs-lh(1.625rem !important, 1.875rem !important);
	}

	.section__title_align_left {
		text-align: left;
	}

	& + .catalog__products {
		margin-top: 2.25rem;

		@include media-breakpoint-down(lg) {
			margin-top: 1.5rem;
		}
	}
}

.section__title_wrapp {
	& + .catalog__products {
		margin-top: 2.25rem;

		@include media-breakpoint-down(lg) {
			margin-top: 1.5rem;
		}
	}
}

.section_margin-top_small {
	margin-top: 2rem;

	@include media-breakpoint-down(lg) {
		margin-top: 3rem;
	}

	@include media-breakpoint-down(md) {
		margin-top: 2rem;
	}
}

.section__kicker {
	@include color($color__darklight);
	font-weight: 250;
	font-size: 20px;
	line-height: 130%;
	text-align: center;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: subpixel-antialiased;

	@include media-breakpoint-down(lg) {
		@include display-text-small;
		@include color($color__gray);
		text-align: left;
	}
}