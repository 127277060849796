.testimonials {
	margin-top: 104px;
}

.testimonials__empty {
	display: none;

	@include media-breakpoint-down(md) {
		display: none;

		&.active {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&.active {
		display: block;
	}
}

.testimonials__title {
	text-align: left;

	@include media-breakpoint-down(md) {
		text-align: center;
	}
}

.testimonials__container {
	display: flex;
	justify-content: space-between;
	margin-top: 56px;

	@media (max-width: 1400px) {
		flex-flow: column nowrap;
		margin-top: 40px;
	}

	@include media-breakpoint-down(md) {
		align-items: center;
		margin-top: 24px;
	}
}

.testimonials__list {
	width: 995px;

	@media (max-width: 1400px) {
		order: 1;
		width: 100%;
		margin-top: 64px;
	}
}

.testimonials__rate {
	display: flex;
	flex-flow: row wrap;
	align-self: flex-start;
	justify-content: space-between;
	width: 284px;
	height: min-content;

	@include media-breakpoint-down(md) {
		align-self: center;

	}
}