@import '../../../variables_global.scss';

$font-family_default: $font-family-sans-serif;
$font-family_title: $font-family_default;
$font-size_default: $font-size-base;

$line-height_default: $line-height-base;
$line-height_content: 1.4;

$container__max_width: 1560px;
$container__tight_max_width: 780px;

$breakpoint__custom_xl: 1568px;
$breakpoint__custom_lg: 1366px;
$breakpoint__custom_xs: 375px;

$fonts-path_default: '../fonts' !default;
$museo-font-path: $fonts-path_default;
$icomoon-font-path: $fonts-path_default;
$images-path: '../img';

$color__light_blue: #008bd1bf;
$color__blue: $blue;
$color__dark_blue: $dark_blue;

$color__green: $green;
$color__dark_green: $dark_green;

//$color__lighter: #edf5fa80;
$color__lighter: #F6FAFC;
$color__light: $gray-400;
$color__light2: #008bd11a;
$color__light2_darken: #008bd133;
$color__dark: #191919;
$color__darklight: #333333;
$color__graylight: #e5e5e5;
$color__gray: #b2b2b2;
$color__graydark: #666666;
//$color__gold: #d0a345;
//$color__gold: #d3a67c;
$color__gold: #bba695;
//$color__gold_hover: #d0a345;
$color__gold_hover: #8e7b6c;
//$color__gold_active: #dfa62d;
$color__gold_active: #6f5c55;
$color__info: $design-layout__color_greenblue;
$color__muted: #b2b2bb;
$color__green: $green;
$color__success: $color__green;
$color__warning: $yellow;
$color__red: $red;
$color__danger: $color__red;

$color__border: $gray-300;

$enable-shadows: true;
$enable-responsive-font-sizes: true;

$tooltip-bg: $color__light;
$tooltip-color: $color__blue;
$tooltip-opacity: 1;

:root {
	/* Colors */
	--black: $black;
	--black-light: $color__dark;
	--gray: $color__light;
	--gray-light: #b2b2bb;
	--gray-dark: $color__darklight;
	--white: $white;
	--accent-gold: $color__gold;
	--error: #c40000;

	/* Fonts */
	--font-stack: "Museo Sans Cyrl", Helvetica, Arial, sans-serif;
}
