.loyalty-slider {
	position: relative;

	.loyalty-slider__items {
		display: flex;
	}

	@include media-breakpoint-down(md) {
		.loyalty-slider__viewport {
			width: calc(100% + 16px);
			margin: 0 auto;
			overflow: hidden;
		}
	}
}
