.button {
	@include text-small;
	@include color($white);
	display: flex;
	align-items: center;
	max-height: 3.25rem;
	margin: 0;
	padding: 0 2rem;
	line-height: 50px;
	justify-content: center;
	text-align: center;
	text-decoration: none;
	background-color: $color__gold;
	border: 1px solid $color__gold;
	cursor: pointer;
	border-radius: $border-radius-xs;
	transition: $transition-base, color $transition-default-time-smallest $transition-default-effect !important;
	letter-spacing: .01555em;
	white-space: nowrap;

	&:hover {
		@include color($white !important);
		background-color: $color__gold_hover;
		border-color: $color__gold_hover;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .06);
	}

	&:active {
		@include color($white !important);
		//background-color: $color__gold_active;
		box-shadow: none;
	}

	&.loading {
		position: relative;
		color: transparent !important;
		pointer-events: none;

		&::after {
			border-top-color: rgba(255, 255, 255, .8);
			border-bottom-color: rgba(255, 255, 255, .8);
		}
	}

	&[disabled] {
		@include color($white);
		background-color: $color__light;
		border-color: $color__light;
		cursor: not-allowed;

		&:hover {
			@include color($white);
			background-color: $color__light;
			border-color: $color__light;
		}
	}

	&.button_md {
		@include text-smallest;
		line-height: 44px;
		padding: 0 1.125rem;
	}

	&.button_sm {
		@include text-smallest;
		line-height: 38px;
		padding: 0 1.125rem;
	}
}

.button_transparent {
	@include color($color__gold !important);
	background-color: transparent;
	border: 1px solid $color__muted;

	&:hover {
		background-color: transparent;
		border-color: $color__light !important;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .05);
	}

	&:active {
		background-color: transparent;
		box-shadow: none;
	}
}

.button_transparent_gold {
	@include color($color__gold !important);
	background-color: transparent;
	border: 1px solid $color__gold !important;
	transition: $transition-base, color $transition-default-time-smallest $transition-default-effect !important;

	&:hover {
		@include color($white !important);
		background-color: $color__gold !important;
		border: 1px solid $color__gold !important;
	}

	&:active {
		@include color($white !important);
		background-color: $color__gold_active !important;
		border-color: $color__gold_active !important;
	}
}

.button_transparent_gray {
	@include color($color__gold !important);
	background-color: transparent;
	border: 1px solid #b2b2bb;

	&:hover {
		@include color($color__gold_hover !important);
		background-color: $white !important;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .05);
	}

	&:active {
		@include color($color__gold_active !important);
		box-shadow: none;
		border-color: #aaa !important;
	}
}

.button_gold {
	@include color($color__gold);
	background-color: transparent;
	border: 1px solid $color__gold;
	transition: $transition-base, color $transition-default-time-smallest $transition-default-effect !important;

	&:hover {
		@include color($white);
		background-color: $color__gold_hover;
		border-color: $color__gold_hover;
	}

	&:active {
		@include color($white);
		background-color: $color__gold_active !important;
		border-color: $color__gold_active !important;
	}
}

.button_white {
	@include color($white);
	padding: 16px 40px;
	text-align: center;
	text-decoration: none;
	background-color: transparent;
	border: 1px solid $white;
	transition: $transition-base;

	&:hover {
		background-color: $color__darklight;
		border: 1px solid $color__darklight;
	}
}

.button_link {
	padding: 0;
	text-decoration: underline;
	text-underline-offset: 2px;
	background-color: transparent;
	border: 0;
}

.button_buy {
	&:after {
		content: 'Купить';
	}

	.check_icon {
		display: none;
	}

	&.active {
		&:after {
			content: 'В корзине';
		}

		.basket_icon {
			display: none;
		}

		.check_icon {
			display: block;
		}
	}

	&.loading {
		svg {
			opacity: 0;
		}
	}

	&.button_md {
		min-width: 142px;
		transition: $transition-base, padding 0s linear !important;

		&.active {
			padding: 0 1.125rem;
		}

		.check_icon {
			margin-right: .25rem;
		}
	}
}

.button_with-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.button_with-icon_left {
		svg {
			margin-right: .625rem;
			position: relative;
			top: -1px;
		}
	}

	&.button_with-icon_right {
		svg {
			margin-left: .75rem;
			position: relative;
			top: -1px;
		}
	}

	svg {
		path {
			transition: none;
		}
	}
}

.button_color-white {
	@include color($white);
}

.button_color-accent {
	@include color($color__gold);
}

.button_color-gold {
	@include color($color__gold);
}

.button_background-transparent {
	background-color: transparent;
}

.button_border-accent {
	border-color: $color__gold;
}

.button_border-black {
	border-color: $color__dark;
}

.button_border-white {
	border-color: $white;
}

.button_uppercase {
	text-transform: uppercase;
}

.button_gray {
	@include color($color__dark);
	background-color: $color__light;
	border-color: $color__light;
}

.button_gray__share {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.button_larr {
	position: relative;
	top: -1px;
	font-weight: 600;
}