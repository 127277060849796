.rating {

}

.rating_input {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	margin-left: 40px;

	@include media-breakpoint-down(sm) {
		margin-left: 16px;
	}
}

.rating__caption {
	@include display-text-small;
	@include color($color__graydark);
	margin-right: 1rem;
	font-weight: 300;
}

.rating__input {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);

	&:checked + .rating__label,
	&:hover + .rating__label,
	&:focus + .rating__label {
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 0L13.4697 7.60081H21.4616L14.996 12.2984L17.4656 19.8992L11 15.2016L4.53436 19.8992L7.00402 12.2984L0.538379 7.60081H8.53035L11 0Z' fill='%23666666'/%3E%3C/svg%3E%0A");
	}
}

.rating__stars {
	display: flex;

	margin-top: 8px;
	margin-right: 66px;

	.rating__stars_clear-margin {
		margin: 0;
	}

	.rating__stars_input {
		position: relative;
		width: 142px;
		height: 22px;
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 0L13.4697 7.60081H21.4616L14.996 12.2984L17.4656 19.8992L11 15.2016L4.53436 19.8992L7.00402 12.2984L0.538379 7.60081H8.53035L11 0Z' fill='%23cccccc'/%3E%3C/svg%3E%0A");
		background-size: 30px 22px;
	}
}

.rating__label {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	background-size: 30px 22px;

	&:nth-of-type(1) {
		z-index: 5;
		width: 22px;
		height: 22px;
	}

	&:nth-of-type(2) {
		z-index: 4;
		width: 52px;
		height: 22px;
	}

	&:nth-of-type(3) {
		z-index: 3;
		width: 82px;
		height: 22px;
	}

	&:nth-of-type(4) {
		z-index: 2;
		width: 112px;
		height: 22px;
	}

	&:nth-of-type(5) {
		z-index: 1;
		width: 142px;
		height: 22px;
	}
}

.rating__star {
	width: 22px;
	height: 20px;
	color: $color__light;

	&_color_black {
		color: $color__graydark;
	}

	&:not(:last-child) {
		margin-right: 8px;
	}

	.rating__star_small {
		width: 15px;
		height: 15px;

		&:not(:last-child) {
			margin-right: 4px;
		}
	}
}

.rating__number {
	margin-bottom: 16px;
	color: $color__darklight;
	font-weight: 300;
	font-size: 32px;
	line-height: 38px;

	.rating__number_disabled {
		color: $color__light;
	}
}

.rating__line {
	display: flex;
	align-items: center;
	color: $color__darklight;
	font-weight: 300;
	@include text-smallest;
	line-height: 21px;

	&:not(:last-child) {
		margin-bottom: 8px;
	}
}

.rating__title {
	width: 57px;
}

.rating__bar {
	display: block;
	width: 180px;
	height: 5px;
	margin: 0 16px;
	padding: 0;
	background-color: rgba(229, 229, 229, 0.392157);
	border: 0;
	cursor: pointer;

	&_filled {
		margin: 0;
		background-color: $color__gold;
	}
}

.rating__button {
	@include display-text-small;
	margin: 1.5rem auto 0;
	padding: 1rem 2rem;
	color: $white;
	font-weight: 400;
	text-transform: uppercase;
	background-color: $color__gold;
}