.thankyou {
	position: relative;
	padding: 172px 0 182px;

	@include media-breakpoint-down(lg) {
		padding: 56px 0 172px;
	}

	@include media-breakpoint-down(md) {
		padding: 97px 0;
		background-color: rgba(0, 0, 0, 0.4);
	}

	& + .section {
		display: none;
	}
}

.thankyou__wrapper {
	max-width: 550px;
	text-align: center;

	@include media-breakpoint-down(lg) {
		text-align: left;
	}

	@include media-breakpoint-down(md) {
		text-align: center;

		.button {
			width: 100%;

			svg {
				display: none;
			}
		}
	}
}

.thankyou__title {
	@include color($color__darklight);
	margin: 0 0 4px;
	font-weight: 400;
	font-size: 64px;
	line-height: 130%;

	@include media-breakpoint-down(lg) {
		@include color($color__darklight);
		font-weight: 300;
		font-size: 38px;
		line-height: 46px;
	}

	@include media-breakpoint-down(md) {
		@include color($white);
		margin-bottom: 32px;
		font-size: 25px;
		line-height: 30px;
	}
}

.thankyou__description {
	@include color($color__darklight);
	margin-bottom: 40px;
	font-weight: 400;
	font-size: 25px;
	line-height: 30px;

	@include media-breakpoint-down(lg) {
		@include color($color__darklight);
		margin-bottom: 32px;
		font-weight: 300;
		font-size: 20px;
		line-height: 130%;
	}

	@include media-breakpoint-down(md) {
		@include display-text-normal;
		@include color($white);
	}
}

.thankyou__background {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	object-fit: cover;
}