.product-card__wrapper {
	width: 25%;
	padding: 0 .75rem;

	@include media-breakpoint-down(xl) {
		width: 33.33%;
	}

	@include media-breakpoint-down(sm) {
		width: 50%;
	}
}

.product-card {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	height: 100%;

	@include media-breakpoint-down(xl) {
		box-shadow: 0 1px 6px -2px rgba(0, 0, 0, .1);
		border-radius: .125rem;
	}

	&:hover {
		@include media-breakpoint-up(lg) {
			.product-card__description {
				background-color: rgba(0, 0, 0, 0.3);
			}

			.catalog-product-card__title {
				span {
					color: $white;
				}
			}

			.catalog-product-card__price {
				color: $white;
			}
		}
	}

	.catalog-product-card__title {
		span {
			transition: all $transition-default-effect $transition-default-time-smallest;
		}
	}

	.catalog-product-card__price {
		transition: all $transition-default-effect $transition-default-time-smallest;
	}

	&.product-card_small {
		@media (max-width: 480px) {
			width: 163px;
		}

		&:not(:first-child) {
			margin-left: 0;
		}
	}

	.card__like-button {
		position: absolute;
		right: .75rem;
		top: .625rem;
		z-index: 11;

		@include media-breakpoint-down(lg) {
			right: .5rem;
			top: .375rem;
		}
	}
}

.card__like-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.375rem;
	height: 2.375rem;
	background-color: transparent;
	border: none;
	border-radius: 50%;
	outline: none;
	cursor: pointer;
	z-index: 9;

	@include media-breakpoint-down(lg) {
		width: 32px;
		height: 32px;

		&.loading {
			&:after {
				left: 11px;
				top: 12px;
			}
		}
	}

	svg {
		margin-top: .125rem;
		flex-shrink: 0;
		fill: transparent;
		stroke: $color__gray;
	}

	&.active {
		svg {
			fill: $color__gold !important;
			stroke: $color__gold !important;
		}

		&:hover {
			fill: $color__gray !important;
			stroke: $color__gray !important;
		}

		&.loading {
			&:after {
				border-bottom-color: $white;
				border-top-color: $white;
			}
		}
	}

	&:hover {
		svg {
			stroke: $color__gold_hover !important;
		}
	}

	&:active {
		svg {
			stroke: $color__gold_active !important;
		}
	}

	&.loading {
		svg {
			/*opacity: 0;*/
		}

		&:after {
			/*border-bottom-color: $white;
			border-top-color: $white;*/
			height: 10px;
			left: 14px;
			top: 15px;
			width: 10px;

			/*border-bottom-color: $color__gray;
			border-top-color: $color__gray;
			height: 18px;
			left: 10px;
			top: 10px;
			width: 18px;*/
		}
	}
}

.catalog-product-card__gallery_swap {
	height: 100%;
	min-height: 186px;
	display: flex;
	align-items: center;

	img {
		min-height: 230px;
	}

	@include media-breakpoint-down(xl) {
		/*padding: .75rem .75rem 0;*/
	}

	@include media-breakpoint-down(sm) {
		img {
			min-height: inherit;
		}
	}

	.product-card__image {
		border-radius: $border-radius;
	}

	/*@include media-breakpoint-down(md) {
		padding: 0;
	}*/
}

.product-card__promo-image {
	height: 230px;
	margin: .75rem auto 0;
	object-fit: contain;
	border-radius: $border-radius-sm;

	@include media-breakpoint-down(xl) {
		height: auto !important;
		min-height: inherit !important;
		margin-top: 0;
	}
}

.product-card__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0;
	transition: $transition-base;

	/*@include media-breakpoint-down(lg) {
		display: none;
	}*/

	.product-card__image_small {
		max-height: 293px;
	}
}

.product-card__description {
	padding: .625rem .75rem .625rem;
	text-align: center;
	width: 100%;
	z-index: 10;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: $transition-base;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: subpixel-antialiased;

	@include media-breakpoint-down(lg) {
		padding-top: 0;
	}

	.catalog-product-card__title {
		margin-top: 0;
	}

	/*@include media-breakpoint-down(lg) {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		padding: 13px 0;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.3);
	}

	@media (max-width: 480px) {
		padding: 8px 0;
		background-color: transparent;
	}*/
}

.product-card__title {
	@include color($color__darklight);
	display: inline-block;
	font-weight: 400;
	font-size: 25px;
	line-height: 30px;
	margin: 0 0 1rem;
	text-decoration: none;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		@include text-small;
		@include lh(1.5rem);
		@include color($white);
		margin: 0;
	}

	@media (max-width: 480px) {
		@include text-smallest;
		@include color($black);
		margin: 0;
		font-weight: 400;
		line-height: 100%;

		.product-card__title_small {
			@include display-text-small;
			margin: 0 0 .5rem;
			font-weight: 500;
		}
	}

	&:hover {
		@include media-breakpoint-up(lg) {
			@include color($white !important);
		}
	}
}

.product-card__price {
	@include display-text-small;
	@include color($black);
	font-weight: 400;
	transition: $transition-base;

	@include media-breakpoint-down(lg) {
		color: $white;
		font-weight: 300;
		font-size: 12px;
	}

	@media (max-width: 480px) {
		color: $black;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}

	.product-card__price_discount {
		margin-left: 24px;
		line-height: 19px;
		text-decoration-line: line-through;
	}
}

.swiper-slide {
	height: auto;
}