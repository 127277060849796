.product__data {
	transition: all $transition-default-time-bigger $transition-default-effect;

	@include media-breakpoint-down(lg) {
		margin-top: 2.75rem;
	}

	@include media-breakpoint-down(md) {
		margin-top: 1rem;
	}

	/*flex-shrink: 0;
	flex-grow: 1;

	@media (max-width: 1200px) {
		min-width: unset;
	}

	 @include media-breakpoint-down(lg) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	@include media-breakpoint-down(sm) {
		grid-template-columns: 1fr 200px;
	}

	@media (max-width: 476px) {
		grid-template-columns: 1fr 100px;
	}

	@media (max-width: 330px) {
		grid-template-columns: 1fr 80px;
	} */
}

.product__title {
	@include color($color__darklight);
	font-weight: 400;
	font-size: 32px;
	line-height: 38px;
	text-transform: uppercase;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: subpixel-antialiased;
	margin-top: 0 !important;

	@include media-breakpoint-down(md) {
		@include fs-lh(1.875rem, 2.1875rem);
	}

	@include media-breakpoint-down(sm) {
		@include fs-lh(1.625rem, 1.875rem);
		font-weight: 300;
	}
}

.product-gallery__controls {
	align-items: center;
	display: flex;

	&.product__favorites {
		.product-gallery__controls-button:not(:last-child) {
			margin-right: 1rem;
		}
	}
}

.product__prices-container {
	background: transparent;
	padding: 0;
	transition: width $transition-default-effect $transition-default-time,
	background $transition-default-effect $transition-default-time,
	padding $transition-default-effect $transition-default-time;

	&:not(.product__prices-container_sticky) {
		box-shadow: none !important;

		@include media-breakpoint-down(lg) {
			padding-top: .75rem;
			gap: .75rem 1rem;
		}

		@include media-breakpoint-down(sm) {
			.product__buttons_wrapp {
				.button_buy {
					min-width: calc(100% - 4rem);
				}
			}
		}
	}

	&.product__prices-container_sticky {
		transition: left $transition-default-effect $transition-default-time-bigger,
		right $transition-default-effect $transition-default-time-bigger,
		bottom $transition-default-effect $transition-default-time-bigger,
		width $transition-default-effect $transition-default-time-bigger,
		background $transition-default-effect $transition-default-time-bigger,
		padding $transition-default-effect $transition-default-time-bigger,
		box-shadow $transition-default-effect $transition-default-time-bigger;

		.product__price_personal {
			text-align: center;
		}

		.product__favorites {
			display: none;
		}

		@include media-breakpoint-down(lg) {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100vw;
			background: $white;
			z-index: 11;
			padding: .75rem 2.625rem;
			box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, .14), 1px 1px 1px -1px rgba(0, 0, 0, .16);

			.product__buttons_wrapp {
				.button_buy {
					min-width: 100%;
				}
			}

			.product__prices-wrapper {
				justify-content: center;
				column-gap: 2.25rem;
				max-width: inherit;
				margin-top: 0;
				display: grid;
				grid-template-columns: 1fr 1fr;
				text-align: center;
				flex-grow: initial;
			}

			.product__prices_block {
				margin: 0 auto;
			}
		}

		@include media-breakpoint-down(md) {
			.product__prices_block {
				flex-direction: column;
				gap: 0;
				align-items: center;
			}
		}

		@include media-breakpoint-down(sm) {
			padding: .75rem 2rem .75rem 1rem;

			.product__prices-wrapper {
				gap: 5%;
			}

			.product__prices_block {
				margin: 0 auto;
			}
		}
	}

	.product__prices-wrapper {
		@include media-breakpoint-down(lg) {
			justify-content: inherit;
		}
	}
}

.product__rating .rating {
	margin-top: -6px;
}

.product__color {
	max-width: 100%;
	margin-top: .5rem;
	padding-bottom: .25rem;
	display: flex;
}

.circle {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 2px solid $color__graylight;
	cursor: pointer;
	display: inline-block;
	transition: $transition-base;

	&:hover {
		border-color: $color__gray;
		box-shadow: inset 0 0 0 .1rem $white;
	}
}

.circle_active {
	/*border-color: #7a7777 !important;*/
	border-color: #8e7b6c !important;
	box-shadow: inset 0 0 0 .1rem $white;
	cursor: default;
}

.circle_incompatible {
	border-color: #7a7777;
	border-style: dashed;
}

.product__color-circle {
	margin-right: 5px;
	padding: 0.25rem;
}

.color-circle_incompatible {
	border: 2px dashed #7a7777;
	border-radius: 50%;
	box-shadow: none;
}

.product__prices-wrapper {
	align-items: center;
	margin-top: 1.625rem;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: .75rem 1.625rem;

	.product__price {
		flex-grow: 1;
		display: flex;
		white-space: nowrap;

		@include media-breakpoint-down(xl) {
			flex-grow: 0;
		}
	}
}

.product__buttons_wrapp {
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	gap: 1rem 1.5rem;

	@include media-breakpoint-down(xl) {
		flex-grow: 0;
	}

	@include media-breakpoint-down(lg) {
		flex-wrap: wrap;
	}

	.button_buy {
		min-width: 12.5rem;
		transition: box-shadow $transition-default-time $transition-default-effect, color $transition-default-time-smallest $transition-default-effect !important;
	}
}

.product__prices_block {
	display: flex;
	align-items: baseline;
	gap: .5rem .75rem;

	.catalog-product-card__price_old {
		margin: 0;

		.catalog-product-card__price_old__value {
			position: relative !important;
			font-size: 18px;
		}
	}
}

.product__price {
	@include color($color__darklight);
	font-size: 32px;
	font-weight: 500;
	line-height: 38px;

	/*@include media-breakpoint-down(md) {
		font-weight: 400;
		font-size: 20px;
		line-height: 127%;
	}*/

	@include media-breakpoint-down(md) {
		line-height: normal;
	}

	@include media-breakpoint-down(sm) {
		font-size: 28px;
	}
}

.product__price_discount {
	@include display-text-small;
	text-decoration-line: line-through;
}

.product__price_personal {
	@include display-text-small;
	width: 100%;
	margin-top: .625rem;

	@include media-breakpoint-down(lg) {
		@include text-smallest;
		font-weight: 300;
		text-align: left;
		margin-top: .625rem;
		margin-bottom: -.375rem;
	}

	@include media-breakpoint-down(md) {
		text-align: center;
	}
}

.paragraph__characteristics, .paragraph__tabs {
	@include color($color__dark);
	font-size: 20px;
	font-weight: 500;
	line-height: 130%;
	margin: 1rem 0;
}

.product__attributes {
	padding-top: .5rem;
	transition: $transition-base;

	&.product__attributes_color_transition * {
		@include color($color__gray);
		transition: $transition-base;
	}

	a {
		@include color($color__darklight, true)
	}
}

.product__attributes > li {
	margin: .875rem 0;
}

.product__icon-svg {
	height: 1.25rem;
	width: 1.25rem;
}

@include media-breakpoint-down(lg) {
	/*.product__favorites {
		display: none;
	}*/
}