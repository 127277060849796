.whatsapp-link {
	position: fixed;
	top: unset;
	right: 30px;
	bottom: 30px;
	z-index: 10;
	opacity: 1;
	transition: $transition-base;

	&.whatsapp-link_arrow_up {
		bottom: 120px;
	}

	@include media-breakpoint-down(lg) {
		right: 16px;
		bottom: 70px;

		&.whatsapp-link_catalog_detail {
			bottom: 85px;
		}

		&.whatsapp-link_arrow_up {
			bottom: 160px;
		}
	}

	@include media-breakpoint-down(md) {
		transform: scale(0.7);
		right: 5px;
		bottom: 65px;

		&.whatsapp-link_arrow_up {
			bottom: 155px;
		}

		&.whatsapp-link_catalog_detail {
			bottom: 80px;
		}
	}

	svg {
		path {
			transition: $transition-base;
		}
	}

	&:hover {
		svg {
			path {
				&:first-child {
					fill: #73e123;
				}
			}
		}
	}
}

