.loyalty-card {
	@include color($white);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 235px;
	height: 154px;
	padding: 24px 0 18px 16px;
	font-weight: 300;
	font-size: 12px;
	line-height: 130%;
	background-color: $color__darklight;
	border-radius: 9px;

	@include media-breakpoint-down(lg) {
		min-width: 208px;
		height: 138px;
		padding: 22px 0 16px 14px;
	}

	&:not(:last-of-type) {
		margin-right: 32px;

		@include media-breakpoint-down(lg) {
			margin-right: 24px;
		}
	}
}

.loyalty-card__background-image {
	position: absolute;
	right: -38px;
	bottom: 0;
	width: 150px;
	height: 148px;
}

.loyalty-card__logo {
	display: flex;
	align-items: flex-end;
	width: 100%;
	max-width: 102px;
	color: $white;
}

.loyalty-card__logo-icon {
	width: 70px;
	height: 20px;
}

.loyalty-card__logo-text {
	height: 18px;
	padding-top: 4px;
}

.loyalty-card__discount {
	@include display-text-small;
}

.loyalty-card__discount-value {
	@include color($color__gold);
	margin-right: .5rem;
	font-weight: 300;
	font-size: 38px;
	line-height: 46px;
}

.loyalty-card__highlighted-text {
	@include color($color__gold);
}
