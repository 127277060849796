.product {
	display: flex;
	margin-top: 3rem;

	@include media-breakpoint-down(lg) {
		display: block;
		margin-top: 1.25rem;
	}

	@include media-breakpoint-down(md) {
		margin-top: 0;

		.product__info {
			display: flex;
			flex-wrap: wrap;
			align-items: baseline;
			justify-content: space-between;
			margin-bottom: 12px;
		}
	}
}

.product__rating {
	display: flex;
	align-items: center;
	margin-bottom: 24px;

	@include media-breakpoint-down(md) {
		margin-right: 8px;
		margin-bottom: 0;
	}

	.rating {
		margin-top: -6px;

		.rating__stars {
			margin-top: 0;
			margin-right: 8px;
		}
	}
}

.product__sku {
	display: flex;
	margin-bottom: 1.5rem;

	@include media-breakpoint-down(md) {
		margin-bottom: 0;
	}

	> *:first-child {
		margin-right: 104px;

		@include media-breakpoint-down(md) {
			margin-right: 16px;
		}
	}

	> *:last-child {
		font-weight: 400;
	}
}

.product__props-container {
	position: relative;
	border-bottom: 1px solid #efefef;
	margin-top: 1.5rem;

	> * {
		&:first-child {
			grid-row: 1;
			grid-column: 1;
		}

		&:nth-child(2) {
			grid-row: 1;
			grid-column: 2;
		}

		&:last-child {
			grid-row: 2;
			grid-column: 2;
		}
	}

	& > .paragraph {
		&::before {
			position: absolute;
			left: 0;
			display: block;
			width: 100%;
			height: 1px;
			margin-top: -1.125rem;
			background: #efefef;
			content: "";
		}
	}
}

.product__props-container-wrapp {
	border-top: 1px solid #efefef;
	align-items: center;
	display: grid;
	grid-column-gap: 2rem;
	grid-template-rows: max-content max-content;
	grid-template-columns: 1fr 3fr;
	padding-top: 9px;
	padding-bottom: 8px;

	@include media-breakpoint-between(lg, xl) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	@include media-breakpoint-down(sm) {
		display: flex;
		flex-direction: row;
		align-items: baseline;
	}
}

.product__props-color-wrapp {
	border-top: 1px solid #efefef;
	padding-top: 1rem;
}

.product__prop-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 -.5rem 0;

	@include media-breakpoint-between(lg, xl) {
		margin: .625rem 0 -.0625rem;
	}

	@include media-breakpoint-down(sm) {
		margin: 0 0 -.5rem;
	}

	> * {
		margin: 0 .5rem .5rem 0;
	}
}

.product__delivery {
	display: flex;
	justify-content: space-between;
}

.product__link {
	@include color($color__gray);
	margin: 0;
	padding: 0;
	font-weight: 600;
	text-decoration-line: underline;
	text-underline-offset: 2px;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.product__prices {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	margin: 24px 0 32px;

	@include media-breakpoint-down(md) {
		margin: 32px 0;
	}
}

.product__tabs {
	margin-top: 2.625rem;

	@include media-breakpoint-down(lg) {
		position: relative;
		max-width: unset;
	}

	@include media-breakpoint-down(md) {
		margin-top: 2rem;
	}
}

.product__tab {
	margin-top: .75rem;
}