.search-form {
	position: relative;
	margin: 0;
}

.search-form__label {
	display: flex;
	width: 100%;

	&:hover {
		.search-form__input {
			border-bottom-color: #b2b2b2;

			::placeholder {
				color: #4d4d4d;
			}
		}
	}
}

.search-form__input {
	@include text-small;
	@include color($color__dark);
	width: 100%;
	font-weight: 300;
	border: 0;
	border-bottom: 1px solid #ccc;
	outline: 0;
	padding: 0 40px 0 1rem;
	line-height: 3rem;
	transition: $transition-base;

	.search-form__input_small_padding {
		padding: 16px 40px 16px 0;
	}

	&::placeholder {
		color: $color__dark;
	}

	&:focus {
		border-color: $color__gold !important;
		color: $black !important;
	}
}

.search-form__icon {
	@include color($color__dark);
	position: absolute;
	top: 16px;
	right: 16px;
	width: 17px;
	height: 17px;
	fill: none;
	transition: $transition-base;

	@include media-breakpoint-down(md) {
		display: none;
	}

	.search-form__icon_position_right {
		right: 0;
	}
}