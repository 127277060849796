.catalog-sidebar {
	background: $white;

	@include media-breakpoint-down(md) {
		display: none;
	}

	.category-list__dropdown {
		background-color: #f7f7f7;

		&.active {
			background-color: $white;
		}
	}

	.category-list__link {
		z-index: 1;
	}

	.category-list__link_dropdown {
		&.active {
			& + .category-list__dropdown {
				top: 0;
				z-index: 0;

				.active {
					& + .category-list__dropdown {
						padding-top: 1px !important;
					}
				}
			}
		}
	}
}

.catalog-sidebar__filter {
	position: relative;
}

.catalog-sidebar__filter_title {
	text-align: center;
	margin: 1rem 0 2.75rem;
}

.catalog-sidebar__title {
	@include color($color__dark);
	margin-bottom: 1rem;
	font-weight: 400;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0.05555em;
	text-transform: uppercase;

	@include media-breakpoint-down(md) {
		@include text-normal;
		margin-bottom: .75rem;
	}
}

.catalog-sidebar__item {
	margin-bottom: 2.825rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid $color__graylight;
	position: relative;

	@include media-breakpoint-down(lg) {
		margin-bottom: 2rem;
		padding-bottom: 1.5rem;
	}

	/*@include media-breakpoint-down(md) {
		margin-bottom: 2rem;
		padding-bottom: 1.625rem;
	}*/
}

.catalog-sidebar__filter_item {
	opacity: 1;
	width: 100%;
	position: relative;
	transition: $transition-base;

	&.disabled {
		opacity: .5;
	}
}

.catalog-sidebar__item_checkbox {
	/*&:not(:last-child) {
		margin-bottom: 4.75rem;

		@include media-breakpoint-down(lg) {
			margin-bottom: 56px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 1.75rem;
		}
	}*/
}

.catalog-sidebar__item_price {

}

.catalog-sidebar__clear {
	@include text-small;
	@include color($color__graydark);
	margin: 0;
	padding: 0;
	font-weight: 300;
	line-height: 1;
	text-align: center;
	text-decoration-line: underline;
	background-color: transparent;
	border: none;
	cursor: pointer;
	transition: $transition-base;

	@include media-breakpoint-down(md) {
		@include color($color__gold);
		line-height: 48px;
		text-transform: uppercase;
		text-decoration: none;
		border: 1px solid $color__gold;
		border-radius: $border-radius-xs;

		&:hover {
			@include color($color__gold_hover);
			border-color: $color__gold_hover;
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .06);
		}

		&:active {
			box-shadow: none;
			@include color($color__gold_active);
			border-color: $color__gold_active !important;
		}
	}
}

.catalog-sidebar__filter-buttons {
	display: grid;
	grid-gap: 1rem;
	grid-template-rows: repeat(2, max-content);
	margin-top: -1rem;

	@include media-breakpoint-down(md) {
		grid-gap: .5rem;
	}

	.button {
		appearance: none;

		&:hover, &:active, &:focus {
			@include color($white !important);
		}
	}
}

.catalog-sidebar__item-group_wrapp {
	position: relative;

	&:before, &:after {
		content: '';
		display: block;
		width: calc(100% - 1rem);
		position: absolute;
		z-index: 1;
		background-color: $white;
		height: .5rem;
	}

	&:before {
		top: -.5rem;
		box-shadow: 0 3px 4px -1px hsla(0, 0%, 100%, .9), 0 2px 5px hsla(0, 0%, 100%, .8);
	}

	&:after {
		bottom: -.5rem;
		box-shadow: 0 -3px 4px -1px hsla(0, 0%, 100%, .9), 0 -2px 5px hsla(0, 0%, 100%, .8);
	}
}

.catalog-sidebar__checkbox-group {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-height: 300px;
	overflow-y: auto;
	padding-left: 1rem;
	margin-left: -1rem;

	&::-webkit-scrollbar {
		width: .5rem;
	}

	&::-webkit-scrollbar-track {
		height: 100%;
		background: none;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #e3e2de;
		border: none;
		border-radius: 10px;
	}

	> *:not(:last-child) {
		margin-bottom: .25rem;

		/*@include media-breakpoint-down(lg) {
			margin-bottom: 1rem;
		}*/
	}
}

.catalog-sidebar__popup {
	@include display-text-normal;
	@include color($white !important);
	position: absolute;
	left: 0;
	z-index: 10;
	padding: 0 1.375rem 0 1.375rem;
	line-height: 46px;
	margin-left: -5rem;
	font-weight: 400;
	text-decoration: none;
	background-color: $color__gold;
	opacity: 0;
	pointer-events: none;
	white-space: nowrap;
	border-radius: 0 $border-radius-xs $border-radius-xs 0;
	transition: $transition-base;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		left: -17px;
		border-style: solid;
		border-width: 23px 17px 23.5px 0;
		border-color: transparent $color__gold transparent transparent;
		top: 0;
		bottom: 0;
		transition: $transition-base;
	}

	&:hover, &:active, &:focus {
		@include color($white !important);
	}

	&:hover {
		background-color: $color__gold_hover;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .06);

		&:before {
			border-color: transparent $color__gold_hover transparent transparent;
		}
	}

	&:active {
		background-color: $color__gold_active;
		box-shadow: none !important;

		&:before {
			border-color: transparent $color__gold_active transparent transparent;
		}
	}

	&.active {
		left: 100%;
		opacity: 1;
		pointer-events: all;
		box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .2);

		@include media-breakpoint-down(md) {
			box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .16);
			bottom: 18px;
		}
	}

	&.disabled {
		cursor: default;
		background-color: $color__gray !important;

		&:before {
			border-color: transparent $color__gray transparent transparent !important;
		}
	}

	@include media-breakpoint-down(md) {
		position: fixed;
		top: inherit !important;
		bottom: -100%;
		border-radius: 0;
		line-height: 50px;
		text-transform: uppercase;
		text-align: center;
		font-size: 1rem;
		left: 0 !important;
		right: 0;
		margin-left: 0;
		letter-spacing: .01555em;

		&:before {
			content: none;
		}
	}
}

