.warranty {
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(sm) {
		margin-bottom: 32px;
	}
}

.warranty__breadcrumb {
	margin: 64px 0 51px;

	@include media-breakpoint-down(xxl) {
		margin: 40px 0 32px;
	}

	@include media-breakpoint-down(sm) {
		margin: 16px 0 32px;
	}
}

.warranty__icon {
	@include color($color__graylight);
	position: absolute;
	top: 58px;
	right: -432px;
	z-index: -5;
	opacity: 0.36;

	@include media-breakpoint-down(xxl) {
		display: none;
	}
}


