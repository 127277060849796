.accent-card-icon {
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: -32px;
	width: 246px;
	height: 140px;

	@include media-breakpoint-down(xxl) {
		display: none;
	}

	& + p {
		position: relative;
		z-index: 2;
	}
}
