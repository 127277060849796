.contacts {

}

.contacts__container {
	display: flex;
	margin: 87px auto 87px;

	@include media-breakpoint-down(md) {
		margin: 0;
		margin-top: 50px;
	}

	@include media-breakpoint-down(sm) {
		margin: 32px auto 80px;
	}
}

.contacts__item {
	&:not(:first-child) {
		margin-top: 2rem;
	}
}

.contacts__form {
	position: sticky;
	top: 170px;
	height: 100%;
	max-width: 680px;

	@include media-breakpoint-down(xl) {
		margin: 80px auto 0;
	}
}