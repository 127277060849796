.certificates-form {
	max-width: 1190px;

	@include media-breakpoint-down(xxl) {
		padding: 72px 48px;
		border: 1px solid;
		border-color: $color__gold transparent;
		box-shadow: 0 3px 25px rgba(0, 30, 69, 0.05);
	}

	@include media-breakpoint-down(sm) {
		margin: 32px auto;
		padding: 24px 0;
	}
}

.certificates-form__header {
	display: flex;
	width: 100%;
	margin-bottom: 32px;
}

.certificates-form__promo {
	@include color($black);
	margin-left: -14px;
	font-weight: 300;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.15em;
	transform: rotate(-90deg);

	@include media-breakpoint-down(xxl) {
		display: none;
	}
}

.certificates-form__title {
	width: 100%;
	font-weight: 300;
	font-size: 38px;
	line-height: 46px;
	text-align: center;

	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 127%;
	}
}

.certificates-form__kicker {
	@include display-text-small;
	@include color($color__dark);
	margin-bottom: 3rem;
	font-weight: 300;
	text-align: center;
	padding: 0 1.25rem;

	@include media-breakpoint-down(xxl) {
		margin-bottom: 2rem;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 1rem;
		font-size: 12px;
	}
}

.certificates-form__form {
	display: flex;
	flex-flow: column nowrap;
	max-width: 630px;
	margin: 0 auto !important;
	padding: 0 1.25rem;
}

.certificates-form__option-list {
	padding: .5rem 0;
	background-color: $white;
	border: 1px solid $color__gold;
	border-radius: 0 0 10px 10px;
	box-shadow: 0 3px 25px rgba(0, 30, 69, 0.1);
}

.certificates-form__option {
	margin: 0;
	padding: 6px 0 8px 32px;

	&:hover {
		background: rgba(229, 229, 229, 0.6);
	}
}

.certificates-form__politic {
	@include text-small;
	@include color($color__dark);
	margin: 1.5rem 0 0;
	font-weight: 300;

	@include media-breakpoint-down(md) {
		margin: 1.5rem auto 0;
		text-align: center;
	}
}

.certificates-form__politic-link {

}

.certificates-form__button {
	min-width: 18rem;
	margin: 2.5rem auto 0;

	@include media-breakpoint-down(md) {
		width: 100%;
		max-width: 100%;
	}
}

