.page-sidebar {
	@include media-breakpoint-down(xxl) {
		display: none;

		&.page-sidebar_display {
			display: block;
		}
	}

	@include media-breakpoint-down(lg) {
		display: none;

		&.page-sidebar_profile {
			display: block;
			margin-bottom: 1.625rem;

			.page-sidebar-user {
				margin-bottom: 0;
			}
		}
	}
}

.page-sidebar__link {
	@include display-text-normal;
	@include color($color__darklight);
	position: relative;
	display: flex;
	align-items: center;
	padding: 12px 0 12px 16px;
	font-weight: 300;
	text-decoration: none;
	background-color: transparent;
	transition: backgrond-color .3s ease-out, color .3s ease-out, border-color .6s cubic-bezier(0.05, 0.7, 0.6, 1), border-width .6s cubic-bezier(0.04, 0.58, 1, 1.54);
	border-right: 0 solid transparent;

	&.active, &:hover {
		@include color($color__darklight);
		background-color: #F7F7F7;
		border-right-color: $color__gold;
		border-width: 5px;
	}

	&:active {
		background-color: hsla(0, 0%, 90%, .6);
		border-right-color: $color__gold_active;
	}

	.page-sidebar__link_padding_big {
		padding: 12px 0 12px 48px;
	}

	@include media-breakpoint-down(lg) {
		border: 0;
		padding-left: 2.625rem;
		padding-right: 2.625rem;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.page-sidebar__list {
	position: sticky;
	top: calc(150px + 1rem);
	margin-left: -1rem;

	@include media-breakpoint-down(lg) {
		position: static;
		margin-left: -2.625rem;
		margin-right: -2.125rem;
	}
}

.page-sidebar__icon {
	@include color($color__gold !important);
	width: 1.5rem;
	height: 1.5rem;
	margin-right: .5rem;
	fill: none;
}